import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import RieDataTable from '../Components/RieDataTable';
import { useArchiveRies } from '../../../Hooks';
import { SearchBar, Unauthorized } from '../../../Shared/Components';
import { Permission } from '../../../Shared/Enums';
import { TableQueryOptions } from '../../../Shared/Types/QueryOptions';
import { ODataResult } from '../Types/ODataResult';
import useMonetUser from '../../../Hooks/useMonetUser';
import {useFilter} from "../../../Shared/Providers/FilterContextProvider";

const Archive = () => {
  const { t } = useTranslation();
  const { checkPermission, hasMultipleOperators } = useMonetUser();
  const tableRef = useRef();

  const {filterObjects} = useFilter();

  const showMultipleOperators = hasMultipleOperators();

  if (!checkPermission(Permission.RIE_ARCHIVE)) {
    return <Unauthorized pageTitle={t('menu.archive')} />;
  }

  const handleDataCall = (queryOptions: TableQueryOptions): UseQueryResult<ODataResult, Error> =>
    useArchiveRies({
      pageIndex: queryOptions.pageIndex,
      pageSize: queryOptions.pageSize,
      sorting: queryOptions.sorting,
      filter: filterObjects.archiveFilter,
    });

  return (
    <div className="archive page">
      <div className="page-header">
        <div className="page-header__title">
          <h1 className="title">{t('menu.archive')}</h1>
        </div>
      </div>
      <SearchBar
        showMultipleOperators={showMultipleOperators}
        showSimpleHeader
        showStatusFilter
        filterKey="archiveFilter"
      />
      <RieDataTable
        ref={tableRef}
        dataCall={handleDataCall}
      />
    </div>
  );
};

export default Archive;
