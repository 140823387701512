import React, { ChangeEvent, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from '../../Enums';
import IconRender from '../Icon/IconRender';

import './Input.scss';

type PropType = {
  className?: string;
  type?: 'text' | 'number';
  trim?: boolean;
  icon?: Icon;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string | number;
  onChange: (value: string | number) => void;
  onSubmit?: () => void;
};

const Input = (props: PropType) => {
  const { className, type, trim, icon, id, placeholder, disabled, value, onChange, onSubmit } = props;

  const wrapperEl = useRef<HTMLInputElement>(null);
  const inputEl = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (wrapperEl.current && inputEl.current && wrapperEl.current.contains(e.target as Node)) {
      if (trim) {
        inputEl.current.value.trim();
      }
      if (e.key === 'Enter' && onSubmit) {
        if (type === 'number') {
          onChange(parseFloat(inputEl.current.value));
        } else {
          onChange(inputEl.current.value);
        }
        onSubmit();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <div
      ref={wrapperEl}
      className={classNames(className, 'input', { 'input--has-icon': icon })}
    >
      {icon && (
        <IconRender
          width="28px"
          height="28px"
          color="var(--dark-grey)"
          icon={icon}
          className="input__icon"
        />
      )}
      <input
        ref={inputEl}
        type={type}
        value={value}
        className="input__input"
        placeholder={placeholder}
        disabled={disabled}
        id={id}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (onChange && e.target) {
            if (trim) {
              e.target.value.trim();
            }
            if (type === 'number') {
              onChange(parseFloat(e.target.value));
            } else {
              onChange(e.target.value);
            }
          }
        }}
      />
    </div>
  );
};

Input.defaultProps = {
  className: '',
  type: 'text',
  trim: false,
  icon: null,
  id: '',
  placeholder: '',
  disabled: false,
  onSubmit: undefined,
  value: undefined,
};

export default Input;
