import { useQuery, UseQueryResult } from 'react-query';

import getAuditLogs from '../Data/auditLogService';
import { ODataResult } from '../../Rie/Types/ODataResult';
import {QueryOptions} from "../../../Shared/Types/QueryOptions";

const useAuditLogs = (queryOptions: QueryOptions): UseQueryResult<ODataResult, Error> =>
  useQuery(['auditLogs', queryOptions], () => getAuditLogs(queryOptions));

export default useAuditLogs;
