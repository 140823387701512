import React from 'react';
import { useTranslation } from 'react-i18next';

import MonetModal from './MonetModal';

type PropType = {
  close: () => void;
  confirm: () => void;
  deleteLabel?: string;
  entityName: string;
  title: string;
  onCloseLabel?: string;
  onConfirmLabel?: string;
};

const MonetDeleteModal = (props: PropType) => {
  const { close, confirm, deleteLabel, entityName, title, onCloseLabel, onConfirmLabel } = props;
  const { t } = useTranslation();

  return (
    <MonetModal
      confirm={confirm}
      title={title}
      close={close}
      onCloseLabel={onCloseLabel || t('generic.close')}
      onConfirmLabel={onConfirmLabel || t('generic.delete')}
    >
      {deleteLabel || t('generic.deleteEntityMessage', { entityName })}
    </MonetModal>
  );
};

MonetDeleteModal.defaultProps = {
  onCloseLabel: undefined,
  onConfirmLabel: undefined,
  deleteLabel: undefined,
};

export default MonetDeleteModal;
