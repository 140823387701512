import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEn from './Localization/en/common.json';
import commonNl from './Localization/nl/common.json';

const resources = {
  en: { common: commonEn },
  nl: { common: commonNl },
};

const detection = {
  order: ['querystring', 'localStorage', 'navigator'],
  lookupQuerystring: 'lng'
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection,
    supportedLngs: ['nl', 'en'],
    fallbackLng: 'nl',
    defaultNS: 'common',
    ns: ['common'],
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
