import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import StatusSymbol from '../StatusSymbol/StatusSymbol';
import { SafetyStatus, Size, Icon, Theme, Operator } from '../../Enums';
import Button from '../Button/Button';
import Title from '../Title/Title';

type ClusterInfoScreenPropType = {
  locations: ClusterLocationType[];
  deactivate: () => void;
};

type ClusterLocationType = {
  geometry: {
    coordinates: number[];
    type: 'Point' | 'Feature';
  };
  properties: {
    category: string;
    cluster: boolean;
    operator: Operator;
    rieId: string;
    siteNumber: string;
    status: SafetyStatus;
  };
  type: 'Point' | 'Feature';
};

const ClusterInfoScreen = (props: ClusterInfoScreenPropType) => {
  const { locations, deactivate } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return (
    <div className="marker-details marker-details--cluster">
      <div className="marker-details__header">
        <Title>{t('map.clusterPanel.title')}</Title>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span
          className="marker-details__close"
          onClick={deactivate}
        >
          X
        </span>
      </div>
      <div className="marker-details__container">
        {locations.map((location: ClusterLocationType) => {
          const {
            properties: { status, siteNumber, operator, rieId },
          } = location;

          return (
            <div
              className="marker-details__row"
              key={siteNumber}
            >
              <span>
                <StatusSymbol
                  status={status}
                  size={Size.small}
                />
              </span>
              <span>
                <Button
                  label={`${siteNumber} ${operator}`}
                  icon={Icon.chevronRight}
                  theme={Theme.transparent}
                  onClick={() => {
                    queryClient.invalidateQueries(['ries', 'rie', rieId]).then(() => {
                      navigate(`/locations/${rieId}`);
                    });
                  }}
                />
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

ClusterInfoScreen.defaultProps = {};

export default ClusterInfoScreen;
