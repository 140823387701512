import { useQuery, UseQueryResult } from 'react-query';
import { getUsersForCompanyById } from '../Data/usersService';
import { ODataResult } from '../../Rie/Types/ODataResult';
import { QueryOptions } from '../../../Shared/Types/QueryOptions';
import { Company } from '../../../Shared/Models';
import { getCompanyById } from '../../Companies/Data/companyService';

const useCompanyUsers = (queryOptions: QueryOptions): UseQueryResult<ODataResult, Error> =>
  useQuery(['users', queryOptions], () => getUsersForCompanyById(queryOptions));

const useCompany = (id?: string): UseQueryResult<Company, Error> =>
  useQuery(['companyById', id], () => getCompanyById(id));

export { useCompanyUsers, useCompany };
