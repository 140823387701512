// src/common/providers/APIErrorProvider/index.js
import React, {useContext, ReactNode, useState, useMemo} from 'react';

export const ErrorContext = React.createContext({
  error: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setError: (error: string) => {}
});

export const useErrorModal = () => useContext(ErrorContext)

type PropType = {
  children: ReactNode[] | ReactNode;
};

// @ts-ignore
export const ErrorContextProvider = (props: PropType) => {
  const [error, setError] = useState('');

  const {children} = props;
  const context = useMemo(() => ({error, setError}), [error, setError]);

  return (
    <ErrorContext.Provider value={context}>{children}</ErrorContext.Provider>
  );
};
