import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import './Toggle.scss';
import { useTranslation } from 'react-i18next';

type PropType = {
  offSwitchLabel: string;
  onSwitchLabel: string;
  handleOffSwitch: () => void;
  handleOnSwitch: () => void;
};

const Toggle = (props: PropType) => {
  const { offSwitchLabel, onSwitchLabel, handleOffSwitch, handleOnSwitch } = props;
  const { t } = useTranslation();

  const buttonEl = useRef<HTMLButtonElement>(null);
  const offSwitchEl = useRef<HTMLElement>(null);
  const onSwitchEl = useRef<HTMLElement>(null);
  const switchEl = useRef<HTMLElement>(null);

  const [active, setActive] = useState(false);

  // Handles the click behavior of the toggle element.
  const handleClick = () => {
    const isCurrentlyActive = !active;
    setActive(isCurrentlyActive);

    if (isCurrentlyActive) {
      handleOnSwitch();
    } else {
      handleOffSwitch();
    }
  };

  // Retrieve the width of the element including the padding.
  const getWidthEl = (el: HTMLElement) => el.offsetWidth;

  const adjustSliderSize = () => {
    if (buttonEl.current && switchEl.current && offSwitchEl.current && onSwitchEl.current) {
      let width: number;
      const buttonStyle = getComputedStyle(buttonEl.current);
      const paddingLeft = parseInt(buttonStyle.paddingLeft, 10);
      const paddingRight = parseInt(buttonStyle.paddingRight, 10);
      const padding = paddingLeft + paddingRight;

      if (active) {
        width = getWidthEl(onSwitchEl.current);
        switchEl.current.style.left = `calc(100% - ${width + padding}px)`;
      } else {
        width = getWidthEl(offSwitchEl.current);
        switchEl.current.style.left = '0';
      }

      switchEl.current.style.width = `${width + padding}px`;
    }
  };

  useEffect(() => {
    adjustSliderSize();
  }, [active]);

  return (
    <button
      ref={buttonEl}
      type="button"
      onClick={handleClick}
      className={classNames('toggle', { 'toggle--active': active })}
    >
      <span
        ref={offSwitchEl}
        className="toggle__option toggle__option--off"
        data-label={offSwitchLabel}
      >
        {t(offSwitchLabel)}
      </span>
      <span
        ref={onSwitchEl}
        className="toggle__option toggle__option--on"
        data-label={onSwitchLabel}
      >
        {t(onSwitchLabel)}
      </span>
      <span
        ref={switchEl}
        className="toggle__switch"
      />
    </button>
  );
};

export default Toggle;
