import { useQuery, UseQueryResult } from 'react-query';
import { Rie } from '../../Shared/Models';
import http from '../../Utils/Axios/http';

const getRieById = async (id: string) => {
  const { data } = await http.get(`/Rie/Ries/${id}/Anonymously`);
  return data;
};

export default function useRiePdf(id: string): UseQueryResult<Rie, Error> {
  return useQuery(['ries', 'rie', id], () => getRieById(id));
}
