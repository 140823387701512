import React, { useMemo, useRef, useState } from 'react';
import { useQueryClient, UseQueryResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';

import { Button, IconButton, Unauthorized } from '../../../Shared/Components';
import { Company } from '../../../Shared/Models';
import { Icon, Permission, Role, Theme } from '../../../Shared/Enums';

import { deleteCompany, newCompany, updateCompany } from '../Data/companyService';
import useCompanies from '../Hooks/useCompanies';
import CompanyModal from '../Components/CompanyModal/CompanyModal';
import MonetDeleteModal from '../../../Shared/Components/MonetModal/MonetDeleteModal';
import Input from '../../../Shared/Components/Input/Input';
import FilterObjectType from '../../../Shared/Types/FilterObjectType';
import DataTable from '../../../Shared/Components/Table/DataTable';
import { TableQueryOptions } from '../../../Shared/Types/QueryOptions';
import { ODataResult } from '../../Rie/Types/ODataResult';
import useMonetUser from '../../../Hooks/useMonetUser';
import { useErrorModal } from '../../../Shared/Providers/ErrorContextProvider';
import { OperatorsCell } from '../../../Shared/Components/Table/Cells/OperatorCell';

const Companies = () => {
  const queryClient = useQueryClient();
  const { i18n, t } = useTranslation();
  const navigator = useNavigate();
  const { permissions, checkPermission, getRoleOptions } = useMonetUser();
  const tableRef = useRef();

  const [companyModalActive, setCompanyModalActive] = useState<boolean>(false);
  const [confirmDeleteModalActive, setConfirmDeleteModalActive] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);
  const [name, setName] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [filter, setFilter] = useState<FilterObjectType>({});

  const roles = useMemo(() => getRoleOptions(), [permissions]);

  // This wrapper is necessary for applying the filter, which is handled outside the DataTable component
  const handleDataCall = (queryOptions: TableQueryOptions): UseQueryResult<ODataResult, Error> =>
    useCompanies({
      pageIndex: queryOptions.pageIndex,
      pageSize: queryOptions.pageSize,
      sorting: queryOptions.sorting,
      filter,
    });

  const { setError } = useErrorModal();

  // eslint-disable-next-line react/no-unstable-nested-components
  const CommandsCell = ({ row }: any) => {
    const company = row.original as Company;

    return (
      <div className="buttons">
        {checkPermission(Permission.COMPANIES_MANAGE_ALL) && (
          <IconButton
            icon={Icon.user}
            label={t('generic.users')}
            size="15px"
            theme={Theme.primary}
            onClick={() => {
              navigator(`/companies/${company.Id}/${company.Name}/users`);
            }}
          />
        )}
        {checkPermission(Permission.COMPANIES_MANAGE_ALL) && (
          <IconButton
            icon={Icon.pencil}
            label={t('generic.edit')}
            size="15px"
            theme={Theme.primary}
            onClick={() => {
              document.querySelector('body')!.classList.add('prevent-scroll');
              setSelectedCompany(company);
              setCompanyModalActive(true);
            }}
          />
        )}
        {checkPermission(Permission.COMPANIES_MANAGE_ALL) &&
          company.Roles.every((r) => roles.some((mr) => mr.value === r && mr.enabled)) && (
            <IconButton
              icon={Icon.delete}
              label={t('generic.delete')}
              size="15px"
              theme={Theme.dark}
              onClick={() => {
                document.querySelector('body')!.classList.add('prevent-scroll');
                setSelectedCompany(company);
                setConfirmDeleteModalActive(true);
              }}
            />
          )}
      </div>
    );
  };

  const columns = useMemo<ColumnDef<Company>[]>(
    () => [
      {
        header: t('companies.name'),
        accessorKey: 'Name',
      },
      {
        header: t('companies.city'),
        accessorKey: 'City',
      },
      {
        header: t('companies.operators'),
        accessorKey: 'Operators',
        cell: OperatorsCell,
        enableSorting: false,
      },
      {
        id: 'Actions',
        header: '',
        width: 50,
        cell: CommandsCell,
      },
    ],
    [i18n.language, permissions],
  );

  if (!(checkPermission(Permission.COMPANIES_MANAGE_ALL) || checkPermission(Permission.COMPANIES_MANAGE_OWN))) {
    return <Unauthorized pageTitle={t('generic.companies')} />;
  }

  const handleSearch = () => {
    const filterObj: FilterObjectType = {
      and: [],
    };

    if (filterObj && filterObj.and) {
      if (name) {
        filterObj.and.push({ Name: { contains: name } });
      }
      if (city) {
        filterObj.and.push({ City: { contains: city } });
      }

      setFilter(filterObj);
      // @ts-ignore
      tableRef.current?.resetPagination();
    }
  };

  const updateSelectedCompany = (company: Company) => {
    updateCompany(company)
      .then(() => {
        queryClient.invalidateQueries(['companies']).then(() => {});
        queryClient.invalidateQueries(['companyById', company.Id]).then(() => {});
        queryClient.invalidateQueries(['users']).then(() => {});
        setCompanyModalActive(false);
      })
      .catch((result) => {
        if (result.status === 500) {
          setError(t('error'));
        } else {
          setError(t('errors.company.nameAlreadyTaken'));
        }
      });
  };

  const saveNewCompany = (company: Company) => {
    newCompany(company)
      .then(() => {
        queryClient.invalidateQueries(['companies']).then(() => {});
        queryClient.invalidateQueries(['companyById', company.Id]).then(() => {});
        queryClient.invalidateQueries(['users']).then(() => {});
        setCompanyModalActive(false);
      })
      .catch((result) => {
        if (result.status === 500) {
          setError(t('error'));
        } else {
          setError(t('errors.company.nameAlreadyTaken'));
        }
      });
  };

  const onSave = (comp: Company) => {
    document.querySelector('body')!.classList.remove('prevent-scroll');
    if ('Id' in comp && comp.Id) {
      updateSelectedCompany(comp);
    } else {
      saveNewCompany(comp);
    }
  };

  const onDelete = () => {
    document.querySelector('body')!.classList.remove('prevent-scroll');
    deleteCompany(selectedCompany?.Id as string)
      .then(() => {
        queryClient.invalidateQueries(['companies']).then(() => {});
        queryClient.invalidateQueries(['companyById', selectedCompany?.Id]).then(() => {});
        queryClient.invalidateQueries(['users']).then(() => {});

        setConfirmDeleteModalActive(false);
      })
      .catch(() => setError(t('error')));
  };

  const defaultCompany: Company = {
    Name: '',
    City: '',
    PostalCode: '',
    Roles: [Role.Viewer],
    Street: '',
    PhoneNumber: '',
  };

  return (
    <div className="companies page">
      <div className="page-header">
        <div className="page-header__title">
          <h1 className="title">{t('generic.companies')}</h1>
        </div>
        {checkPermission(Permission.COMPANIES_MANAGE_ALL) && (
          <div className="page-header__actions">
            <Button
              label={t('companies.add')}
              theme={Theme.primary}
              onClick={() => {
                document.querySelector('body')!.classList.add('prevent-scroll');
                setCompanyModalActive(true);
                setSelectedCompany(undefined);
              }}
            />
          </div>
        )}
      </div>

      <div className="search-bar search-bar--margin-bottom">
        <div className="search-bar__filters">
          <Input
            placeholder={t('companies.name')}
            value={name}
            type="text"
            onChange={(e: string | number) => setName(e as string)}
            onSubmit={() => handleSearch()}
          />
          <Input
            placeholder={t('companies.city')}
            value={city}
            type="text"
            onChange={(e: string | number) => setCity(e as string)}
            onSubmit={() => handleSearch()}
          />
        </div>
        <div className="search-bar__submit">
          <Button
            label={t('generic.search')}
            icon={Icon.chevronRight}
            theme={Theme.primary}
            onClick={() => handleSearch()}
          />
        </div>
      </div>

      {checkPermission(Permission.COMPANIES_MANAGE_ALL) || checkPermission(Permission.COMPANIES_MANAGE_ALL) ? (
        <DataTable
          ref={tableRef}
          columns={columns}
          dataCall={handleDataCall}
          keyAttribute="Id"
          initialSortingState={[{ id: 'Name', desc: false }]}
        />
      ) : (
        <>{t('generic.notAuthorized')}</>
      )}

      {companyModalActive && (
        <CompanyModal
          handleClose={() => {
            document.querySelector('body')!.classList.remove('prevent-scroll');
            setCompanyModalActive(false);
          }}
          save={onSave}
          company={!selectedCompany ? defaultCompany : selectedCompany}
          roles={roles}
        />
      )}
      {confirmDeleteModalActive && (
        <MonetDeleteModal
          close={() => {
            document.querySelector('body')!.classList.remove('prevent-scroll');
            setConfirmDeleteModalActive(false);
          }}
          confirm={onDelete}
          title={t('companies.deleteCompany')}
          entityName={selectedCompany?.Name || ''}
        />
      )}
    </div>
  );
};

export default Companies;
