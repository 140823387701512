import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { AxiosResponse } from 'axios';
import useBem from '@frontend/use-bem';
import {
  Accordion,
  Button,
  GoogleMaps,
  InfoBlock,
  Question,
  StatusSymbol,
  Tabs,
  Title,
} from '../../../Shared/Components';
import { Assessment, Icon, Permission, SafetyStatus, Size, Status, Theme } from '../../../Shared/Enums';
import { useRie } from '../../../Hooks';
import { AnswerSafety, Rie } from '../../../Shared/Models';
import IconButton from '../../../Shared/Components/IconButton/IconButton';
import http from '../../../Utils/Axios/http';
import Loader from '../../../Shared/Components/Loader/Loader';
import Operator, { getOperator } from '../../../Shared/Enums/Operator';
import ImageSlider from '../../../Shared/Components/ImageSlider/ImageSlider';
import MonetModal from '../../../Shared/Components/MonetModal/MonetModal';
import {
  validateLatitude,
  validateLongitude,
  validatePostalCode,
  validateSiteNumber,
} from '../../../Utils/Helpers/ValidationHelpers';
import Answer from '../../../Shared/Models/Answer';
import useMonetUser from '../../../Hooks/useMonetUser';
import { useErrorModal } from '../../../Shared/Providers/ErrorContextProvider';
import formatUsername from '../../Users/Util/UserNameFormatter';

import './RieDetail.scss';

const RieDetail = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const { permissions, checkPermission, checkOperatorPermission } = useMonetUser();
  const { bemClassName, bemClassNames } = useBem('rie-detail');

  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);
  const [showQuarantineModal, setShowQuarantineModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [showQuarantineActions, setShowQuarantineActions] = useState(false);
  const [showDeletionActions, setShowDeletionActions] = useState(false);
  const [showDeArchive, setShowDeArchive] = useState(false);
  const [showRequestDeletionModal, setShowRequestDeletionModal] = useState(false);
  const [showDeArchiveModal, setShowDeArchiveModal] = useState(false);
  const [showInvalidRieModal, setShowInvalidRieModal] = useState(false);
  const [isApproval, setIsApproval] = useState(true);
  const [isDeletion, setIsDeletion] = useState(false);
  const [remark, setRemark] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [pendingApproval, setPendingApproval] = useState(false);
  const [activeAccordions, setActiveAccordions] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [rie, setRie] = useState<Rie | null>(null);
  const [dirtyFields, setDirtyFields] = useState<string[]>([]);
  const [currentRie, setCurrentRie] = useState<string>('');
  const [currentOperator, setCurrentOperator] = useState<Operator | null>(null);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState<boolean>(false);
  const [isArchiveDisabled, setIsArchiveDisabled] = useState<boolean>(false);
  const [isDeArchiveDisabled, setIsDeArchiveDisabled] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [editImagesModalActive, setEditImagesModalActive] = useState<boolean>(false);
  const [rieImages, setRieImages] = useState<string[]>([]);
  const [showRoute, setShowRoute] = useState<boolean>(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useAuth0();

  const operator = searchParams.get('operator') as Operator | null;

  const { isLoading, data } = useRie(id || '', operator);
  const { setError } = useErrorModal();

  useEffect(() => {
    if (data && (currentRie !== data.Id || operator !== currentOperator)) {
      if (data.Id === 'new') {
        data.InspectorName = formatUsername(user) ?? 'Onbekend';
      }
      if (currentRie !== data.Id) {
        setDirtyFields([]);
      }
      setCurrentOperator(data.Operator);
      setCurrentRie(data.Id);
      setRie(data);
    }

    if (data && rie) {
      setShowQuarantineActions(
        rie.Status === Status.PendingApproval &&
          checkPermission(Permission.RIE_QUARANTINE) &&
          (rie.Operator === Operator.ODIDO || rie.Operator === Operator.VF),
      );
      setShowDeletionActions(
        rie.Status === Status.PendingDeletion &&
          checkPermission(Permission.RIE_QUARANTINE) &&
          (rie.Operator === Operator.ODIDO || rie.Operator === Operator.VF),
      );
      setPendingApproval(rie.Status === Status.PendingApproval);

      setIsEditable(
        (rie.Status === Status.Active || rie.Status === Status.Rejected) &&
          checkOperatorPermission(Permission.RIE_SUBMIT, rie.Operator) &&
          (rie.Operator === Operator.ODIDO || rie.Operator === Operator.VF) &&
          rie.HasPendingRie === false,
      );
      setIsNew(rie.Id === 'new');
      setShowDeArchive(
        rie.Status === Status.Archived &&
          data.Id !== 'new' &&
          checkPermission(Permission.RIE_QUARANTINE) &&
          (rie.Operator === Operator.ODIDO || rie.Operator === Operator.VF),
      );
    }
  }, [data, rie, user, id, permissions]);

  if (isLoading) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  const setRieUpdated = (dirtyKey?: string) => {
    if (!rie) {
      return;
    }

    if (dirtyKey && !dirtyFields.includes(dirtyKey)) {
      dirtyFields.push(dirtyKey);
      setDirtyFields([...dirtyFields]);
    }

    setRie({ ...rie });
    setHasChanges(true);
  };

  // @todo add validation to infoblock inputs
  const updateAddress = (address: string) => {
    rie!.Address = address;
    setRieUpdated('address');
  };

  const updateX = (x: string) => {
    rie!.X = x;
    setRieUpdated('x');
  };

  const updateY = (y: string) => {
    rie!.Y = y;
    setRieUpdated('y');
  };

  const updatePostalCode = (postalCode: string) => {
    rie!.PostalCode = postalCode;
    setRieUpdated('postalCode');
  };

  const updateCity = (city: string) => {
    rie!.City = city;
    setRieUpdated('city');
  };

  const updateSiteNumber = (siteNumber: string) => {
    rie!.SiteNumber = siteNumber;
    setRieUpdated('site');
  };

  const updateInspectionDate = (inspectionDate?: Date) => {
    if (inspectionDate) {
      rie!.InspectionDate = inspectionDate.toISOString();
    }

    setRieUpdated('inspectionDate');
  };

  const updateInspectorName = (inspectorName: string) => {
    rie!.InspectorName = inspectorName;
    setRieUpdated('inspectorName');
  };

  const updateInspectorParty = (inspectorParty: string) => {
    rie!.InspectorParty = inspectorParty;
    setRieUpdated('inspectorParty');
  };

  // @todo Translations.
  const renderTabs = () => (
    <Tabs
      tabs={[
        {
          title: t('rie.details.info.title'),
          onClick: () => setActiveTab(0),
        },
        {
          title: t('rie.details.address.title'),
          onClick: () => setActiveTab(1),
        },
        {
          title: t('rie.details.party.title'),
          onClick: () => setActiveTab(2),
        },
        {
          title: t('rie.details.party.route'),
          onClick: () => setActiveTab(3),
        },
        { icon: Icon.marker, url: '/locations' },
      ]}
    />
  );

  const isDirty = (key: string): boolean => dirtyFields.includes(key);

  const renderInfoBlocks = () => (
    <>
      {rie && (
        <InfoBlock
          editable={isEditable}
          className="rie-detail__info-block"
          visible={activeTab === 0}
          title={t('rie.details.info.title')}
          version={rie?.Version.toString()}
          items={[
            { label: t('rie.details.info.operator'), value: getOperator(rie.Operator), editable: false },
            {
              label: t('rie.details.info.site'),
              value: rie.SiteNumber,
              dataType: 'text',
              editable: isEditable && isNew,
              onValueChange: (value) => updateSiteNumber(value as string),
              valid: validateSiteNumber(rie.SiteNumber, rie.Operator),
              validationMessage:
                rie.SiteNumber === ''
                  ? t('generic.validation.fieldRequired')
                  : t(`generic.validation.invalidSite.${operator}`),
              dirty: isDirty('site'),
            },
            {
              label: t('rie.details.info.safetyStatus'),
              editable: false,
              dataType: 'node',
              node: (
                <div className="safety-status-symbol-wrapper">
                  <StatusSymbol
                    status={rie.SafetyStatus}
                    size={Size.small}
                  />
                  <p>{t(`generic.colors.${rie.SafetyStatus}`)}</p>
                </div>
              ),
            },
            {
              label: t('rie.details.info.inventory'),
              value: rie.InspectionDate,
              dataType: 'date',
              onValueChange: (value) => updateInspectionDate(value as Date),
              valid: rie.InspectionDate !== '' && rie.InspectionDate !== undefined,
              validationMessage: t('generic.validation.fieldRequired'),
              dirty: isDirty('inspectionDate'),
            },
            {
              label: t('rie.details.info.status.title'),
              value: t(`rie.details.info.status.${rie.Status}`),
              editable: false,
              visible: rie.Status !== Status.Active,
            },
          ]}
        />
      )}
      {rie && (
        <InfoBlock
          editable={isEditable}
          className="rie-detail__info-block"
          visible={activeTab === 1}
          title={t('rie.details.address.title')}
          items={[
            {
              label: t('rie.details.address.title'),
              value: rie.Address,
              dataType: 'text',
              onValueChange: (value) => updateAddress(value as string),
              valid: rie.Address !== '',
              validationMessage: t('generic.validation.fieldRequired'),
              dirty: isDirty('address'),
            },
            {
              label: t('rie.details.address.postalCode'),
              value: rie.PostalCode,
              dataType: 'text',
              onValueChange: (value) => updatePostalCode(value as string),
              valid: rie.PostalCode !== '' && validatePostalCode(rie.PostalCode),
              validationMessage:
                rie.PostalCode === ''
                  ? t('generic.validation.fieldRequired')
                  : t('companies.validation.postalCode.invalid'),
              dirty: isDirty('postalCode'),
            },
            {
              label: t('rie.details.address.city'),
              value: rie.City,
              dataType: 'text',
              onValueChange: (value) => updateCity(value as string),
              valid: rie.City !== '',
              validationMessage: t('generic.validation.fieldRequired'),
              dirty: isDirty('city'),
            },
            {
              label: t('rie.details.address.x'),
              value: rie.X,
              dataType: 'text',
              onValueChange: (value) => updateX(value as string),
              valid: validateLongitude(rie.X as string),
              validationMessage:
                rie.X === ''
                  ? t('generic.validation.fieldRequired')
                  : t('generic.validation.invalidLatLong', { type: 'longitude', min: -180, max: 180 }),
              dirty: isDirty('x'),
            },
            {
              label: t('rie.details.address.y'),
              value: rie.Y,
              dataType: 'text',
              onValueChange: (value) => updateY(value as string),
              valid: validateLatitude(rie.Y as string),
              validationMessage:
                rie.Y === ''
                  ? t('generic.validation.fieldRequired')
                  : t('generic.validation.invalidLatLong', { type: 'latitude', min: -90, max: 90 }),
              dirty: isDirty('y'),
            },
          ]}
        />
      )}
      {rie && (
        <InfoBlock
          editable={isEditable}
          className="rie-detail__info-block"
          visible={activeTab === 2}
          title={t('rie.details.party.title')}
          items={[
            {
              label: t('rie.details.party.party'),
              value: rie.InspectorParty,
              dataType: 'text',
              onValueChange: (value) => updateInspectorParty(value as string),
              valid: rie.InspectorParty !== '',
              validationMessage: t('generic.validation.fieldRequired'),
              dirty: isDirty('inspectorParty'),
            },
            {
              label: t('rie.details.party.executor'),
              value: rie.InspectorName,
              dataType: 'text',
              onValueChange: (value) => updateInspectorName(value as string),
              valid: rie.InspectorName !== '',
              validationMessage: t('generic.validation.fieldRequired'),
              dirty: isDirty('inspectorName'),
            },
            {
              label: t('rie.details.party.lastUpdatedBy'),
              dataType: 'plain',
              value: rie.EditedByName,
            },
          ]}
        />
      )}
      {rie && rie.Id !== 'new' && (
        <div className={bemClassNames([bemClassName('map'), bemClassName('map', 'visible', activeTab === 3)])}>
          <GoogleMaps
            key={rie.Id}
            ries={[rie]}
            allRies={showRoute ? [] : [rie]}
            isDetail
            showRoute={showRoute}
          />
          <div className="rie-detail__map-actions">
            <Button
              label={t('rie.details.actions.showMap')}
              theme={Theme.primary}
              onClick={() => setShowRoute(true)}
              disabled={showRoute}
            />
            <Button
              label={t('rie.details.actions.navigate')}
              theme={Theme.dark}
              onClick={() => window.open(`https://maps.google.com?q=${rie?.Y} , ${rie?.X}`)}
            />
          </div>
        </div>
      )}
    </>
  );

  const calculateSafetyStatus = (safeties: AnswerSafety[]): SafetyStatus => {
    if (safeties.some((s) => s.Safety === SafetyStatus.Red)) {
      return SafetyStatus.Red;
    }
    if (safeties.some((s) => s.Safety === SafetyStatus.Orange)) {
      return SafetyStatus.Orange;
    }
    if (safeties.some((s) => s.Safety === SafetyStatus.Yellow)) {
      return SafetyStatus.Yellow;
    }
    if (safeties.some((s) => s.Safety === SafetyStatus.Green)) {
      return SafetyStatus.Green;
    }
    return SafetyStatus.Grey;
  };

  const uploadFiles = async (files: File[]): Promise<string[]> => {
    const newFileIds: string[] = [];
    setShowLoader(true);

    const promises = [];

    for (let i = 0; i < files.length; i += 1) {
      const formRie = new FormData();
      formRie.append('file', files[i] as Blob, files[i].name);
      promises.push(http.post(`/File/Files/`, formRie));
    }

    await Promise.all(promises)
      .then((values) => {
        for (let i = 0; i < values.length; i += 1) {
          // @ts-ignore
          newFileIds.push(values[i].data.Id);
        }
      })
      .finally(() => {
        setShowLoader(false);
      });

    return newFileIds;
  };

  const handleAssessmentUpdate = async (answer: Answer) => {
    if (!rie) return;

    setShowLoader(true);
    http
      .post<Answer, AxiosResponse<AnswerSafety>>(`/Rie/Ries/CalculateSafetyForAnswer?version=${rie.Version}`, answer)
      .then((result) => {
        rie.AnswersSafety.find((answersSafety) => answersSafety.QuestionId === answer.QuestionId)!.Safety =
          result.data.Safety;

        rie.SafetyStatus = calculateSafetyStatus(rie.AnswersSafety);
      })
      .catch((result) => {
        setError(result.data.value);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const handleRieUpdate = async (
    questionKey: string,
    description: string,
    date: string | undefined,
    dateInspectionValidUntil: string | undefined,
    dateVisualInspectionValidUntil: string | undefined,
    assessment: Assessment,
    files: File[],
    attachments: string[],
  ) => {
    if (rie) {
      const answerToUpdate = rie.Answers.find((answer) => answer.QuestionId === questionKey);
      if (answerToUpdate) {
        const index = rie.Answers.findIndex((answer) => answer === answerToUpdate);

        if (answerToUpdate.Assessment !== assessment) {
          answerToUpdate.Assessment = assessment;

          await handleAssessmentUpdate(answerToUpdate);
        }

        answerToUpdate.Description = description;
        answerToUpdate.InspectionDate = date;
        answerToUpdate.InspectionValidUntil = dateInspectionValidUntil;
        answerToUpdate.VisualInspectionValidUntil = dateVisualInspectionValidUntil;
        answerToUpdate.Attachments = attachments.length > 0 ? attachments : [];

        if (files.length > 0) {
          const newIds = await uploadFiles(files);
          answerToUpdate.Attachments = [...answerToUpdate.Attachments, ...newIds];
        }
        rie.Answers[index] = answerToUpdate;

        rie.Version = '2.9';

        setRie({ ...rie });
        setHasChanges(true);
      }
    }
  };

  const getChapterCount = (r: Rie) => {
    if (r && r.AnswersSafety) {
      return Math.max(...r.AnswersSafety.map((answer) => Number(answer.ChapterId)));
    }
    return 0;
  };

  const handleOnEditImagesClick = () => {
    if (checkPermission(Permission.RIE_SUBMIT)) {
      document.querySelector('body')!.classList.add('prevent-scroll');
      setRieImages(rie?.Images as string[]);
      setEditImagesModalActive(!editImagesModalActive);
    }
  };

  const handleRemoveAttachment = (attachment: string) => {
    const index = rieImages.findIndex((f) => f === attachment);
    if (index !== -1) {
      rieImages.splice(index as number, 1);
    }
    setRieImages([...rieImages]);
  };

  const renderAccordions = () => {
    const accordions = [];
    if (rie) {
      for (let i = 1; i <= getChapterCount(rie); i += 1) {
        const answers = rie.Answers.filter((answer) => answer.QuestionId.startsWith(`${i}.`));
        const status = calculateSafetyStatus(rie.AnswersSafety.filter((s) => s.ChapterId === i.toString(10)));

        accordions.push(
          <Accordion
            prefix={
              <StatusSymbol
                number={i}
                status={status}
              />
            }
            title={t(`rie.${rie.Version}.${i}`)}
            className="rie-detail__accordion"
            key={i}
            activeState={activeAccordions}
          >
            {answers.map((answer) => (
              <Question
                questionId={answer.QuestionId}
                chapter={t(`rie.${rie.Version}.${i}`)}
                question={t(`rie.${rie.Version}.${answer.QuestionId}`)}
                assessment={answer.Assessment}
                safetyStatus={
                  rie.AnswersSafety.find((answersSafety) => answersSafety.QuestionId === answer.QuestionId)
                    ?.Safety as SafetyStatus
                }
                attachments={answer.Attachments}
                description={answer.Description}
                key={answer.QuestionId}
                showDate={answer.QuestionId.startsWith('9')} // @todo there is no proper specification implementation to retrieve whether a date needs to be set here...
                showValidUntilDates={answer.QuestionId.startsWith('9')} // @todo there is no proper specification implementation to retrieve whether a date needs to be set here...
                date={answer.InspectionDate}
                dateInspectionValidUntil={answer.InspectionValidUntil}
                dateVisualInspectionValidUntil={answer.VisualInspectionValidUntil}
                updateValueHandler={(
                  description: string,
                  date: string | undefined,
                  dateInspectionValidUntil: string | undefined,
                  dateVisualInspectionValidUntil: string | undefined,
                  assessment: Assessment,
                  files: File[],
                  attachments: string[],
                ) =>
                  handleRieUpdate(
                    answer.QuestionId,
                    description,
                    date,
                    dateInspectionValidUntil,
                    dateVisualInspectionValidUntil,
                    assessment,
                    files,
                    attachments,
                  )
                }
                showEdit={isEditable}
              />
            ))}
          </Accordion>,
        );
      }
      if (rie.Images && rie.Images.length > 0) {
        accordions.push(
          <Accordion
            prefix={
              <StatusSymbol
                number="F"
                status={SafetyStatus.Grey}
              />
            }
            title={t(`rie.${rie.Version}.images`, { count: rie.Images.length })}
            className="rie-detail__accordion"
            key="rie-photos"
            activeState={activeAccordions}
          >
            <div className="rie-detail__image-container">
              {rie.Images.length > 0 && (
                <div className="rie-detail__image-inner-container">
                  {checkPermission(Permission.RIE_SUBMIT) && (
                    <div className="rie-detail__image-container-button">
                      <IconButton
                        icon={Icon.pencil}
                        label={t('general.edit')}
                        onClick={() => handleOnEditImagesClick()}
                      />
                    </div>
                  )}
                  <div className="question__image-container">
                    <ImageSlider images={rie.Images} />
                  </div>
                </div>
              )}
            </div>
          </Accordion>,
        );
      }
    }

    return accordions;
  };

  const processEdit = async (approved: boolean) => {
    const type = approved ? 'approve' : 'reject';

    setShowLoader(true);
    setIsSubmitDisabled(true);
    http
      .post(`/Rie/Ries/${id}/${type}`, { RejectionRemarks: remark })
      .then(() => {
        if (approved) {
          rie!.Status = Status.Active;
        } else {
          rie!.Status = Status.Rejected;
        }
        rie!.HasPendingRie = false;

        setRie({ ...rie! });
        setRemark('');
        queryClient.invalidateQueries(['ries']).then(() => {
          navigate('/quarantine');
        });
      })
      .catch((result) => {
        setError(result.data.value);
      })
      .finally(() => {
        setShowQuarantineModal(false);
        setShowLoader(false);
        setIsSubmitDisabled(false);
      });
  };

  const processDeletion = async (approved: boolean) => {
    const type = approved ? 'ApproveDeletion' : 'RejectDeletion';

    setShowLoader(true);
    setIsSubmitDisabled(true);
    http
      .post(`/Rie/Ries/${id}/${type}`, { RejectionRemarks: remark })
      .then(() => {
        setShowDeletionActions(false);
        setRie({ ...rie! });
        setRemark('');
        queryClient.invalidateQueries(['ries']).then(() => {
          navigate('/quarantine');
        });
      })
      .catch((result) => {
        setError(result.data.value);
      })
      .finally(() => {
        setShowQuarantineModal(false);
        setShowLoader(false);
        setIsSubmitDisabled(false);
      });
  };

  const processQuarantine = async (approved: boolean, isDeletionAction: boolean) => {
    if (isDeletionAction) {
      await processDeletion(approved);
    } else {
      await processEdit(approved);
    }
  };

  const validateRie = (): boolean => {
    if (rie) {
      if (!rie.Operator) return false;
      if (!validateSiteNumber(rie.SiteNumber, rie.Operator)) return false;
      if (!rie.City) return false;
      if (!rie.Address) return false;
      if (!validatePostalCode(rie.PostalCode)) return false;
      if (!rie.InspectionDate) return false;
      if (!rie.InspectorName) return false;
      if (!validateLongitude(rie.X as string)) return false;
      if (!validateLatitude(rie.Y as string)) return false;
    } else {
      return false;
    }
    return true;
  };

  const markAllAsDirty = () => {
    const allDirtyFields = [
      'inspectionDate',
      'address',
      'postalCode',
      'city',
      'x',
      'y',
      'site',
      'inspectorParty',
      'inspectorName',
    ];

    setDirtyFields(allDirtyFields);
  };

  const submitRie = () => {
    const rieToSubmit = {
      OriginatingId: rie?.Id === 'new' ? undefined : rie?.Id,
      Operator: rie?.Operator,
      SiteNumber: rie?.SiteNumber,
      City: rie?.City,
      Address: rie?.Address,
      PostalCode: rie?.PostalCode,
      Version: rie?.Version,
      InspectionDate: rie?.InspectionDate,
      InspectorName: rie?.InspectorName,
      InspectorParty: rie?.InspectorParty,
      Answers: rie?.Answers,
      X: Number(rie?.X),
      Y: Number(rie?.Y),
    };

    if (!validateRie()) {
      setShowInvalidRieModal(true);
      markAllAsDirty();
    } else {
      setShowLoader(true);
      setIsSubmitDisabled(true);
      http
        .post('/Rie/Ries/Submit', rieToSubmit)
        .then((result: any) => {
          setHasChanges(false);
          queryClient.invalidateQueries(['ries']).then(() => {
            navigate(`/locations/${(result.data as Rie).Id}`);
          });
        })
        .catch((result) => {
          setError(result?.data?.value ?? t('generic.errorMessage'));
        })
        .finally(() => {
          setShowLoader(false);
          setIsSubmitDisabled(false);
        });
    }
  };

  const downloadRie = () => {
    setShowLoader(true);
    setIsDownloadDisabled(true);
    http
      .get(`/Rie/Ries/${id}/Download?lng=${i18n.language}`, { responseType: 'blob' })
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `rie-${rie?.Operator.toLowerCase()}-${rie?.SiteNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((result) => {
        setError(result?.data?.value ?? t('generic.errorMessage'));
      })
      .finally(() => {
        setShowLoader(false);
        setIsDownloadDisabled(false);
      });
  };

  const requestDeletion = () => {
    setShowLoader(true);
    setIsArchiveDisabled(true);
    setIsDeArchiveDisabled(false);
    http
      .post(`/Rie/Ries/${id}/RequestDeletion`)
      .then((result) => {
        setShowRequestDeletionModal(false);
        setShowDeArchive(true);
        setHasChanges(false);
        queryClient.invalidateQueries(['ries']).then(() => {
          navigate(`/locations/${(result.data as Rie).Id}`);
        });
      })
      .catch((result) => {
        setError(result?.data?.value ?? t('generic.errorMessage'));
      })
      .finally(() => {
        setShowLoader(false);
        setIsArchiveDisabled(false);
      });
  };

  const deArchiveRie = () => {
    setShowLoader(true);
    setIsArchiveDisabled(false);
    setIsDeArchiveDisabled(true);
    http
      .post(`/Rie/Ries/${id}/DeArchive`)
      .then(() => {
        setShowDeArchiveModal(false);
        setShowDeArchive(false);
        setHasChanges(false);
        queryClient.invalidateQueries(['ries']);
      })
      .catch((result) => {
        setError(result?.data?.value ?? t('generic.errorMessage'));
      })
      .finally(() => {
        setShowLoader(false);
        setIsArchiveDisabled(false);
      });
  };

  const closeEditImages = () => {
    rie!.Images = rieImages;
    setRie(rie);
    setEditImagesModalActive(false);
  };

  return (
    <div className="rie-detail">
      <div className="rie-detail__header">
        <div className="rie-detail__header-info">
          <Title>
            {t('rie.details.site')} {rie?.SiteNumber}
          </Title>
        </div>
        <div className="rie-detail__header-actions">
          {showLoader && (
            <div className="action-loader">
              <Loader />
            </div>
          )}
          {showQuarantineActions && (
            <>
              <Button
                label={t('rie.details.quarantine.approve')}
                onClick={() => {
                  setIsApproval(true);
                  setIsDeletion(false);
                  setShowQuarantineModal(true);
                }}
                disabled={isSubmitDisabled}
              />
              <Button
                label={t('rie.details.quarantine.reject')}
                onClick={() => {
                  setIsApproval(false);
                  setIsDeletion(false);
                  setShowQuarantineModal(true);
                }}
                disabled={isSubmitDisabled}
              />
            </>
          )}
          {showDeletionActions && (
            <>
              <Button
                label={t('rie.details.quarantine.approveDeletion')}
                onClick={() => {
                  setIsApproval(true);
                  setIsDeletion(true);
                  setShowQuarantineModal(true);
                }}
                disabled={isSubmitDisabled}
              />
              <Button
                label={t('rie.details.quarantine.reject')}
                onClick={() => {
                  setIsApproval(false);
                  setIsDeletion(true);
                  setShowQuarantineModal(true);
                }}
                disabled={isSubmitDisabled}
              />
            </>
          )}
          {isEditable && rie?.Status === Status.Active && (
            <Button
              icon={Icon.delete}
              label={t('generic.delete')}
              theme={Theme.dark}
              onClick={() => setShowRequestDeletionModal(true)}
              disabled={isArchiveDisabled}
            />
          )}
          {showDeArchive && (
            <Button
              icon={Icon.archive}
              label={t('rie.deArchive')}
              theme={Theme.dark}
              onClick={() => setShowDeArchiveModal(true)}
              disabled={isDeArchiveDisabled}
            />
          )}
          <Button
            label={activeAccordions ? t('rie.details.actions.hideAll') : t('rie.details.actions.showAll')}
            onClick={() => setActiveAccordions(!activeAccordions)}
          />
          {!isNew && !hasChanges && (
            <Button
              label={t('rie.details.actions.download')}
              theme={Theme.primary}
              onClick={() => downloadRie()}
              disabled={isDownloadDisabled}
            />
          )}
          {isEditable && hasChanges && (
            <Button
              label={t('generic.submit')}
              theme={Theme.primary}
              onClick={() => submitRie()}
              disabled={isSubmitDisabled}
            />
          )}
        </div>
      </div>
      {hasChanges && isEditable && <div className="rie-detail__save-message">{t('rie.details.saveMessage')}</div>}
      {pendingApproval && !checkPermission(Permission.RIE_QUARANTINE) && (
        <div className="rie-detail__save-message">{t('rie.details.pendingApprovalMessage')}</div>
      )}
      <div className="rie-detail__tabs">{renderTabs()}</div>
      <div className="rie-detail__content">
        <div className="rie-detail__info-blocks">{renderInfoBlocks()}</div>
        <div className="rie-detail__accordions">{renderAccordions()}</div>
      </div>
      {showQuarantineModal && (
        <div className="quarantine-modal quarantine-modal--active">
          <div className="quarantine-modal__wrapper">
            <div className="quarantine-modal__header">
              <span className="question-modal__chapter">
                {isApproval ? t('rie.details.quarantine.approve') : t('rie.details.quarantine.reject')}
              </span>
              <IconButton
                className="quarantine-modal__close"
                label="Close"
                icon={Icon.close}
                theme={Theme.transparent}
                onClick={() => setShowQuarantineModal(false)}
              />
            </div>
            <div className="quarantine-modal__footer">
              {!isApproval && (
                <textarea
                  className="quarantine-modal__textarea"
                  name="textarea"
                  id="textarea"
                  placeholder={t('generic.explanation')}
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              )}
              <div className="quarantine-modal__actions">
                {showLoader && <Loader />}
                {!showLoader && isApproval && (
                  <Button
                    className="quarantine-modal__save quarantine-modal__action quarantine-modal__button"
                    label={t('rie.details.quarantine.approve')}
                    theme={Theme.primary}
                    onClick={() => processQuarantine(true, isDeletion)}
                  />
                )}
                {!showLoader && !isApproval && (
                  <Button
                    className="quarantine-modal__save quarantine-modal__action quarantine-modal__button"
                    label={t('rie.details.quarantine.reject')}
                    theme={Theme.primary}
                    onClick={() => processQuarantine(false, isDeletion)}
                  />
                )}
              </div>
            </div>
          </div>
          <Button
            className="quarantine-modal__overlay"
            onClick={() => setShowQuarantineModal(false)}
          />
        </div>
      )}
      {showRequestDeletionModal && (
        <MonetModal
          close={() => setShowRequestDeletionModal(false)}
          confirm={() => requestDeletion()}
          title={t('generic.delete')}
          onConfirmLabel={t('generic.yes')}
          onCloseLabel={t('generic.no')}
        >
          {t('rie.requestDeletionRieMessage')}
        </MonetModal>
      )}
      {showDeArchiveModal && (
        <MonetModal
          close={() => setShowDeArchiveModal(false)}
          confirm={() => deArchiveRie()}
          title={t('rie.deArchive')}
          onConfirmLabel={t('generic.yes')}
          onCloseLabel={t('generic.no')}
        >
          {t('rie.deArchiveRieMessage')}
        </MonetModal>
      )}
      {showInvalidRieModal && (
        <MonetModal
          close={() => setShowInvalidRieModal(false)}
          title={t('rie.details.validation.invalid.title')}
          confirm={() => setShowInvalidRieModal(false)}
          onConfirmLabel={t('generic.close')}
          showClose={false}
        >
          {t('rie.details.validation.invalid.content')}
        </MonetModal>
      )}
      {editImagesModalActive && (
        <MonetModal
          close={() => {
            document.querySelector('body')!.classList.remove('prevent-scroll');
            setEditImagesModalActive(false);
          }}
          title={t('rie.details.deletePhotos')}
          confirm={() => {
            document.querySelector('body')!.classList.remove('prevent-scroll');
            closeEditImages();
          }}
          onConfirmLabel={t('generic.save')}
          showClose
          className="monet-modal--image-edit"
        >
          <div className="monet-modal__files monet-modal__files--existing">
            <ImageSlider
              images={rieImages}
              callback={handleRemoveAttachment}
            />
          </div>
        </MonetModal>
      )}
    </div>
  );
};

export default RieDetail;
