import { useQuery, UseQueryResult } from 'react-query';
import buildQuery from 'odata-query';

import http from '../../Utils/Axios/http';
import { ODataResult } from '../../Features/Rie/Types/ODataResult';
import getODataQueryOptions, {QueryOptions} from "../../Shared/Types/QueryOptions";

const getArchiveRies = async (queryOptions: QueryOptions) => {
  const options = getODataQueryOptions(queryOptions);

  const select = [
    'Id',
    'Operator',
    'SiteNumber',
    'City',
    'Status',
    'SafetyStatus',
    'Address',
    'PostalCode',
    'InspectionDate',
    'X',
    'Y',
  ];
  let query = buildQuery<any>({...options, ...{select}});
  query = query.replaceAll("'tolower(''", "tolower('").replaceAll("'')'", "')");
  const { data } = await http.get(`/Rie/Ries/Archive${query}`);
  return {
    value: data.value,
    count: data['@odata.count'],
  };
};

export default function useArchiveRies(queryOptions: QueryOptions): UseQueryResult<ODataResult, Error> {
  return useQuery(['ries', 'archive', queryOptions], () => getArchiveRies(queryOptions));
}
