import React from 'react';

type TextCellType = {
  renderValue: string;
};

const TextCell = ( { renderValue } : TextCellType ) =>
    (
        <div
            className="table-cell-value"
            data-text={renderValue}
        >
          {renderValue}
        </div>
    );

export default TextCell;
