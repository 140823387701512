import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
// @ts-ignore
import Files from 'react-butterfiles';
import IconButton from '../IconButton/IconButton';
import Button from '../Button/Button';
import { Assessment, IconPosition, Icon, Theme } from '../../Enums';
import ImageSlider from '../ImageSlider/ImageSlider';

import './QuestionModal.scss';

type PropType = {
  attachments?: string[];
  assessment: Assessment;
  prefix?: React.ReactNode;
  chapter: string;
  question: string;
  handleClose: () => void;
  value: string;
  updateValue: (
    description: string,
    date: string | undefined,
    dateInspectionValidUntil: string | undefined,
    dateVisualInspectionValidUntil: string | undefined,
    assessment: Assessment,
    files: File[],
    attachments: string[],
  ) => void;
  date?: string;
  dateInspectionValidUntil?: string;
  dateVisualInspectionValidUntil?: string;
  showDate?: boolean;
  showValidUntilDates?: boolean;
};

type FileError = {
  file: File;
  id: string;
  index: number;
  type: string;
};

const QuestionModal = (props: PropType) => {
  const {
    attachments,
    assessment,
    prefix,
    chapter,
    question,
    handleClose,
    updateValue,
    value,
    date,
    dateInspectionValidUntil,
    dateVisualInspectionValidUntil,
    showDate,
    showValidUntilDates,
  } = props;
  const { i18n, t } = useTranslation();

  const [questionAssessment, setQuestionAssessment] = useState(assessment);
  const [questionValue, setQuestionValue] = useState(value);
  const [dateValue, setDate] = useState(date);
  const [dateInspectionValidUntilValue, setDateInspectionValidUntilValue] = useState(dateInspectionValidUntil);
  const [dateVisualInspectionValidUntilValue, setDateVisualInspectionValidUntilValue] =
    useState(dateVisualInspectionValidUntil);
  const [files, setFiles] = useState<File[]>([]);
  const [attachedFiles, setAttachedFiles] = useState<string[]>([...(attachments as string[])]);
  const [fileErrors, setFileErrors] = useState<FileError[]>([]);
  const parsedDate = dateValue ? new Date(dateValue) : undefined;
  const parsedInspectionValidUntilDate = dateInspectionValidUntilValue
    ? new Date(dateInspectionValidUntilValue)
    : undefined;
  const parsedVisualInspectionValidUntilDate = dateVisualInspectionValidUntilValue
    ? new Date(dateVisualInspectionValidUntilValue)
    : undefined;

  const fileSettings = {
    maxSize: '40mb',
    allowMultiple: true,
    supportedFormats: ['.png', '.jpg', '.jpeg'],
    multipleMaxSize: '400mb',
  };
  const supportedFormatMimeTypes = fileSettings.supportedFormats.map((format: string) => format.replace('.', 'image/'));

  const handleUpdateValue = () => {
    updateValue(
      questionValue,
      dateValue,
      dateInspectionValidUntilValue,
      dateVisualInspectionValidUntilValue,
      questionAssessment,
      files,
      attachedFiles,
    );
    setFiles([]);
  };

  const handleRemoveFile = (file: File) => {
    const index = files.findIndex((f) => f === file);
    files.splice(index, 1);
    setFiles([...files]);
  };

  const handleRemoveAttachment = (attachment: string) => {
    const index = attachedFiles?.findIndex((f) => f === attachment);
    if (index !== -1) {
      attachedFiles?.splice(index as number, 1);
    }
    setAttachedFiles([...attachedFiles]);
  };

  const handleError = (type: string) => (
    <>
      {type === 'unsupportedFileType' && t(`generic.files.error.${type}`, { types: fileSettings.supportedFormats })}
      {type === 'maxSizeExceeded' && t(`generic.files.error.${type}`, { maxSize: fileSettings.maxSize })}
      {type === 'multipleMaxSizeExceeded' &&
        t(`generic.files.error.${type}`, { maxSize: fileSettings.multipleMaxSize })}
      {type === 'multipleNotAllowed' && t(`generic.files.error.${type}`)}
    </>
  );

  const renderFileErrors = () => (
    <div>
      <div className="file-upload-error__title">{t('generic.files.error.title', { count: fileErrors.length })}</div>
      {fileErrors.map((error) => {
        if (error.file) {
          return (
            <div
              className="file-upload-error"
              key={error.file.name}
            >
              {error.file && (
                <>
                  <div className="file-upload-error__file">{error.file.name}</div>
                  <ul className="file-upload-error__list">
                    <li
                      className="file-upload-error__item"
                      key={`${error.file.name} ${error.type}`}
                    >
                      {handleError(error.type)}
                    </li>
                  </ul>
                </>
              )}
            </div>
          );
        }
        return (
          <div
            className="file-upload-error"
            key={error.type}
          >
            <ul className="file-upload-error__list">
              <li
                className="file-upload-error__item"
                key={`${error.type}`}
              >
                {handleError(error.type)}
              </li>
            </ul>
          </div>
        );
      })}
    </div>
  );

  const renderFiles = () => {
    if (files.length > 0) {
      return (
        <div className="monet-modal__files">
          <ul>
            {files.map((file: File, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={file.name + index}>
                <div className="monet-modal__file-name">
                  <span>{file.name}</span>
                </div>
                <div className="monet-modal__file-action">
                  <IconButton
                    className="monet-modal__files-action"
                    icon={Icon.close}
                    theme={Theme.primary}
                    label="delete"
                    onClick={() => {
                      handleRemoveFile(file);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  const renderAttachments = () => (
    <div className="monet-modal__files monet-modal__files--existing">
      <ImageSlider
        images={attachedFiles}
        callback={handleRemoveAttachment}
      />
    </div>
  );

  return (
    <div className="monet-modal">
      <div className="monet-modal__wrapper monet-modal__wrapper--question">
        <div className="monet-modal__header">
          <div className="monet-modal__prefix">{prefix}</div>
          <span className="monet-modal__chapter">{chapter}</span>
          <IconButton
            className="monet-modal__close"
            label={t('generic.close')}
            icon={Icon.close}
            theme={Theme.transparent}
            onClick={handleClose}
          />
        </div>
        <div className="monet-modal__content">
          <span className="monet-modal__question">{question}</span>
          <div className="monet-modal__assessments">
            <Button
              className="monet-modal__assessment monet-modal__button"
              label={t('generic.yes')}
              theme={questionAssessment === Assessment.Yes ? Theme.primary : Theme.dark}
              onClick={() => setQuestionAssessment(Assessment.Yes)}
            />
            <Button
              className="monet-modal__assessment monet-modal__button"
              label={t('generic.no')}
              theme={questionAssessment === Assessment.No ? Theme.primary : Theme.dark}
              onClick={() => setQuestionAssessment(Assessment.No)}
            />
            <Button
              className="monet-modal__assessment monet-modal__button"
              label={t('generic.notApplicable')}
              theme={questionAssessment === Assessment.NotApplicable ? Theme.primary : Theme.dark}
              onClick={() => setQuestionAssessment(Assessment.NotApplicable)}
            />
          </div>

          {showDate && !showValidUntilDates && (
            <DatePicker
              selected={parsedDate}
              onChange={(e) => setDate(e?.toISOString())}
              locale={i18n.language}
              placeholderText={t('generic.date')}
              dateFormat="PP"
            />
          )}

          {showDate && showValidUntilDates && (
            <>
              <span>
                <b>{t('generic.inspection-valid-till-date')}</b>
              </span>
              <DatePicker
                selected={parsedInspectionValidUntilDate}
                onChange={(e) => setDateInspectionValidUntilValue(e?.toISOString())}
                locale={i18n.language}
                placeholderText={t('generic.inspection-valid-till-date')}
                dateFormat="PP"
              />
              <span>
                <b>{t('generic.visual-inspection-valid-till-date')}</b>
              </span>
              <DatePicker
                selected={parsedVisualInspectionValidUntilDate}
                onChange={(e) => setDateVisualInspectionValidUntilValue(e?.toISOString())}
                locale={i18n.language}
                placeholderText={t('generic.visual-inspection-valid-till-date')}
                dateFormat="PP"
              />
            </>
          )}
          <span>
            <b>{t('generic.description')}</b>
          </span>
          <textarea
            className="monet-modal__textarea"
            name="textarea"
            id="textarea"
            placeholder={t('generic.description')}
            value={questionValue}
            onChange={(e) => setQuestionValue(e.target.value)}
          />
          {renderFiles()}
          {attachments && renderAttachments()}
          {fileErrors.length > 0 && renderFileErrors()}
        </div>
        <div className="monet-modal__footer">
          <Files
            multiple={fileSettings.allowMultiple}
            maxSize={fileSettings.maxSize}
            multipleMaxSize={fileSettings.multipleMaxSize}
            accept={supportedFormatMimeTypes}
            onSuccess={(newFiles: any) => {
              newFiles.forEach((f: any) => files.push(f.src.file));
              setFiles([...files]);
              setFileErrors([]);
            }}
            onError={(errors: any) => {
              setFileErrors([...errors]);
            }}
            id="files-upload-component"
          >
            {({ browseFiles }: any) => (
              <Button
                className="monet-modal__upload monet-modal__action monet-modal__button"
                label={t('generic.files.upload')}
                theme={Theme.dark}
                icon={Icon.image}
                iconPosition={IconPosition.left}
                onClick={browseFiles}
              />
            )}
          </Files>
          <Button
            className="monet-modal__save monet-modal__action monet-modal__button"
            label={t('generic.edit')}
            theme={Theme.primary}
            onClick={() => handleUpdateValue()}
          />
        </div>
      </div>
      <Button
        className="monet-modal__overlay"
        onClick={handleClose}
      />
    </div>
  );
};

QuestionModal.defaultProps = {
  attachments: [],
  prefix: '',
  showDate: false,
  showValidUntilDates: false,
  date: undefined,
  dateInspectionValidUntil: undefined,
  dateVisualInspectionValidUntil: undefined,
};

export default QuestionModal;
