import React from 'react';
import { getOperator } from '../../../Enums/Operator';
import TextCell from "./TextCell";

const formatOperator = (op: string) => (op ? getOperator(op) : '');
const OperatorCell = ({ getValue }: any) => {
  const renderValue = formatOperator(getValue());
  return (<TextCell renderValue={renderValue} />);
};

export const OperatorsCell = (values: any) => values.getValue().map(formatOperator).join(', ');

export default OperatorCell;
