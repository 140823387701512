import { useQuery, UseQueryResult } from 'react-query';
import { Rie } from '../../Shared/Models';
import http from '../../Utils/Axios/http';
import newRie from '../../Features/Rie/Data/newRie';
import { Operator } from '../../Shared/Enums';

const getRieById = async (id: string) => {
  const { data } = await http.get(`/Rie/Ries/${id}`);
  return data;
};

export default function useRie(id: string, operator: Operator | null): UseQueryResult<Rie, Error> {
  if (id === 'new') {
    return useQuery([operator], () => Promise.resolve(newRie(operator)));
  }

  return useQuery(['ries', 'rie', id], () => getRieById(id), { staleTime: Infinity });
}
