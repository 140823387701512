import { Operator } from '../../Shared/Enums';

const validatePostalCode = (postalCode: string): boolean => {
  if (postalCode === '') return false;

  const postalCodeRegex = /^[1-9][0-9]{3} {0,1}?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$/;
  return postalCodeRegex.test(postalCode);
};

const validateSiteNumber = (siteNumber: string, operator: Operator): boolean => {
  if (siteNumber.length > 16) return false;
  if (siteNumber === '') return false;

  if (operator === Operator.VF) {
    if (!siteNumber.startsWith('S')) return false;
    if (siteNumber.length < 6 || siteNumber.length > 8) return false;
  }

  if (operator === Operator.ODIDO) {
    if (!siteNumber.startsWith('L')) return false;
    if (siteNumber.length !== 7) return false;
  }

  if (operator === Operator.KPN) {
    const kpnRegex = /\d+/;
    if (!kpnRegex.test(siteNumber)) return false;
  }

  return true;
};

// TODO: Maybe limit to just within lat longs that are within the country
const validateLongitude = (value: string): boolean => {
  if (value === '') return false;

  const longitude = Number(value);
  if (Number.isNaN(longitude)) return false;
  if (longitude < -180) return false;
  if (longitude > 180) return false;
  return true;
};

const validateLatitude = (value: string): boolean => {
  if (value === '') return false;

  const latitude = Number(value);
  if (Number.isNaN(latitude)) return false;
  if (latitude < -90) return false;
  if (latitude > 90) return false;
  return true;
};

export { validatePostalCode, validateSiteNumber, validateLongitude, validateLatitude };
