import buildQuery from 'odata-query';
import http from '../../../Utils/Axios/http';
import { User } from '../../../Shared/Models';
import getODataQueryOptions, { QueryOptions } from '../../../Shared/Types/QueryOptions';
import FilterObjectType from '../../../Shared/Types/FilterObjectType';

const base = '/CoreAdministration/Users/';

const deleteUser = async (id: string) => http.delete(`${base}${id}`);

const getUsers = async (page: number, size: number = 10) => {
  const top = size;
  const skip = size * (page - 1);
  const count = true;
  const query = buildQuery({ top, skip, count });

  const { data } = await http.get(`${base}${query}`);
  return {
    value: data.value,
    count: data['@odata.count'],
  };
};

const getUsersForCompanyById = async (queryOptions: QueryOptions) => {
  const expand = {
    Company: {},
  };

  // @ts-ignore
  let query = buildQuery({ ...getODataQueryOptions(queryOptions), ...{ expand } });
  query = query.replace("'guid", ''); // Jumping through hoops for odata, DO NOT REMOVE
  query = query.replace("guid'", ''); // Jumping through hoops for odata, DO NOT REMOVE

  const { data } = await http.get(`${base}${query}`);
  return {
    value: data.value,
    count: data['@odata.count'],
  };
};

const getUserByEmail = async (email?: string) => {
  if (!email) {
    return {
      value: [],
      count: 0,
    };
  }

  const expand = {
    Company: {
      select: ['Name'],
    },
  };

  const filter: FilterObjectType = {
    and: [{ EmailAddress: { contains: email } }],
  };

  const queryOptions: QueryOptions = {
    pageSize: 1,
    pageIndex: 0,
    sorting: [],
    filter,
  };

  // @ts-ignore
  const query = buildQuery({ ...getODataQueryOptions(queryOptions), ...{ expand } });

  const { data } = await http.get(`${base}${query}`);
  return data.value[0];
};

const createUser = async (user: User) => {
  const data = { ...user };

  // These fields are removed from the Data object (copy of user), to ensure UpdateUser works the call can not handle extra fields being sent
  delete data.Id;
  delete data.Operators;

  return http.post(`${base}CreateUser`, data);
};

const updateUser = async (user: User) => {
  const data = { ...user };

  // These fields are removed from the Data object (copy of user), to ensure UpdateUser works the call can not handle extra fields being sent
  delete data.Id;
  delete data.CompanyId;
  delete data.Operators;

  return http.post(`${base}${user.Id}/UpdateUser`, data);
};

export { createUser, deleteUser, getUsers, getUsersForCompanyById, updateUser, getUserByEmail };
