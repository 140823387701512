import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { t } from 'i18next';
import Operator from '../Shared/Enums/Operator';
import Permission from '../Shared/Enums/Permission';
import useUserByEmail from '../Features/Users/Hooks/useUserByEmail';
import { RoleSelectType } from '../Shared/Types/RoleSelectType';
import { Role } from '../Shared/Enums';

type Auth0JwtPayload = JwtPayload & {
  permissions: string[];
};

const useMonetUser = () => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const [ops, setOps] = useState<Operator[]>([]);

  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const { data, isLoading, error } = useUserByEmail(user?.email);

  useEffect(() => {
    if (!isAuthenticated) {
      setPermissions([]);
      return;
    }

    if (!isLoading && error) {
      if ((error as any).data.status === 401) {
        logout();
      }
    }

    if (!isLoading && data) {
      setOps(data.Operators ?? []);
    }

    (async () => {
      try {
        // eslint-disable-next-line no-underscore-dangle
        const token = await getAccessTokenSilently({ audience: window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_AUDIENCE });

        if (token) {
          const decoded: Auth0JwtPayload = jwtDecode(token);

          setPermissions(decoded.permissions ?? []);
        }
      } catch (e: any) {
        throw new Error(e.message);
      }
    })();
  }, [isAuthenticated, getAccessTokenSilently, data, isLoading]);

  const checkOperatorPermission = (permission: Permission, allowedOperator: Operator): boolean =>
    permissions.includes(`${permission}:${allowedOperator.toLowerCase()}`);

  const checkPermission = (permission: Permission): boolean => permissions.some((p) => p.startsWith(permission));

  const hasMultipleOperators = (): boolean => ops.length > 1;

  const getRoleOptions = (): RoleSelectType[] => {
    const roles: RoleSelectType[] = [];
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_ADMIN_INDICIA),
      value: Role.AdministratorIndicia,
      label: t('generic.roles.AdministratorIndicia'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_ADMIN_MONET),
      value: Role.AdministratorMonet,
      label: t('generic.roles.AdministratorMonet'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_OPERATOR_OWNER_KPN),
      value: Role.OperatorOwnerKPN,
      label: t('generic.roles.OperatorOwnerKPN'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_OPERATOR_OWNER_VF),
      value: Role.OperatorOwnerVF,
      label: t('generic.roles.OperatorOwnerVF'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_OPERATOR_OWNER_OD),
      value: Role.OperatorOwnerOD,
      label: t('generic.roles.OperatorOwnerOD'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_OPERATOR_KPN),
      value: Role.OperatorKPN,
      label: t('generic.roles.OperatorKPN'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_OPERATOR_VF),
      value: Role.OperatorVF,
      label: t('generic.roles.OperatorVF'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_OPERATOR_OD),
      value: Role.OperatorOD,
      label: t('generic.roles.OperatorOD'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_VALIDATOR_VF),
      value: Role.ValidatorVF,
      label: t('generic.roles.ValidatorVF'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_VALIDATOR_OD),
      value: Role.ValidatorOD,
      label: t('generic.roles.ValidatorOD'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_INSPECTOR_VF),
      value: Role.InspectorVF,
      label: t('generic.roles.InspectorVF'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_INSPECTOR_OD),
      value: Role.InspectorOD,
      label: t('generic.roles.InspectorOD'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_COMPANY_MANAGER_KPN),
      value: Role.CompanyManagerKPN,
      label: t('generic.roles.CompanyManagerKPN'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_COMPANY_MANAGER_VF),
      value: Role.CompanyManagerVF,
      label: t('generic.roles.CompanyManagerVF'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_COMPANY_MANAGER_OD),
      value: Role.CompanyManagerOD,
      label: t('generic.roles.CompanyManagerOD'),
    });
    roles.push({
      enabled: checkPermission(Permission.ROLE_ASSIGN_VIEWER),
      value: Role.Viewer,
      label: t('generic.roles.Viewer'),
    });

    return roles;
  };

  return {
    ops,
    permissions,
    checkPermission,
    checkOperatorPermission,
    hasMultipleOperators,
    loggedInUser: user,
    getRoleOptions,
  };
};

export default useMonetUser;
