import classNames from 'classnames';
import React, {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import DatePicker from 'react-datepicker';
import Subtitle from '../Subtitle/Subtitle';
import './InfoBlock.scss';
import Input from '../Input/Input';
import {formatDate} from '../../../Utils/Luxon/dateFormat';

type InfoBlockItem = {
  label: string;
  onValueChange?: (value: string | number | Date | null) => void;
  value?: string | number | Date;
  node?: ReactNode;
  editable?: boolean;
  dataType?: 'date' | 'node' | 'number' | 'text' | 'plain';
  visible?: boolean;
  valid?: boolean;
  validationMessage?: string;
  dirty?: boolean;
};

type PropType = {
  className?: string;
  editable: boolean;
  items: InfoBlockItem[];
  title?: string;
  version?: string;
  visible?: boolean;
};

const InfoBlock = (props: PropType) => {
  const { className, editable, items, title, version, visible } = props;
  const { i18n, t } = useTranslation();

  const renderInput = (item: InfoBlockItem) => (
    <Input
      onChange={(e) => item.onValueChange!(e)}
      className={classNames('info-block__input', { 'input-invalid': !item.valid && item.dirty })}
      type={item.dataType as 'number' | 'text' | undefined}
      value={item.value as string | number | undefined}
    />
  );

  const renderDateInput = (item: InfoBlockItem) => (
    <DatePicker
      selected={new Date(item.value as string)}
      onChange={(date) => item.onValueChange!(date)}
      locale={i18n.language}
      placeholderText={t('generic.date')}
      dateFormat="PP"
    />
  );

  const renderPlainItem = (item: InfoBlockItem) => {
    const { value} = item;

    return (
        <p className="info-block__plain">{value}</p>
    );
  };

  return (
    <div className={classNames(className, 'info-block', { 'info-block--invisible': !visible })}>
      {title && version && (
        <Subtitle className="info-block__title">
          {t(title)} {t(version)}
        </Subtitle>
      )}
      {items && (
        <div className="info-block__items">
          {items
            .filter((item) => item.visible === undefined || item.visible)
            .map((item) => {
              const isItemEditable = editable && (item.editable === undefined || item.editable);

              return (
                <div
                  key={item.label}
                  className="info-block__item"
                >
                  <div className="info-block__label">{t(item.label)}</div>
                  <div className="info-block__value">
                    {!isItemEditable && item.dataType !== 'date' && item.value}
                    {!isItemEditable && item.dataType === 'date' && formatDate(item.value as string | undefined)}
                    {item.dataType === 'node' && item.node}
                    {isItemEditable && (item.dataType === 'number' || item.dataType === 'text') && renderInput(item)}
                    {isItemEditable && item.dataType === 'date' && renderDateInput(item)}
                    {isItemEditable && item.dataType === 'plain' && renderPlainItem(item)}

                    {!item.valid && item.dirty && (
                      <span className="info-block__validation-message">{item.validationMessage}</span>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

InfoBlock.defaultProps = {
  className: '',
  title: '',
  version: '',
  visible: true,
};

export default InfoBlock;
