import React, { type ReactNode, useState, useEffect } from 'react';
import SlideDown from 'react-slidedown/lib/slidedown';
import 'react-slidedown/lib/slidedown.css';
import classNames from 'classnames';
import { Icon, Theme } from '../../Enums';
import IconButton from '../IconButton/IconButton';

import './Accordion.scss';

type PropType = {
  title: ReactNode;
  children: ReactNode;
  className?: string;
  prefix?: ReactNode;
  background?: boolean;
  activeState?: boolean;
};

const Accordion = (props: PropType) => {
  const { title, prefix, children, className, background, activeState } = props;

  const [active, setActive] = useState(activeState);

  useEffect(() => {
    setActive(activeState);
  }, [activeState]);

  const handleAccordionToggle = () => {
    setActive(!active);
  };

  return (
    <div
      className={classNames(
        className,
        'accordion',
        { 'accordion--active': active },
        { 'accordion--has-background': background },
      )}
    >
      <div
        className="accordion__header"
        role="button"
        onClick={handleAccordionToggle}
        onKeyDown={handleAccordionToggle}
        tabIndex={0}
      >
        <div className="accordion__title-wrapper">
          <span className="accordion__prefix">{prefix}</span>
          <span className="accordion__title">{title}</span>
        </div>
        <IconButton
          icon={Icon.chevronDown}
          label="Toggle"
          className="accordion__toggle"
          theme={Theme.transparent}
          onClick={handleAccordionToggle}
          size="16px"
        />
      </div>
      <SlideDown className="accordion__slide-down">
        {active && <div className="accordion__content">{children}</div>}
      </SlideDown>
    </div>
  );
};

Accordion.defaultProps = {
  className: '',
  prefix: null,
  background: true,
  activeState: false,
};

export default Accordion;
