import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import './MonetModal.scss';

import Button from '../Button/Button';
import { Icon, Theme } from '../../Enums';
import IconButton from '../IconButton/IconButton';

type PropType = {
  children: ReactNode;
  close: () => void;
  confirm: () => void;
  title: string;
  onCloseLabel?: string;
  onConfirmLabel?: string;
  showClose?: boolean;
  showConfirm?: boolean;
  isErrorModal?: boolean;
  className?: string;
};

const MonetModal = (props: PropType) => {
  const {
    children,
    className,
    close,
    confirm,
    onCloseLabel,
    onConfirmLabel,
    title,
    showClose,
    showConfirm,
    isErrorModal,
  } = props;
  const { t } = useTranslation();

  React.useEffect(() => {
    const closeFn = (e: any) => {
      if (e.key === 'Escape') {
        close();
      }
    };
    window.addEventListener('keydown', closeFn);
    return () => window.removeEventListener('keydown', closeFn);
  }, []);

  return (
    <div
      className={classNames('monet-modal', className, {
        'monet-modal--error': isErrorModal,
      })}
    >
      <div className="monet-modal__wrapper">
        <div className="monet-modal__header">
          <div className="monet-modal__chapter">{title}</div>
          <IconButton
            className="question-modal__close"
            label={onCloseLabel || t('generic.close')}
            icon={Icon.close}
            theme={Theme.transparent}
            onClick={close}
          />
        </div>
        <div className="monet-modal__content">{children}</div>
        <div
          className={classNames('monet-modal__footer', {
            'monet-modal__footer--single-button': !showClose || !showConfirm,
          })}
        >
          {showClose && (
            <Button
              className="question-modal__save question-modal__action question-modal__button"
              label={onCloseLabel || t('generic.close')}
              theme={Theme.dark}
              onClick={close}
            />
          )}
          {showConfirm && (
            <Button
              className="question-modal__save question-modal__action question-modal__button"
              label={onConfirmLabel || t('generic.save')}
              theme={Theme.primary}
              onClick={() => confirm()}
            />
          )}
        </div>
      </div>
      <div className="monet-modal__overlay" />
    </div>
  );
};

MonetModal.defaultProps = {
  onCloseLabel: undefined,
  onConfirmLabel: undefined,
  showClose: true,
  showConfirm: true,
  isErrorModal: false,
  className: '',
};

export default MonetModal;
