const getDateFromString = (dateStr: string | undefined): Date | undefined => {
  if (!dateStr) {
    return undefined;
  }

  return new Date(dateStr);
};

const getStringFromDate = (date: Date | undefined): string | undefined => {
  if (!date) {
    return undefined;
  }

  if (date.getTimezoneOffset() === 0) {
    return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date
      .getUTCDate()
      .toString()
      .padStart(2, '0')}T00:00:00.000Z`;
  }

  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}T00:00:00.000Z`;
};

export { getDateFromString, getStringFromDate };
