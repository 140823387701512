import React from 'react';
import classNames from 'classnames';

import { Icon } from '../../../Enums';
import IconRender from '../../Icon/IconRender';

import './PagerButton.scss';

type PropType = {
  active: boolean;
  icon: Icon;
  label: string;
  onClick: () => void;
};

const PagerButton = ({ active, icon, label, onClick }: PropType) => (
  <button
    className={classNames('pager-button', { 'pager-button--active': active })}
    onClick={onClick}
    type="button"
    title={label}
  >
    <IconRender
      width="10px"
      height="16px"
      icon={icon}
    />
  </button>
);

export default PagerButton;
