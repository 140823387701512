import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Input from '../Input/Input';
import Button from '../Button/Button';
import Icon from '../../Enums/Icon';
import Theme from '../../Enums/Theme';

import './SearchBar.scss';
import '../Select/Select.scss';
import Title from '../Title/Title';
import { OperatorSelectType } from '../../Types/OperatorSelectType';
import { Status } from '../../Enums';
import { StatusSelectType } from '../../Types/StatusSelectType';
import InputWithIcon from '../InputWithIcon/InputWithIcon';
import { useFilter } from '../../Providers/FilterContextProvider';
import RieFilterType from '../../Types/RieFilterType';
import RieFiltersType from '../../Types/RieFiltersType';

type PropType = {
  showMultipleOperators?: boolean;
  showSimpleHeader?: boolean;
  showStatusFilter?: boolean;
  excludedStatuses?: Status[];
  filterKey: keyof RieFiltersType<any>;
};

const SearchBar = (props: PropType) => {
  const { showMultipleOperators, showSimpleHeader, showStatusFilter, excludedStatuses, filterKey } = props;
  const { t } = useTranslation();

  const { filters, onFilterChanged } = useFilter();
  const filter = filters[filterKey];
  const [currentFilter, setCurrentFilter] = useState<RieFilterType>(filter);

  const statusOptions: StatusSelectType[] = [
    { value: '', label: t(`locations.searchBar.chooseStatus`) },
    { value: Status.Active, label: t(`rie.details.info.status.Active`) },
    { value: Status.Archived, label: t(`rie.details.info.status.Archived`) },
    { value: Status.PendingApproval, label: t(`rie.details.info.status.PendingApproval`) },
    { value: Status.PendingDeletion, label: t(`rie.details.info.status.PendingDeletion`) },
    { value: Status.Rejected, label: t(`rie.details.info.status.Rejected`) },
  ].filter((st) => st.value === '' || !excludedStatuses!.includes(st.value as Status));

  const operatorOptions: OperatorSelectType[] = [
    ...[{ value: '', label: t('locations.searchBar.chooseOperator') }],
    { value: 'KPN', label: t(`generic.operators.kpn`) },
    { value: 'VF', label: t(`generic.operators.vf`) },
    { value: 'OD', label: t(`generic.operators.od`) },
  ];

  const closeSearchOverlay = () => {
    const searchOverlay = document.querySelector('.locations__search-bar.locations__active');
    if (searchOverlay) {
      searchOverlay.classList.remove('locations__active');
    }
  };

  return (
    <div className="search-bar">
      {!showSimpleHeader && (
        <div className="search-bar__info">
          <Title>{t('generic.safetySiteDatabase')}</Title>
          <p>{t('locations.searchBar.explanation')}</p>
        </div>
      )}
      <div className="search-bar__search-section">
        <div className="search-bar__filters">
          {showMultipleOperators && (
            <InputWithIcon
              icon={Icon.operator}
              size="28px"
            >
              <Select
                placeholder={t('locations.searchBar.chooseOperator')}
                classNamePrefix="react-select"
                value={currentFilter.operator}
                onChange={(v) => {
                  if (v) {
                    setCurrentFilter({ ...currentFilter, operator: v });
                  }
                }}
                options={operatorOptions}
              />
            </InputWithIcon>
          )}
          <Input
            icon={Icon.siteLocation}
            placeholder={t('rie.details.info.site')}
            value={currentFilter.siteNumber}
            type="text"
            trim
            onSubmit={() => onFilterChanged(currentFilter, filterKey)}
            onChange={(e: string | number) => setCurrentFilter({ ...currentFilter, siteNumber: e as string })}
          />
          <Input
            icon={Icon.marker}
            placeholder={t('location.city')}
            value={currentFilter.city}
            type="text"
            trim
            onSubmit={() => onFilterChanged(currentFilter, filterKey)}
            onChange={(e: string | number) => setCurrentFilter({ ...currentFilter, city: e as string })}
          />
          <Input
            icon={Icon.street}
            placeholder={t('location.address')}
            value={currentFilter.address}
            type="text"
            trim
            onSubmit={() => onFilterChanged(currentFilter, filterKey)}
            onChange={(e: string | number) => setCurrentFilter({ ...currentFilter, address: e as string })}
          />
          {showStatusFilter && (
            <Select
              placeholder={t('locations.searchBar.chooseStatus')}
              classNamePrefix="react-select"
              value={currentFilter.status}
              onChange={(v) => {
                if (v) {
                  setCurrentFilter({ ...currentFilter, status: v });
                }
              }}
              options={statusOptions}
            />
          )}
        </div>
        <div className="search-bar__submit">
          <Button
            label={t('generic.search')}
            icon={Icon.chevronRight}
            theme={Theme.primary}
            onClick={() => {
              onFilterChanged(currentFilter, filterKey);
              closeSearchOverlay();
            }}
          />
        </div>
      </div>
    </div>
  );
};

SearchBar.defaultProps = {
  showMultipleOperators: true,
  showSimpleHeader: false,
  showStatusFilter: false,
  excludedStatuses: [],
};

export default SearchBar;
