import buildQuery from 'odata-query';

import http from '../../../Utils/Axios/http';
import getODataQueryOptions, {QueryOptions} from "../../../Shared/Types/QueryOptions";

const base = '/Audit/ImportLogEntries';

const getImportLogs = async (queryOptions: QueryOptions) => {
  let query = buildQuery(getODataQueryOptions(queryOptions));
  query = query.replaceAll('T00:00:00.000Z', ''); // Jumping through hoops for odata, DO NOT REMOVE

  const { data } = await http.get(`${base}${query}`);
  return {
    value: data.value,
    count: data['@odata.count'],
  };
};

export default getImportLogs;
