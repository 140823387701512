import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import Button from '../Button/Button';
import IconButton from '../IconButton/IconButton';

import './Tab.scss';
import { Icon, Theme } from '../../Enums';

export type PropType = {
  icon?: Icon;
  title?: string;
  url?: string;
  className?: string;
  active?: boolean;
  onClick?: () => void;
};

const Tab = (props: PropType) => {
  const { icon, title, url, className, active, onClick } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (url) {
      navigate(url, { replace: true });
    }
  };

  const renderButton = () => {
    let component = null;
    if (title) {
      component = (
        <Button
          className="tab__button"
          label={title}
          onClick={handleClick}
          theme={Theme.transparent}
        />
      );
    } else if (icon) {
      component = (
        <IconButton
          className="tab__button"
          label="Tab button"
          icon={icon}
          onClick={handleClick}
          theme={Theme.transparentInverted}
        />
      );
    }
    return component;
  };

  return <div className={classNames(className, 'tab', { 'tab--active': active })}>{renderButton()}</div>;
};

Tab.defaultProps = {
  icon: null,
  title: '',
  url: '',
  className: '',
  active: false,
  onClick: () => {},
};

export default Tab;
