import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { ColumnDef } from '@tanstack/react-table';
import { UseQueryResult } from 'react-query';

import { InputWithIcon, Unauthorized } from '../../../Shared/Components';
import Input from '../../../Shared/Components/Input/Input';
import Icon from '../../../Shared/Enums/Icon';
import Button from '../../../Shared/Components/Button/Button';
import Theme from '../../../Shared/Enums/Theme';
import FilterObjectType from '../../../Shared/Types/FilterObjectType';
import useImportLogs from '../Hooks/useImportLogs';
import ImportLogSeverity from '../../../Shared/Enums/ImportLogSeverity';

import { SeveritySelectType } from '../../../Shared/Types/SeveritySelectType';

import './ImportLogs.scss';
import { ImportLogEntry } from '../../../Shared/Models';
import { Operator, Permission } from '../../../Shared/Enums';
import OperatorCell from '../../../Shared/Components/Table/Cells/OperatorCell';
import DateTimeCell from '../../../Shared/Components/Table/Cells/DateTimeCell';
import DataTable from '../../../Shared/Components/Table/DataTable';
import { TableQueryOptions } from '../../../Shared/Types/QueryOptions';
import { ODataResult } from '../../Rie/Types/ODataResult';
import { OperatorSelectType } from '../../../Shared/Types/OperatorSelectType';
import SiteRieCell from '../../../Shared/Components/Table/Cells/SiteRieCell';
import useMonetUser from '../../../Hooks/useMonetUser';
import SeverityCell from '../../../Shared/Components/Table/Cells/SeverityCell';

const ImportLogs = () => {
  const { i18n, t } = useTranslation();
  const { ops, hasMultipleOperators, checkPermission } = useMonetUser();
  const tableRef = useRef();

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [siteNumber, setSiteNumber] = useState<string>('');
  const [severity, setSeverity] = useState<SeveritySelectType>({
    value: '',
    label: t('generic.selectStatus'),
  });
  const [filter, setFilter] = useState<FilterObjectType>({});

  const [operator, setOperator] = useState<OperatorSelectType>({
    value: '',
    label: t('locations.searchBar.chooseOperator'),
  });
  const operatorOptions: OperatorSelectType[] = [
    ...[{ value: '', label: t('locations.searchBar.chooseOperator') }],
    ...ops.map((op) => ({ value: op, label: t(`generic.operators.${op.toLowerCase()}`) })),
  ].filter((op) => op.value !== Operator.ODIDO); // since Odido doesn't do imports

  const severityStatusOptions: SeveritySelectType[] = [
    { value: '', label: t('generic.selectStatus') },
    { value: ImportLogSeverity.INFO, label: t(`generic.severity.${ImportLogSeverity.INFO.toLowerCase()}`) },
    { value: ImportLogSeverity.WARNING, label: t(`generic.severity.${ImportLogSeverity.WARNING.toLowerCase()}`) },
    { value: ImportLogSeverity.ERROR, label: t(`generic.severity.${ImportLogSeverity.ERROR.toLowerCase()}`) },
  ];

  const columns = useMemo<ColumnDef<ImportLogEntry>[]>(
    () => [
      {
        header: t('importLogs.date'),
        accessorKey: 'DateTime',
        cell: DateTimeCell,
      },
      {
        header: t('importLogs.executedAt'),
        id: 'ExecutedAt',
        accessorFn: (row) => row,
        cell: SiteRieCell,
        enableSorting: false,
      },
      {
        header: t('importLogs.operator'),
        accessorKey: 'Operator',
        cell: OperatorCell,
        hide: !hasMultipleOperators(),
        enableSorting: false,
      },
      {
        header: t('importLogs.description'),
        accessorKey: 'Message',
      },
      {
        header: t('importLogs.status'),
        accessorKey: 'Severity',
        cell: SeverityCell,
        enableSorting: false,
        width: '100px',
      },
    ],
    [i18n.language],
  );

  if (!checkPermission(Permission.READ_IMPORT_LOGS)) {
    return <Unauthorized pageTitle={t('generic.importLogs')} />;
  }

  const handleSearch = () => {
    const filterObj: FilterObjectType = {
      and: [],
    };

    if (filterObj && filterObj.and) {
      if (siteNumber) {
        filterObj.and.push({ ExecutedAt: { contains: siteNumber } });
      }
      if (severity.value) {
        filterObj.and.push({ Severity: severity.value });
      }

      if (startDate) {
        // Parsing to correct format, and creating a new Date object. Jumping through hoops for odata DO NOT REMOVE
        const parsedStartDate = DateTime.fromISO(startDate.toISOString()).toFormat('yyyy-MM-dd');
        filterObj.and.push({ DateTime: { ge: new Date(parsedStartDate) } });
      }

      if (endDate) {
        // Parsing to correct format, and creating a new Date object. Jumping through hoops for odata DO NOT REMOVE
        const parsedEndDate = DateTime.fromISO(endDate.toISOString()).toFormat('yyyy-MM-dd');
        filterObj.and.push({ DateTime: { le: new Date(parsedEndDate) } });
      }

      if (operator.value) {
        filterObj.and.push({ Operator: operator.value });
      }

      setFilter(filterObj);
      // @ts-ignore
      tableRef.current?.resetPagination();
    }
  };

  const handleDataCall = (queryOptions: TableQueryOptions): UseQueryResult<ODataResult, Error> =>
    useImportLogs({
      pageIndex: queryOptions.pageIndex,
      pageSize: queryOptions.pageSize,
      sorting: queryOptions.sorting,
      filter,
    });

  return (
    <div className="import-logs page">
      <div className="page-header">
        <div className="page-header__title">
          <h1 className="title">{t('generic.importLogs')}</h1>
        </div>
      </div>
      <div className="search-bar search-bar--margin-bottom">
        <div className="search-bar__search-section">
          <div className="search-bar__filters">
            <InputWithIcon
              icon={Icon.calendar}
              size="28px"
            >
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date as Date)}
                locale={i18n.language}
                placeholderText={t('generic.dateStart')}
                dateFormat="PP"
              />
            </InputWithIcon>
            <InputWithIcon
              icon={Icon.calendar}
              size="28px"
            >
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date as Date)}
                locale={i18n.language}
                placeholderText={t('generic.dateEnd')}
                dateFormat="PP"
              />
            </InputWithIcon>
            <Input
              placeholder={t('rie.details.info.site')}
              value={siteNumber}
              type="text"
              icon={Icon.siteLocation}
              onChange={(e: string | number) => setSiteNumber(e as string)}
              onSubmit={() => handleSearch()}
            />
            {hasMultipleOperators() && (
              <InputWithIcon
                icon={Icon.operator}
                size="28px"
              >
                <Select
                  placeholder={t('locations.searchBar.chooseOperator')}
                  classNamePrefix="react-select"
                  defaultValue={operator}
                  onChange={(v) => {
                    if (v) {
                      setOperator(v);
                    }
                  }}
                  options={operatorOptions}
                />
              </InputWithIcon>
            )}
            <Select
              classNamePrefix="react-select"
              placeholder={t('generic.selectStatus')}
              onChange={(newValue): void => {
                setSeverity(newValue!);
              }}
              options={severityStatusOptions}
            />
          </div>
          <div className="search-bar__submit">
            <Button
              label={t('generic.search')}
              icon={Icon.chevronRight}
              theme={Theme.primary}
              onClick={() => handleSearch()}
            />
          </div>
        </div>
      </div>

      <DataTable
        ref={tableRef}
        columns={columns}
        dataCall={handleDataCall}
        keyAttribute="Id"
        initialSortingState={[{ id: 'DateTime', desc: true }]}
      />
    </div>
  );
};

export default ImportLogs;
