import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { formatDate } from '../../../Utils/Luxon/dateFormat';

import StatusSymbol from '../StatusSymbol/StatusSymbol';
import Button from '../Button/Button';
import Rie from '../../Models/Rie';
import Size from '../../Enums/Size';
import Icon from '../../Enums/Icon';
import Theme from '../../Enums/Theme';
import { getOperator } from '../../Enums/Operator';
import Title from '../Title/Title';

type PinInfoScreenPropType = {
  deactivate: () => void;
  rie: Rie;
  lat: number;
  lng: number;
};

const PinInfoScreen = (props: PinInfoScreenPropType) => {
  const { lat, lng, deactivate, rie } = props;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return (
    <div
      className="marker-details"
      data-lat={lat}
      data-lng={lng}
    >
      <div className="marker-details__header">
        <Title>{t('map.details.title')}</Title>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span
          className="marker-details__close"
          onClick={deactivate}
        >
          X
        </span>
      </div>
      <div className="marker-details__row">
        <span>{t('map.details.site')}</span>
        <span>{rie.SiteNumber}</span>
      </div>
      <div className="marker-details__row">
        <span>{t('map.details.operator')}</span>
        <span>{getOperator(rie.Operator)}</span>
      </div>
      <div className="marker-details__row">
        <span>{t('map.details.safetyStatus')}</span>
        <span>
          <StatusSymbol
            status={rie.SafetyStatus}
            size={Size.small}
          />
        </span>
      </div>
      <div className="marker-details__row">
        <span>{t('map.details.location')}</span>
        <span>{rie.City}</span>
      </div>
      <div className="marker-details__row">
        <span>{t('map.details.address')}</span>
        <span>{rie.Address}</span>
      </div>
      <div className="marker-details__row">
        <span>{t('map.details.inventory')}</span>
        <span>{formatDate(rie.InspectionDate as string)}</span>
      </div>
      <div className="marker-details__row marker-details__row--actions">
        <Button
          label={t('map.details.viewSite')}
          icon={Icon.chevronRight}
          theme={Theme.transparent}
          onClick={() => {
            queryClient.invalidateQueries(['ries', 'rie', rie.Id]).then(() => {
              navigate(`/locations/${rie.Id}`);
            });
          }}
        />
      </div>
    </div>
  );
};

PinInfoScreen.defaultProps = {};

export default PinInfoScreen;
