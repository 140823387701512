import React from 'react';
import { formatDate } from '../../../../Utils/Luxon/dateFormat';
import TextCell from "./TextCell";

const DateCell = ({ getValue }: any) => {
  const renderValue = formatDate(getValue()) as string;
  return (<TextCell renderValue={renderValue} />);
};

export default DateCell;
