import { DateTime, Settings } from 'luxon';

Settings.defaultLocale = 'nl';

const formatDate = (date: string | undefined): string | undefined => {
  if(!date) {
    return undefined;
  }

  const dateTime = DateTime.fromISO(date);
  if(dateTime.year <= 1) {
    return undefined;
  }

  return dateTime.toLocaleString(DateTime.DATE_MED);
}
const formatDateTime = (date: string): string => DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);

export { formatDate, formatDateTime };
