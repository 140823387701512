import { Rie } from '../../../Shared/Models';
import { Assessment, Operator, SafetyStatus, Status } from '../../../Shared/Enums';

const setSiteNumberStart = (operator: Operator): string => {
  if (operator === Operator.VF) {
    return 'S';
  }

  if (operator === Operator.ODIDO) {
    return 'L';
  }

  return '';
};

const newRie = (operator: Operator | null): Rie => ({
  Address: '',
  Answers: [
    {
      QuestionId: '1.1',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '1.3',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '1.4',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '1.5',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '2.1',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '2.2',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '2.3',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '2.4',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '2.5',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '2.6',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '2.7',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '2.8',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '3.1',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '3.2',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '3.4',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '3.6',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '3.9',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '3.10',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '4.3',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '4.4',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '4.5',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '4.8',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '4.9',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '4.10',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '4.11',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '4.12',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '5.1',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '5.2',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '5.3',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '5.4',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '5.5',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '5.6',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '5.7',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '5.9',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '5.10',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '5.11',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '6.1',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '6.2',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '6.3',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '7.1',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '7.2',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '7.3',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '7.4',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '7.5',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '7.6',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '7.7',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '8.2',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '8.3',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '8.4',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '8.5',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '8.6',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '8.7',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '9.1',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '9.3',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '9.6',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '9.7',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '9.8',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '9.9',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '9.10',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '9.11',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '9.13',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '9.14',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '10.1',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '10.2',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
    {
      QuestionId: '10.3',
      Assessment: Assessment.NotApplicable,
      Description: '',
      Attachments: [],
    },
  ],
  AnswersSafety: [
    { ChapterId: '1', QuestionId: '1.1', Safety: SafetyStatus.Grey },
    { ChapterId: '1', QuestionId: '1.3', Safety: SafetyStatus.Grey },
    { ChapterId: '1', QuestionId: '1.4', Safety: SafetyStatus.Grey },
    { ChapterId: '1', QuestionId: '1.5', Safety: SafetyStatus.Grey },
    { ChapterId: '2', QuestionId: '2.1', Safety: SafetyStatus.Grey },
    { ChapterId: '2', QuestionId: '2.2', Safety: SafetyStatus.Grey },
    { ChapterId: '2', QuestionId: '2.3', Safety: SafetyStatus.Grey },
    { ChapterId: '2', QuestionId: '2.4', Safety: SafetyStatus.Grey },
    { ChapterId: '2', QuestionId: '2.5', Safety: SafetyStatus.Grey },
    { ChapterId: '2', QuestionId: '2.6', Safety: SafetyStatus.Grey },
    { ChapterId: '2', QuestionId: '2.7', Safety: SafetyStatus.Grey },
    { ChapterId: '2', QuestionId: '2.8', Safety: SafetyStatus.Grey },
    { ChapterId: '3', QuestionId: '3.1', Safety: SafetyStatus.Grey },
    { ChapterId: '3', QuestionId: '3.2', Safety: SafetyStatus.Grey },
    { ChapterId: '3', QuestionId: '3.4', Safety: SafetyStatus.Grey },
    { ChapterId: '3', QuestionId: '3.6', Safety: SafetyStatus.Grey },
    { ChapterId: '3', QuestionId: '3.9', Safety: SafetyStatus.Grey },
    { ChapterId: '3', QuestionId: '3.10', Safety: SafetyStatus.Grey },
    { ChapterId: '4', QuestionId: '4.3', Safety: SafetyStatus.Grey },
    { ChapterId: '4', QuestionId: '4.4', Safety: SafetyStatus.Grey },
    { ChapterId: '4', QuestionId: '4.5', Safety: SafetyStatus.Grey },
    { ChapterId: '4', QuestionId: '4.8', Safety: SafetyStatus.Grey },
    { ChapterId: '4', QuestionId: '4.9', Safety: SafetyStatus.Grey },
    { ChapterId: '4', QuestionId: '4.10', Safety: SafetyStatus.Grey },
    { ChapterId: '4', QuestionId: '4.11', Safety: SafetyStatus.Grey },
    { ChapterId: '4', QuestionId: '4.12', Safety: SafetyStatus.Grey },
    { ChapterId: '5', QuestionId: '5.1', Safety: SafetyStatus.Grey },
    { ChapterId: '5', QuestionId: '5.2', Safety: SafetyStatus.Grey },
    { ChapterId: '5', QuestionId: '5.3', Safety: SafetyStatus.Grey },
    { ChapterId: '5', QuestionId: '5.4', Safety: SafetyStatus.Grey },
    { ChapterId: '5', QuestionId: '5.5', Safety: SafetyStatus.Grey },
    { ChapterId: '5', QuestionId: '5.6', Safety: SafetyStatus.Grey },
    { ChapterId: '5', QuestionId: '5.7', Safety: SafetyStatus.Grey },
    { ChapterId: '5', QuestionId: '5.9', Safety: SafetyStatus.Grey },
    { ChapterId: '5', QuestionId: '5.10', Safety: SafetyStatus.Grey },
    { ChapterId: '5', QuestionId: '5.11', Safety: SafetyStatus.Grey },
    { ChapterId: '6', QuestionId: '6.1', Safety: SafetyStatus.Grey },
    { ChapterId: '6', QuestionId: '6.2', Safety: SafetyStatus.Grey },
    { ChapterId: '6', QuestionId: '6.3', Safety: SafetyStatus.Grey },
    { ChapterId: '7', QuestionId: '7.1', Safety: SafetyStatus.Grey },
    { ChapterId: '7', QuestionId: '7.2', Safety: SafetyStatus.Grey },
    { ChapterId: '7', QuestionId: '7.3', Safety: SafetyStatus.Grey },
    { ChapterId: '7', QuestionId: '7.4', Safety: SafetyStatus.Grey },
    { ChapterId: '7', QuestionId: '7.5', Safety: SafetyStatus.Grey },
    { ChapterId: '7', QuestionId: '7.6', Safety: SafetyStatus.Grey },
    { ChapterId: '7', QuestionId: '7.7', Safety: SafetyStatus.Grey },
    { ChapterId: '8', QuestionId: '8.2', Safety: SafetyStatus.Grey },
    { ChapterId: '8', QuestionId: '8.3', Safety: SafetyStatus.Grey },
    { ChapterId: '8', QuestionId: '8.4', Safety: SafetyStatus.Grey },
    { ChapterId: '8', QuestionId: '8.5', Safety: SafetyStatus.Grey },
    { ChapterId: '8', QuestionId: '8.6', Safety: SafetyStatus.Grey },
    { ChapterId: '8', QuestionId: '8.7', Safety: SafetyStatus.Grey },
    { ChapterId: '9', QuestionId: '9.1', Safety: SafetyStatus.Grey },
    { ChapterId: '9', QuestionId: '9.7', Safety: SafetyStatus.Grey },
    { ChapterId: '9', QuestionId: '9.8', Safety: SafetyStatus.Grey },
    { ChapterId: '9', QuestionId: '9.9', Safety: SafetyStatus.Grey },
    { ChapterId: '9', QuestionId: '9.10', Safety: SafetyStatus.Grey },
    { ChapterId: '9', QuestionId: '9.11', Safety: SafetyStatus.Grey },
    { ChapterId: '9', QuestionId: '9.13', Safety: SafetyStatus.Grey },
    { ChapterId: '9', QuestionId: '9.14', Safety: SafetyStatus.Grey },
    { ChapterId: '10', QuestionId: '10.1', Safety: SafetyStatus.Grey },
    { ChapterId: '10', QuestionId: '10.2', Safety: SafetyStatus.Grey },
    { ChapterId: '10', QuestionId: '10.3', Safety: SafetyStatus.Grey },
  ],
  AssessorAuth0Id: '',
  City: '',
  HasPendingRie: false,
  InspectionDate: new Date().toISOString(),
  InspectorName: '',
  InspectorParty: '',
  Operator: operator ?? Operator.ODIDO,
  PostalCode: '',
  RejectionRemarks: '',
  RequesterAuth0Id: '',
  SafetyStatus: SafetyStatus.Green,
  SiteNumber: setSiteNumberStart(operator ?? Operator.ODIDO),
  Status: Status.Active,
  Version: '2.8',
  Id: 'new',
  Modified: new Date().toISOString(),
  X: '',
  Y: '',
});

export default newRie;
