import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';

import ProtectedRoute from './Features/Login/Auth0/ProtectedRoute';
import Locations from './Features/Locations/Pages/Locations';
import Quarantine from './Features/Rie/Pages/Quarantine';
import Archive from './Features/Rie/Pages/Archive';
import Companies from './Features/Companies/Pages/Companies';
import Users from './Features/Users/Pages/Users';
import AuditLogs from './Features/Audit/Pages/AuditLogs';
import ImportLogs from './Features/ImportLogs/Pages/ImportLogs';
import MySubmittedRies from './Features/Rie/Pages/MySubmittedRies';
import RieDetailPdf from './Features/Rie/Pages/RieDetailPdf';
import RieDetail from './Features/Rie/Pages/RieDetail';
import { Loader } from './Shared/Components';

import './App.scss';

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className="centralize-loader ">
        <Loader />
      </div>
    );
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
        refetchOnMount: true,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient} >
      <div className="app">
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute
                showHeader
                component={Locations}
              />
            }
          />
          <Route
            path="/locations"
            element={
              <ProtectedRoute
                showHeader
                component={Locations}
              />
            }
          />
          <Route
            path="/locations/:id"
            element={
              <ProtectedRoute
                showHeader
                component={RieDetail}
              />
            }
          />
          <Route
            path="/locations/:id/pdf"
            element={<RieDetailPdf />}
          />
          <Route
            path="/location/new"
            element={
              <ProtectedRoute
                showHeader
                component={RieDetail}
              />
            }
          />
          <Route
            path="/my-submitted-ries"
            element={
              <ProtectedRoute
                showHeader
                component={MySubmittedRies}
              />
            }
          />
          <Route
            path="/quarantine"
            element={
              <ProtectedRoute
                showHeader
                component={Quarantine}
              />
            }
          />
          <Route
            path="/archive"
            element={
              <ProtectedRoute
                showHeader
                component={Archive}
              />
            }
          />
          <Route
            path="/companies"
            element={
              <ProtectedRoute
                showHeader
                component={Companies}
              />
            }
          />
          <Route
            path="/companies/:id/:companyName/users"
            element={
              <ProtectedRoute
                showHeader
                component={Users}
              />
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute
                showHeader
                component={Users}
              />
            }
          />
          <Route
            path="/audit-logs"
            element={
              <ProtectedRoute
                showHeader
                component={AuditLogs}
              />
            }
          />
          <Route
            path="/import-logs"
            element={
              <ProtectedRoute
                showHeader
                component={ImportLogs}
              />
            }
          />
        </Routes>
      </div>
    </QueryClientProvider>
  );
};

export default App;
