import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, UseQueryResult } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';

import { Rie } from '../../../Shared/Models';
import { ODataResult } from '../Types/ODataResult';
import OperatorCell from '../../../Shared/Components/Table/Cells/OperatorCell';
import SafetyStatusCell from '../Cells/SafetyStatusCell';
import DateCell from '../../../Shared/Components/Table/Cells/DateCell';
import DataTable from '../../../Shared/Components/Table/DataTable';
import { TableQueryOptions } from '../../../Shared/Types/QueryOptions';
import TextCell from '../../../Shared/Components/Table/Cells/TextCell';
import useMonetUser from '../../../Hooks/useMonetUser';

import './RieDataTable.scss';

type RieDataTableType = {
  dataCall: (queryOptions: TableQueryOptions) => UseQueryResult<ODataResult, Error>;
  hideStatus?: boolean;
};

// Operator(s) constrained table with RI&E's (for Quarantine and Archive pages)
const RieDataTable = forwardRef(({ dataCall, hideStatus }: RieDataTableType, ref) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { hasMultipleOperators } = useMonetUser();
  const tableRef = useRef();

  const showMultipleOperators = hasMultipleOperators();

  useImperativeHandle(ref, () => ({
    resetPagination() {
      // @ts-ignore
      tableRef.current?.resetPagination();
    },
  }));

  const formatStatus = (value: any) => {
    const renderValue = t(`rie.details.info.status.${value}`);
    return <TextCell renderValue={renderValue} />;
  };

  const columns = useMemo<ColumnDef<Rie>[]>(
    () => [
      {
        header: 'Id',
        accessorKey: 'Id',
        hide: true,
      },
      {
        header: t('rie.details.info.site'),
        accessorKey: 'SiteNumber',
        enableSorting: false,
      },
      {
        header: t('rie.details.info.operator'),
        accessorKey: 'Operator',
        cell: OperatorCell,
        hide: !showMultipleOperators,
      },
      {
        header: t('rie.details.address.title'),
        accessorKey: 'Address',
      },
      {
        header: t('rie.details.address.city'),
        accessorKey: 'City',
      },
      {
        header: t('rie.details.info.inventory'),
        accessorKey: 'InspectionDate',
        cell: DateCell,
      },
      {
        header: t('rie.details.info.status.title'),
        accessorKey: 'Status',
        cell: (cell) => formatStatus(cell.getValue()),
        hide: hideStatus,
      },
      {
        header: t('rie.details.info.safetyStatus'),
        accessorKey: 'SafetyStatus',
        cell: SafetyStatusCell,
      },
    ],
    [i18n.language],
  );

  return (
    <div className="rie-data-table">
      <DataTable
        ref={tableRef}
        columns={columns}
        dataCall={dataCall}
        onRowClick={(id: string) => {
          queryClient.invalidateQueries(['ries', 'rie', id]).then(() => {
            navigate(`/locations/${id}`);
          });
        }}
        keyAttribute="Id"
      />
    </div>
  );
});

RieDataTable.defaultProps = {
  hideStatus: false,
};

export default RieDataTable;
