import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Flyout from '../Flyout/Flyout';
import IconRender from '../Icon/IconRender';
import IconButton from '../IconButton/IconButton';
import Loader from '../Loader/Loader';

import { Icon, Operator, Permission, Theme } from '../../Enums';

import './Menu.scss';
import exportRies from '../../../Features/Export/Components/Export';
import useMonetUser from '../../../Hooks/useMonetUser';
import { useErrorModal } from '../../Providers/ErrorContextProvider';
import useUserByEmail from '../../../Features/Users/Hooks/useUserByEmail';

type PropType = {
  active: boolean;
  handleClose: () => void;
};

const Menu = (props: PropType) => {
  const { active, handleClose } = props;
  const { logout, user } = useAuth0();
  const { data, isLoading } = useUserByEmail(user?.email);
  const { t, i18n } = useTranslation();

  const { checkPermission, checkOperatorPermission } = useMonetUser();

  const [showKPNLoader, setShowKPNLoader] = useState<boolean>(false);
  const [showVFLoader, setShowVFLoader] = useState<boolean>(false);
  const [showTMLoader, setShowTMLoader] = useState<boolean>(false);

  const { setError } = useErrorModal();

  if (isLoading) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  return (
    <Flyout
      className="menu"
      active={active}
      handleClose={handleClose}
      footer={
        <div className="menu__footer">
          <IconButton
            icon={Icon.flagNL}
            onClick={() => i18n.changeLanguage('nl')}
            label={t('language.dutch')}
            theme={Theme.transparent}
            size="32px"
          />
          <IconButton
            icon={Icon.flagUK}
            onClick={() => i18n.changeLanguage('en')}
            label={t('language.english')}
            theme={Theme.transparent}
            size="32px"
          />
        </div>
      }
    >
      <Link
        to="/locations"
        onClick={handleClose}
        className="menu__link"
      >
        <IconRender icon={Icon.siteLocation} /> {t('menu.sites')}
      </Link>
      {checkOperatorPermission(Permission.RIE_SUBMIT, Operator.ODIDO) && (
        <Link
          to="/locations/new?operator=OD"
          onClick={handleClose}
          className="menu__link"
        >
          <IconRender icon={Icon.pencil} /> {t('menu.submit.od')}
        </Link>
      )}
      {checkOperatorPermission(Permission.RIE_SUBMIT, Operator.VF) && (
        <Link
          to="/locations/new?operator=VF"
          onClick={handleClose}
          className="menu__link"
        >
          <IconRender icon={Icon.pencil} /> {t('menu.submit.vf')}
        </Link>
      )}
      {checkPermission(Permission.RIE_SUBMIT) && (
        <Link
          to="/my-submitted-ries"
          onClick={handleClose}
          className="menu__link"
        >
          <IconRender icon={Icon.submittedRies} /> {t('menu.mySubmittedRies')}
        </Link>
      )}
      {checkPermission(Permission.COMPANIES_MANAGE_ALL) && (
        <Link
          to="/companies"
          onClick={handleClose}
          className="menu__link menu__link--hide-mobile"
        >
          <IconRender icon={Icon.companies} /> {t('menu.companies')}
        </Link>
      )}
      {checkPermission(Permission.COMPANIES_MANAGE_ALL) && (
        <Link
          to="/users"
          onClick={handleClose}
          className="menu__link menu__link--hide-mobile"
        >
          <IconRender icon={Icon.user} /> {t('menu.users')}
        </Link>
      )}
      {!checkPermission(Permission.COMPANIES_MANAGE_ALL) && checkPermission(Permission.COMPANIES_MANAGE_OWN) && (
        <Link
          to={`/companies/${data?.CompanyId}/${data?.Company?.Name}/users`}
          onClick={handleClose}
          className="menu__link menu__link--hide-mobile"
        >
          <IconRender icon={Icon.user} /> {t('menu.users')}
        </Link>
      )}
      {checkPermission(Permission.RIE_QUARANTINE) && (
        <Link
          to="/quarantine"
          onClick={handleClose}
          className="menu__link"
        >
          <IconRender icon={Icon.quarantine} /> {t('menu.quarantine')}
        </Link>
      )}
      {checkPermission(Permission.RIE_ARCHIVE) && (
        <Link
          to="/archive"
          onClick={handleClose}
          className="menu__link"
        >
          <IconRender icon={Icon.archive} /> {t('menu.archive')}
        </Link>
      )}
      {checkPermission(Permission.READ_IMPORT_LOGS) && (
        <Link
          to="/import-logs"
          onClick={handleClose}
          className="menu__link menu__link--hide-mobile"
        >
          <IconRender icon={Icon.importLog} /> {t('menu.import')}
        </Link>
      )}
      {checkPermission(Permission.READ_AUDIT_LOGS) && (
        <Link
          to="/audit-logs"
          onClick={handleClose}
          className="menu__link menu__link--hide-mobile"
        >
          <IconRender icon={Icon.auditLog} /> {t('menu.audit')}
        </Link>
      )}
      <div className="menu__item menu__item--hide-mobile">
        {showKPNLoader && (
          <div className="menu__item-loader">
            <Loader />
          </div>
        )}
        {checkOperatorPermission(Permission.RIE_EXPORT, Operator.KPN) && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            to="#"
            onClick={() => {
              setShowKPNLoader(true);
              exportRies(
                Operator.KPN,
                () => {
                  setShowKPNLoader(false);
                },
                setError,
              );
            }}
            className={classNames('menu__link', { 'menu__link--disabled': showKPNLoader })}
          >
            <IconRender icon={Icon.export} /> {t('menu.export.kpn')}
          </Link>
        )}
      </div>
      <div className="menu__item menu__item--hide-mobile">
        {showTMLoader && (
          <div className="menu__item-loader">
            <Loader />
          </div>
        )}
        {checkOperatorPermission(Permission.RIE_EXPORT, Operator.ODIDO) && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            to="#"
            onClick={() => {
              setShowTMLoader(true);
              exportRies(
                Operator.ODIDO,
                () => {
                  setShowTMLoader(false);
                },
                setError,
              );
            }}
            className={classNames('menu__link', { 'menu__link--disabled': showTMLoader })}
          >
            <IconRender icon={Icon.export} /> {t('menu.export.tm')}
          </Link>
        )}
      </div>
      <div className="menu__item menu__item--hide-mobile">
        {showVFLoader && (
          <div className="menu__item-loader">
            <Loader />
          </div>
        )}
        {checkOperatorPermission(Permission.RIE_EXPORT, Operator.VF) && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            to="#"
            onClick={() => {
              setShowVFLoader(true);
              exportRies(
                Operator.VF,
                () => {
                  setShowVFLoader(false);
                },
                setError,
              );
            }}
            className={classNames('menu__link', { 'menu__link--disabled': showVFLoader })}
          >
            <IconRender icon={Icon.export} /> {t('menu.export.vf')}
          </Link>
        )}
      </div>
      <a
        href="https://monet.nl/93e11118-6cab-11ed-a1eb-0242ac120002-ssd"
        className="menu__link"
        target="_blank"
        rel="noreferrer"
      >
        <IconRender icon={Icon.cloud} /> {t('menu.downloads')}
      </a>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link
        to="#"
        onClick={() => logout({ returnTo: window.location.origin })}
        className="menu__link"
      >
        <IconRender icon={Icon.exit} /> {t('menu.logout')}
      </Link>
    </Flyout>
  );
};

export default Menu;
