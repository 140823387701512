import React from 'react';
import { useTranslation } from 'react-i18next';

import TextCell from './TextCell';
import './SeverityCell.scss';

const SeverityCell = ({ getValue }: any) => {
  const { t } = useTranslation();
  const renderValue = t(`generic.severity.${getValue().toLowerCase()}`);

  return (
    <div className="severity">
      <TextCell renderValue={renderValue} />
    </div>
  );
};

export default SeverityCell;
