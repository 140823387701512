import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQueryClient, UseQueryResult } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';

import { Button, Unauthorized } from '../../../Shared/Components';
import { User } from '../../../Shared/Models';
import { Icon, Permission, Role, Theme } from '../../../Shared/Enums';

import { useCompany, useCompanyUsers } from '../Hooks/useCompanyUsers';
import MonetDeleteModal from '../../../Shared/Components/MonetModal/MonetDeleteModal';
import { createUser, deleteUser, updateUser } from '../Data/usersService';
import UserModal from '../Components/UserModal';
import FilterObjectType from '../../../Shared/Types/FilterObjectType';
import Input from '../../../Shared/Components/Input/Input';
import DataTable from '../../../Shared/Components/Table/DataTable';
import { ODataResult } from '../../Rie/Types/ODataResult';
import { TableQueryOptions } from '../../../Shared/Types/QueryOptions';
import { OperatorsCell } from '../../../Shared/Components/Table/Cells/OperatorCell';
import useMonetUser from '../../../Hooks/useMonetUser';
import { useErrorModal } from '../../../Shared/Providers/ErrorContextProvider';
import UserCommandCell from '../Components/UserCommandCell';

const Companies = () => {
  const queryClient = useQueryClient();
  const { i18n, t } = useTranslation();
  const { companyName, id } = useParams();
  const { permissions, checkPermission, getRoleOptions } = useMonetUser();
  const tableRef = useRef();
  const { data } = useCompany(id);

  const [userModalActive, setUserModalActive] = useState<boolean>(false);
  const [confirmDeleteModalActive, setConfirmDeleteModalActive] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [filter, setFilter] = useState<FilterObjectType>(id ? { and: [{ CompanyId: `guid${id}guid` }] } : {});

  const { setError } = useErrorModal();

  const showCompany = companyName === undefined;

  // This wrapper is necessary for applying the filter, which is handled outside the DataTable component
  const handleDataCall = (queryOptions: TableQueryOptions): UseQueryResult<ODataResult, Error> =>
    useCompanyUsers({
      pageIndex: queryOptions.pageIndex,
      pageSize: queryOptions.pageSize,
      sorting: queryOptions.sorting,
      filter,
    });

  const roles = useMemo(() => getRoleOptions(), [permissions, data]);

  // eslint-disable-next-line react/no-unstable-nested-components

  const formatRoles = (rs: string[]): string => rs.map((role) => t(`generic.roles.${role}`)).join(', ');

  const columns = useMemo<ColumnDef<User>[]>(
    () => [
      {
        header: t('users.firstName'),
        accessorKey: 'FirstName',
      },
      {
        header: t('users.lastName'),
        accessorKey: 'LastName',
      },
      {
        header: t('users.email'),
        accessorKey: 'EmailAddress',
      },
      {
        header: t('users.company'),
        id: 'Company',
        accessorFn: (row) => row.Company?.Name,
        enableSorting: false,
        hide: !showCompany,
      },
      {
        header: t('users.operators'),
        accessorKey: 'Operators',
        cell: OperatorsCell,
        enableSorting: false,
      },
      {
        header: t('users.roles'),
        accessorKey: 'Roles',
        cell: (cell) => formatRoles(cell.getValue()),
        enableSorting: false,
      },
      {
        id: 'Actions',
        header: '',
        width: 50,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (cell) => (
          <UserCommandCell
            {...cell}
            selectedUser={setSelectedUser}
            activateUserModal={setUserModalActive}
            activateConfirmDeleteModal={setConfirmDeleteModalActive}
          />
        ),
      },
    ],
    [i18n.language, data],
  );

  if (!(checkPermission(Permission.COMPANIES_MANAGE_ALL) || checkPermission(Permission.COMPANIES_MANAGE_OWN))) {
    return <Unauthorized pageTitle={t('users.editUsersTitle', { company: companyName })} />;
  }

  const handleSearch = () => {
    const filterObj: FilterObjectType = {
      and: [],
    };

    if (filterObj && filterObj.and) {
      if (id) {
        filterObj.and.push({ CompanyId: `guid${id}guid` });
      }
      if (firstName) {
        filterObj.and.push({ FirstName: { contains: firstName } });
      }
      if (lastName) {
        filterObj.and.push({ LastName: { contains: lastName } });
      }
      if (emailAddress) {
        filterObj.and.push({ EmailAddress: { contains: emailAddress } });
      }

      setFilter(filterObj);
      // @ts-ignore
      tableRef.current?.resetPagination();
    }
  };

  const updateSelectedUser = (user: User) => {
    updateUser(user)
      .then(() => {
        queryClient.invalidateQueries(['users']).then(() => {});
        setUserModalActive(false);
      })
      .catch((error) => {
        if (error.status === 400) {
          setError(t('errors.user.emailAlreadyTaken'));
        } else {
          setError(t('error'));
        }
      });
  };

  const saveNewUser = (user: User) => {
    createUser(user)
      .then(() => {
        queryClient.invalidateQueries(['users']).then(() => {});
        setUserModalActive(false);
      })
      .catch((error) => {
        if (error.status === 400) {
          setError(t('errors.user.emailAlreadyTaken'));
        } else {
          setError(t('error'));
        }
      });
  };

  const onSave = (user: User) => {
    if ('Id' in user && user.Id) {
      updateSelectedUser(user);
    } else {
      saveNewUser(user);
    }
  };

  const defaultUser: User = {
    CompanyId: data?.Id ?? '',
    EmailAddress: '',
    FirstName: '',
    LastName: '',
    MobilePhone: '',
    Roles: [Role.Viewer],
    Company: data!,
  };

  const onDelete = () => {
    deleteUser(selectedUser?.Id as string)
      .then(() => {
        queryClient.invalidateQueries(['users']).then(() => {});
        setConfirmDeleteModalActive(false);
      })
      .catch(() => setError(t('error')));
  };

  let title = t('users.editUsersTitle.noCompany');
  if (id) {
    title = t('users.editUsersTitle.company', { company: companyName });
  }

  return (
    <div className="page">
      <div className="page-header">
        <div className="page-header__title">
          <h1 className="title">{title}</h1>
        </div>
        <div className="page-header__actions">
          {id && (
            <Button
              label={t('users.add')}
              theme={Theme.primary}
              onClick={() => {
                document.querySelector('body')!.classList.add('prevent-scroll');
                setUserModalActive(true);
                setSelectedUser(undefined);
              }}
            />
          )}
        </div>
      </div>
      <div className="search-bar search-bar--margin-bottom">
        <div className="search-bar__filters">
          <Input
            placeholder={t('users.firstName')}
            value={firstName}
            type="text"
            onChange={(e: string | number) => setFirstName(e as string)}
            onSubmit={() => handleSearch()}
          />
          <Input
            placeholder={t('users.lastName')}
            value={lastName}
            type="text"
            onChange={(e: string | number) => setLastName(e as string)}
            onSubmit={() => handleSearch()}
          />
          <Input
            placeholder={t('users.email')}
            value={emailAddress}
            type="text"
            onChange={(e: string | number) => setEmailAddress(e as string)}
            onSubmit={() => handleSearch()}
          />
        </div>
        <div className="search-bar__submit">
          <Button
            label={t('generic.search')}
            icon={Icon.chevronRight}
            theme={Theme.primary}
            onClick={() => handleSearch()}
          />
        </div>
      </div>

      <DataTable
        ref={tableRef}
        columns={columns}
        dataCall={handleDataCall}
        keyAttribute="Id"
        initialSortingState={[{ id: 'EmailAddress', desc: false }]}
      />
      {userModalActive && (
        <UserModal
          handleClose={() => {
            document.querySelector('body')!.classList.remove('prevent-scroll');
            setUserModalActive(false);
          }}
          save={(user) => {
            document.querySelector('body')!.classList.remove('prevent-scroll');
            onSave(user);
          }}
          user={!selectedUser ? defaultUser : selectedUser}
          companyId={selectedUser?.CompanyId || (id as string)}
          roles={roles}
          company={selectedUser?.Company! || defaultUser.Company!}
        />
      )}
      {confirmDeleteModalActive && (
        <MonetDeleteModal
          close={() => setConfirmDeleteModalActive(false)}
          confirm={onDelete}
          title={t('users.removeUser')}
          entityName={selectedUser ? `${selectedUser?.FirstName} ${selectedUser.LastName}` : ''}
        />
      )}
    </div>
  );
};

export default Companies;
