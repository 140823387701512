import React, { useState } from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';

import { Company, User } from '../../../Shared/Models';
import { Input } from '../../../Shared/Components';
import { Role } from '../../../Shared/Enums';
import { RoleSelectType } from '../../../Shared/Types/RoleSelectType';
import MonetModal from '../../../Shared/Components/MonetModal/MonetModal';
import useMonetUser from '../../../Hooks/useMonetUser';
import { getDateFromString, getStringFromDate } from '../../../Utils/Helpers/DateHelper';

type PropType = {
  handleClose: () => void;
  save: (user: User) => void;
  user: User;
  company: Company;
  companyId: string;
  roles: RoleSelectType[];
};

const UserModal = (props: PropType) => {
  const { companyId, company, handleClose, roles, save, user } = props;
  const { i18n, t } = useTranslation();
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [userFirstName, setUserFirstName] = useState(user.FirstName);
  const [userFirstNameError, setUserFirstNameError] = useState<string | null>(null);
  const [userLastName, setUserLastName] = useState(user.LastName);
  const [userLastNameError, setUserLastNameError] = useState<string | null>(null);
  const [userMobile, setUserMobile] = useState(user.MobilePhone);
  const [userMobileError, setUserMobileError] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState(user.EmailAddress);
  const [userEmailError, setUserEmailError] = useState<string | null>(null);
  const [userRoles, setUserRoles] = useState(user.Roles);
  const [userCertificateValidFrom, setUserCertificateValidFrom] = useState(
    getDateFromString(user.CertificateValidFrom),
  );
  const [userCertificateValidUntil, setUserCertificateValidUntil] = useState(
    getDateFromString(user.CertificateValidUntil),
  );
  const { loggedInUser } = useMonetUser();

  const defaultUserRoles = [];
  for (let i = 0; i <= user.Roles.length; i += 1) {
    const selectIndex = roles.findIndex((item) => item.value === user.Roles[i]);

    if (selectIndex !== -1) {
      const role = roles[selectIndex];
      role.enabled = role.enabled && company.Roles.includes(role.value);
      defaultUserRoles.push(role);
    }
  }

  const visibleRoles: RoleSelectType[] = [];
  visibleRoles.push(...defaultUserRoles);
  for (let i = 0; i < roles.length; i += 1) {
    const role = roles[i];
    role.enabled = role.enabled && company.Roles.includes(role.value);

    if (!defaultUserRoles.some((r) => r.value === role.value) && role.enabled) {
      visibleRoles.push(role);
    }
  }

  const resetValidation = () => {
    setUserFirstNameError(null);
    setUserLastNameError(null);
    setUserMobileError(null);
    setUserEmailError(null);
  };

  const validateUser = (u: User) => {
    resetValidation();

    let valid = true;

    if (!u.FirstName) {
      valid = false;
      setUserFirstNameError(t('users.validation.firstName'));
    }
    if (!u.LastName) {
      valid = false;
      setUserLastNameError(t('users.validation.lastName'));
    }

    if (!u.EmailAddress) {
      valid = false;
      setUserEmailError(t('users.validation.email.required'));
    } else if (u.EmailAddress.indexOf('@') === -1 || u.EmailAddress.length > 128) {
      valid = false;
      setUserEmailError(t('users.validation.email.invalid'));
    }

    if (u.MobilePhone && u.MobilePhone.length > 0) {
      const mobilePhoneRegex = /^\+?[0-9- ()]{10,16}/;
      if (u.MobilePhone.length > 16 || !mobilePhoneRegex.test(u.MobilePhone)) {
        valid = false;
        setUserMobileError(t('users.validation.phone.invalid'));
      }
    }

    return valid;
  };

  const handleSave = () => {
    const newUser: User = {
      CompanyId: companyId,
      EmailAddress: userEmail.trim(),
      FirstName: userFirstName.trim(),
      LastName: userLastName.trim(),
      MobilePhone: userMobile.trim(),
      Roles: userRoles,
      Operators: [],
      CertificateValidFrom: getStringFromDate(userCertificateValidFrom),
      CertificateValidUntil: getStringFromDate(userCertificateValidUntil),
    };

    if (user) {
      newUser.Id = user.Id;
    }

    if (validateUser(newUser)) {
      save(newUser);
    } else {
      setShowValidationErrors(true);
    }
  };

  const selectStyle = {
    multiValueRemove: (style: any, state: any) => ({
      ...style,
      ...(!state.data?.enabled
        ? {
            visibility: 'hidden',
            width: '4px',
          }
        : {}),
    }),
  };

  return (
    <MonetModal
      confirm={handleSave}
      close={handleClose}
      onConfirmLabel={user ? t('generic.save') : t('generic.create')}
      title={user ? t('users.updateUser') : t('users.createUser')}
    >
      <label htmlFor="user_firstName">
        <span>{t('users.firstName')}</span>
        <Input
          className={classNames({ 'input--has-error': userFirstNameError })}
          placeholder={t('users.firstName')}
          value={userFirstName}
          id="user_firstName"
          onChange={(e) => setUserFirstName(e as string)}
          type="text"
        />
      </label>
      <label htmlFor="user_lastName">
        <span>{t('users.lastName')}</span>
        <Input
          className={classNames({ 'input--has-error': userLastNameError })}
          placeholder={t('users.lastName')}
          value={userLastName}
          id="user_lastName"
          onChange={(e) => setUserLastName(e as string)}
          type="text"
        />
      </label>
      <label htmlFor="user_email">
        <span>{t('users.email')}</span>
        <Input
          className={classNames({ 'input--has-error': userEmailError })}
          placeholder={t('users.email')}
          value={userEmail}
          id="user_email"
          onChange={(e) => setUserEmail(e as string)}
          type="text"
          disabled={loggedInUser?.sub === user?.Auth0Id}
        />
      </label>
      <label htmlFor="user_phone">
        <span>{t('users.phone')}</span>
        <Input
          className={classNames({ 'input--has-error': userMobileError })}
          placeholder={t('users.phone')}
          value={userMobile}
          id="user_phone"
          onChange={(e) => setUserMobile(e as string)}
          type="text"
        />
      </label>
      <label htmlFor="user_roles">
        <span>{t('users.roles')}</span>
        <Select
          classNamePrefix="react-select"
          placeholder={t('generic.selectRoles')}
          defaultValue={defaultUserRoles}
          id="user_roles"
          isMulti
          onChange={(newValue): void => {
            const newRoles: Role[] = [];
            newValue.forEach((item: RoleSelectType) => newRoles.push(item.value as Role));
            setUserRoles(newRoles);
          }}
          menuPlacement="top"
          options={visibleRoles}
          isOptionDisabled={(option) => !option.enabled}
          styles={selectStyle}
          isClearable={false}
        />
      </label>
      {(userRoles.includes(Role.InspectorVF) || userRoles.includes(Role.InspectorOD)) && (
        <label htmlFor="user_certification_valid_from">
          <span>{t('users.certificationValidFrom')}</span>
          <DatePicker
            selected={userCertificateValidFrom}
            onChange={(e) => setUserCertificateValidFrom(e as Date)}
            locale={i18n.language}
            placeholderText={t('users.certificationValidFrom')}
            dateFormat="PP"
          />
        </label>
      )}
      {(userRoles.includes(Role.InspectorVF) || userRoles.includes(Role.InspectorOD)) && (
        <label htmlFor="user_certification_valid_until">
          <span>{t('users.certificationValidUntil')}</span>
          <DatePicker
            selected={userCertificateValidUntil}
            onChange={(e) => setUserCertificateValidUntil(e as Date)}
            locale={i18n.language}
            placeholderText={t('users.certificationValidFrom')}
            dateFormat="PP"
          />
        </label>
      )}

      {showValidationErrors && (
        <ul className="monet-modal__error-container">
          {userFirstNameError && <li>{userFirstNameError}</li>}
          {userLastNameError && <li>{userLastNameError}</li>}
          {userEmailError && <li>{userEmailError}</li>}
          {userMobileError && <li>{userMobileError}</li>}
        </ul>
      )}
    </MonetModal>
  );
};

export default UserModal;
