import {User} from "@auth0/auth0-react";

const formatUsername = (user?: User): string | null => {
  if (!user) {
    return null;
  }

  let username = '';
  if ('given_name' in user || 'family_name' in user) {
    username = `${user.given_name} ${user.family_name}`;
  } else if ('nickname' in user) {
    username = (user.nickname as string).replace('.', ' ');
  } else {
    username = user.email as string;
  }

  return username;
}

export default formatUsername;