enum Status {
  Active = 'Active',
  PendingApproval = 'PendingApproval',
  Rejected = 'Rejected',
  Archived = 'Archived',
  Deleted = 'Deleted',
  PendingDeletion = 'PendingDeletion'
}

export default Status;
