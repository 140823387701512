import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Accordion, InfoBlock, Question, StatusSymbol } from '../../../Shared/Components';
import { SafetyStatus, Size, Status } from '../../../Shared/Enums';
import { useRiePdf } from '../../../Hooks';

import './RieDetailPdf.scss';
import { AnswerSafety, Rie } from '../../../Shared/Models';
import Loader from '../../../Shared/Components/Loader/Loader';
import { formatDate } from '../../../Utils/Luxon/dateFormat';
import { getOperator } from '../../../Shared/Enums/Operator';

const RieDetailPdf = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { isLoading, data } = useRiePdf(id || '');
  const [rie, setRie] = useState<Rie | undefined>(undefined);

  useEffect(() => {
    if (data && !rie) {
      setRie(data);
      document.title = `${t('rie.details.info.site')} ${data.SiteNumber}`;
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  // @todo Translations.
  const renderInfoBlocks = () => (
    <>
      {rie && (
        <InfoBlock
          editable={false}
          className="rie-detail-pdf__info-block"
          title={t('rie.details.info.title')}
          version={data?.Version.toString()}
          items={[
            { label: t('rie.details.info.operator'), value: getOperator(rie.Operator) },
            { label: t('rie.details.info.site'), value: rie.SiteNumber },
            {
              label: t('rie.details.info.safetyStatus'),
              dataType: 'node',
              node: (
                <div className="safety-status-symbol-wrapper">
                  <StatusSymbol
                    status={rie.SafetyStatus}
                    size={Size.small}
                  />
                  <p>{t(`generic.colors.${rie.SafetyStatus}`)}</p>
                </div>
              ),
            },
            { label: t('rie.details.info.inventory'), value: formatDate(rie.InspectionDate as string) },
            {
              label: t('rie.details.info.status.title'),
              value: t(`rie.details.info.status.${rie.Status}`),
              editable: false,
              visible: rie.Status !== Status.Active,
            },
            {
              label: t('rie.details.info.version'),
              value: data?.Version.toString(),
            },
          ]}
        />
      )}
      {rie && (
        <InfoBlock
          editable={false}
          className="rie-detail-pdf__info-block"
          title={t('rie.details.address.title')}
          items={[
            { label: t('rie.details.address.title'), value: rie.Address },
            { label: t('rie.details.address.postalCode'), value: rie.PostalCode },
            { label: t('rie.details.address.city'), value: rie.City },
            { label: t('rie.details.address.x'), value: rie.X, dataType: 'number' },
            { label: t('rie.details.address.y'), value: rie.Y, dataType: 'number' },
          ]}
        />
      )}
      {rie && (
        <InfoBlock
          editable={false}
          className="rie-detail-pdf__info-block"
          title={t('rie.details.party.title')}
          items={[
            { label: t('rie.details.party.party'), value: rie.InspectorParty },
            { label: t('rie.details.party.executor'), value: rie.InspectorName },
            { label: t('rie.details.party.editedByName'), value: rie.EditedByName },
          ]}
        />
      )}
    </>
  );

  const calculateSafetyStatus = (safeties: AnswerSafety[]): SafetyStatus => {
    if (safeties.some((s) => s.Safety === SafetyStatus.Red)) {
      return SafetyStatus.Red;
    }
    if (safeties.some((s) => s.Safety === SafetyStatus.Orange)) {
      return SafetyStatus.Orange;
    }
    if (safeties.some((s) => s.Safety === SafetyStatus.Yellow)) {
      return SafetyStatus.Yellow;
    }
    if (safeties.some((s) => s.Safety === SafetyStatus.Green)) {
      return SafetyStatus.Green;
    }
    return SafetyStatus.Grey;
  };

  const getChapterCount = (r: Rie) => {
    if (r && r.AnswersSafety) {
      return Math.max(...r.AnswersSafety.map((answer) => Number(answer.ChapterId)));
    }
    return 0;
  };

  // eslint-disable-next-line no-underscore-dangle
  const getImageUrl = (img: string) => `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL}File/Files/${img}/$value`;

  const renderAccordions = () => {
    const accordions = [];
    if (rie) {
      for (let i = 1; i <= getChapterCount(rie); i += 1) {
        const activeAccordions = true;
        const answers = rie.Answers.filter((answer) => answer.QuestionId.startsWith(`${i}.`));
        const status = calculateSafetyStatus(rie.AnswersSafety.filter((s) => s.ChapterId === i.toString(10)));

        accordions.push(
          <Accordion
            prefix={
              <StatusSymbol
                number={i}
                status={status}
              />
            }
            title={t(`rie.${rie.Version}.${i}`)}
            className="rie-detail-pdf__accordion"
            key={i}
            activeState={activeAccordions}
          >
            {answers.map((answer) => (
              <Question
                questionId={answer.QuestionId}
                chapter={t(`rie.${rie.Version}.${i}`)}
                question={t(`rie.${rie.Version}.${answer.QuestionId}`)}
                assessment={answer.Assessment}
                safetyStatus={
                  rie.AnswersSafety.find((answersSafety) => answersSafety.QuestionId === answer.QuestionId)
                    ?.Safety as SafetyStatus
                }
                attachments={answer.Attachments}
                showDate={answer.QuestionId.startsWith('9')} // @todo there is no proper specification implementation to retrieve whether a date needs to be set here...
                date={answer.InspectionDate}
                description={answer.Description}
                dateInspectionValidUntil={answer.InspectionValidUntil}
                dateVisualInspectionValidUntil={answer.VisualInspectionValidUntil}
                key={answer.QuestionId}
                updateValueHandler={() => {}}
                showEdit={false}
                pdf
              />
            ))}
          </Accordion>,
        );
      }
      if (rie.Images && rie.Images.length > 0) {
        accordions.push(
          <Accordion
            prefix={
              <StatusSymbol
                number="F"
                status={SafetyStatus.Grey}
              />
            }
            title={t(`rie.${rie.Version}.images`, { count: rie.Images.length })}
            className="rie-detail-pdf__accordion"
            key="rie-photos"
            activeState
          >
            <div className="rie-detail__image-container rie-detail__image-container--no-slider rie-detail__image-container--pdf">
              {rie.Images.map((img) => (
                <div
                  key={img}
                  className="rie-photo"
                >
                  <img
                    key={img}
                    src={getImageUrl(img)}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </Accordion>,
        );
      }
    }

    return accordions;
  };

  return (
    <div className="rie-detail-pdf">
      <div className="rie-detail-pdf__content">
        <div className="rie-detail-pdf__info-blocks">{renderInfoBlocks()}</div>
        <div className="rie-detail-pdf__accordions">{renderAccordions()}</div>
      </div>
    </div>
  );
};

export default RieDetailPdf;
