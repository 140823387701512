import React, { useState } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import Assessment from '../../Enums/Assessment';
import Icon from '../../Enums/Icon';
import QuestionModal from '../QuestionModal/QuestionModal';
import StatusSymbol from '../StatusSymbol/StatusSymbol';
import SafetyStatus from '../../Enums/SafetyStatus';
import './Question.scss';
import { Permission } from '../../Enums';
import ImageSlider from '../ImageSlider/ImageSlider';
import QuestionEditButton from '../QuestionEditButton/QuestionEditButton';
import { formatDate } from '../../../Utils/Luxon/dateFormat';
import useMonetUser from '../../../Hooks/useMonetUser';

type PropType = {
  questionId: string;
  chapter: string;
  question: string;
  assessment: Assessment;
  safetyStatus?: SafetyStatus;
  description?: string;
  attachments?: string[];
  showDate?: boolean;
  showValidUntilDates?: boolean;
  date?: string;
  dateInspectionValidUntil?: string;
  dateVisualInspectionValidUntil?: string;
  updateValueHandler: (
    description: string,
    date: string | undefined,
    dateInspectionValidUntil: string | undefined,
    dateVisualInspectionValidUntil: string | undefined,
    assessment: Assessment,
    files: File[],
    attachments: string[],
  ) => void;
  showEdit?: boolean;
  pdf?: boolean;
};

const Question = (props: PropType) => {
  const {
    questionId,
    chapter,
    question,
    assessment,
    safetyStatus,
    description,
    attachments,
    updateValueHandler,
    showEdit,
    showDate,
    showValidUntilDates,
    date,
    dateInspectionValidUntil,
    dateVisualInspectionValidUntil,
    pdf,
  } = props;

  const { checkPermission } = useMonetUser();

  const [editModalActive, setEditModalActive] = useState(false);

  const formattedDate = formatDate(date);
  const formattedInspectionValidUntilDate = formatDate(dateInspectionValidUntil);
  const formattedVisualInspectionValidUntilDate = formatDate(dateVisualInspectionValidUntil);

  // eslint-disable-next-line no-underscore-dangle
  const getImageUrl = (img: string) => `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL}File/Files/${img}/$value`;
  const handleOnEditClick = () => {
    if (checkPermission(Permission.RIE_SUBMIT)) {
      document.querySelector('body')!.classList.add('prevent-scroll');
      setEditModalActive(!editModalActive);
    }
  };

  return (
    <div className="question">
      <div className="question__header">
        <div className="question__header-left">
          <span
            className={classNames('question__question', {
              'question__question--requires-attention':
                safetyStatus !== SafetyStatus.Grey && safetyStatus !== SafetyStatus.Green,
            })}
          >
            <span className="question__question_number">{questionId}</span> {question}
          </span>
        </div>
        <div className="question__header-right">
          <QuestionEditButton
            icon={Icon.pencil}
            assessment={assessment}
            safetyStatus={safetyStatus as SafetyStatus}
            iconColor="white"
            allowEdit={(showEdit && checkPermission(Permission.RIE_SUBMIT)) as boolean}
            onClick={handleOnEditClick}
          />
        </div>
      </div>
      {(description ||
        formattedInspectionValidUntilDate ||
        formattedVisualInspectionValidUntilDate ||
        (showDate && formattedDate) ||
        (attachments && attachments?.length > 0)) && (
        <div
          className={classNames(
            'question__content',
            {
              'question__content--img-only':
                !description &&
                !(date && date !== '0001-01-01T00:00:00Z' && showDate) &&
                !(formattedInspectionValidUntilDate || formattedVisualInspectionValidUntilDate) &&
                attachments,
            },
            {
              'question__content--description-only': description && date && showDate && !attachments,
            },
            {
              'question__content--description-only': description,
            },
            {
              'question__content--description-only':
                formattedInspectionValidUntilDate || formattedVisualInspectionValidUntilDate,
            },
            { 'question__content--pdf': pdf },
          )}
        >
          {(description || (showDate && formattedDate) || (description && showDate && formattedDate)) &&
            !(formattedInspectionValidUntilDate || formattedVisualInspectionValidUntilDate) && (
              <div className="question__description">
                {showDate && formattedDate && (
                  <div className="question__date">
                    {t('generic.date')}: {formattedDate}
                  </div>
                )}
                {description && <div>{description}</div>}
              </div>
            )}
          {(formattedInspectionValidUntilDate || formattedVisualInspectionValidUntilDate) && (
            <div className="question__description">
              {formattedInspectionValidUntilDate && (
                <div className="question__date">
                  {t('generic.inspection-valid-till-date')}: {formattedInspectionValidUntilDate}
                </div>
              )}
              {formattedVisualInspectionValidUntilDate && (
                <div className="question__date">
                  {t('generic.visual-inspection-valid-till-date')}: {formattedVisualInspectionValidUntilDate}
                </div>
              )}
              {description && <div>{description}</div>}
            </div>
          )}

          {!pdf && attachments && attachments?.length > 0 && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {attachments.length > 0 && (
                <div className="question__image-container">
                  <ImageSlider images={attachments} />
                </div>
              )}
            </>
          )}
          {pdf && attachments && (
            <div
              className={classNames('question__image-container', 'question__image-container--no-slider', {
                'question__image-container--pdf': pdf,
              })}
            >
              {attachments.map((img) => (
                <div
                  key={img}
                  className="rie-photo"
                >
                  <img
                    key={img}
                    src={getImageUrl(img)}
                    alt=""
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {editModalActive && (
        <div className="question__edit-modal">
          <QuestionModal
            attachments={attachments}
            assessment={assessment}
            value={description || ''}
            prefix={<StatusSymbol number={questionId} />}
            chapter={chapter}
            question={question}
            handleClose={() => {
              document.querySelector('body')!.classList.remove('prevent-scroll');
              setEditModalActive(false);
            }}
            updateValue={(
              d: string,
              da: string | undefined,
              dai: string | undefined,
              dav: string | undefined,
              a: Assessment,
              f: File[],
              at: string[],
            ) => {
              document.querySelector('body')!.classList.remove('prevent-scroll');
              updateValueHandler(d, da, dai, dav, a, f, at);
              setEditModalActive(false);
            }}
            date={date}
            dateInspectionValidUntil={dateInspectionValidUntil}
            dateVisualInspectionValidUntil={dateVisualInspectionValidUntil}
            showDate={showDate}
            showValidUntilDates={showValidUntilDates}
          />
        </div>
      )}
    </div>
  );
};

Question.defaultProps = {
  description: '',
  attachments: [],
  safetyStatus: SafetyStatus.Green,
  showEdit: true,
  showDate: false,
  showValidUntilDates: false,
  date: undefined,
  dateInspectionValidUntil: undefined,
  dateVisualInspectionValidUntil: undefined,
  pdf: false,
};

export default Question;
