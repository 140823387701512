import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import IconRender from '../Icon/IconRender';
import { Icon, Theme } from '../../Enums';
import IconPosition from '../../Enums/IconPosition';

import './Button.scss';

type PropType = {
  className?: string;
  disabled?: boolean;
  icon?: Icon;
  iconColor?: string;
  iconPosition?: IconPosition;
  isSubmit?: boolean;
  label?: string;
  onClick: () => void;
  theme?: Theme;
};

const Button = (props: PropType) => {
  const { className, disabled, icon, iconColor, iconPosition, isSubmit, onClick, label, theme } = props;
  const { t } = useTranslation();

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className={classNames(
        className,
        'button',
        { [`button--${theme}`]: theme },
        { 'button--has-icon': icon },
        { [`button--icon-${iconPosition}`]: icon && iconPosition },
        { 'button--disabled': disabled },
      )}
      title={label || undefined}
      onClick={(e: any): void => {
        e.preventDefault();
        onClick();
      }}
      disabled={disabled}
    >
      {label && (
        <span
          className="button__label"
          data-label={t(label)}
        >
          {t(label)}
        </span>
      )}
      {icon && (
        <IconRender
          className="button__icon"
          icon={icon}
          width="15px"
          height="15px"
          color={iconColor}
        />
      )}
    </button>
  );
};

Button.defaultProps = {
  className: '',
  disabled: false,
  icon: '',
  iconColor: '#FFFFFF',
  iconPosition: IconPosition.right,
  isSubmit: false,
  label: '',
  theme: Theme.default,
};

export default Button;
