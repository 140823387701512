import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

type PropType = {
  children: any;
};

const Auth0ProviderWithHistory = ({ children }: PropType): JSX.Element => {
  const domain = window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_DOMAIN; // eslint-disable-line
  const clientId = window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_CLIENT_ID; // eslint-disable-line
  const audience = window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_AUDIENCE; // eslint-disable-line
  const scope = `openid profile email ${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL}`; // eslint-disable-line

  const {
    location: { pathname, origin },
  } = window;

  const navigator = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigator(appState?.returnTo || pathname);
  };

  return (
    <Auth0Provider
      clientId={clientId || ''}
      domain={domain || ''}
      scope={scope}
      redirectUri={origin}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
