import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import IconRender from '../Icon/IconRender';
import { Icon, SafetyStatus, Theme } from '../../Enums';

import './QuestionEditButton.scss';
import Assessment from '../../Enums/Assessment';

type PropType = {
  className?: string;
  icon: Icon;
  iconColor?: string;
  onClick: () => void;
  allowEdit: boolean;
  assessment: Assessment;
  safetyStatus: SafetyStatus;
};

const QuestionEditButton = (props: PropType) => {
  const { className, onClick, icon, iconColor, allowEdit, assessment, safetyStatus } = props;
  const { t } = useTranslation();

  let theme: Theme;
  let assessmentLabel = 'generic.';

  switch (safetyStatus) {
    case SafetyStatus.Yellow:
      theme = Theme.yellow;
      break;
    case SafetyStatus.Orange:
      theme = Theme.orange;
      break;
    case SafetyStatus.Red:
      theme = Theme.red;
      break;
    case SafetyStatus.Grey:
    case SafetyStatus.Green:
    default:
      theme = Theme.default;
      break;
  }
  switch (assessment) {
    case Assessment.NotApplicable:
      assessmentLabel += 'notApplicable';
      break;
    case Assessment.Yes:
      assessmentLabel += 'yes';
      break;
    case Assessment.No:
    default:
      assessmentLabel += 'no';
      break;
  }

  return (
    <button
      type="button"
      className={classNames(
        className,
        'question-edit-button',
        { 'question-edit-button--no-edit': !allowEdit },
        { [`question-edit-button--${theme}`]: theme },
      )}
      title={t(assessmentLabel)}
      onClick={(e: any): void => {
        e.preventDefault();

        if (allowEdit) {
          onClick()
        }
      }}
    >
      {t(assessmentLabel)}
      {allowEdit && (
        <div className="button-container">
          <div className="inner-container">
            <IconRender
              icon={icon}
              width="15px"
              height="15px"
              color={iconColor}
            />
          </div>
        </div>
      )}
    </button>
  );
};

QuestionEditButton.defaultProps = {
  className: '',
  iconColor: '#FFFFFF',
};

export default QuestionEditButton;
