import React, { useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import { UseQueryResult } from 'react-query';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import Select from 'react-select';

import { InputWithIcon, Unauthorized } from '../../../Shared/Components';
import useAuditLogs from '../Hooks/useAuditLogs';
import DateTimeCell from '../../../Shared/Components/Table/Cells/DateTimeCell';
import { AuditLogEntry } from '../../../Shared/Models';
import Input from '../../../Shared/Components/Input/Input';
import Icon from '../../../Shared/Enums/Icon';
import Button from '../../../Shared/Components/Button/Button';
import Theme from '../../../Shared/Enums/Theme';
import FilterObjectType from '../../../Shared/Types/FilterObjectType';
import { Permission } from '../../../Shared/Enums';
import DataTable from '../../../Shared/Components/Table/DataTable';
import { TableQueryOptions } from '../../../Shared/Types/QueryOptions';
import { ODataResult } from '../../Rie/Types/ODataResult';

import './AuditLogs.scss';
import { AuditActionType } from '../../../Shared/Types/AuditActionType';
import SiteRieCell from '../../../Shared/Components/Table/Cells/SiteRieCell';
import OperatorCell from '../../../Shared/Components/Table/Cells/OperatorCell';
import { OperatorSelectType } from '../../../Shared/Types/OperatorSelectType';
import useMonetUser from '../../../Hooks/useMonetUser';

const AuditLogs = () => {
  const { i18n, t } = useTranslation();
  const { ops, hasMultipleOperators, checkPermission } = useMonetUser();
  const tableRef = useRef();

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [siteNumber, setSiteNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [filter, setFilter] = useState<FilterObjectType>({});
  const [action, setAction] = useState<AuditActionType>({
    value: '',
    label: t('auditLogs.action'),
  });

  const [operator, setOperator] = useState<OperatorSelectType>({
    value: '',
    label: t('locations.searchBar.chooseOperator'),
  });

  const operatorOptions: OperatorSelectType[] = [
    ...[{ value: '', label: t('locations.searchBar.chooseOperator') }],
    ...ops.map((op) => ({ value: op, label: t(`generic.operators.${op.toLowerCase()}`) })),
  ];

  const formatUser = (row: AuditLogEntry): string => {
    if (!row.FirstName) {
      return row.LastName;
    }
    if (!row.LastName) {
      return row.FirstName;
    }
    return `${row.FirstName} ${row.LastName}`;
  };

  const actionOptions: AuditActionType[] = [
    { value: '', label: t('auditLogs.action') },
    { value: 'ReadRie', label: t(`auditLogs.actions.ReadRie`) },
    { value: 'ReadAllRies', label: t(`auditLogs.actions.ReadAllRies`) },
    { value: 'SubmitRie', label: t(`auditLogs.actions.SubmitRie`) },
    { value: 'ApproveRie', label: t(`auditLogs.actions.ApproveRie`) },
    { value: 'RejectRie', label: t(`auditLogs.actions.RejectRie`) },
    { value: 'ArchiveRie', label: t(`auditLogs.actions.ArchiveRie`) },
    { value: 'DeleteRie', label: t(`auditLogs.actions.DeleteRie`) },
  ];
  const formatAction = (act: string): string => t(`auditLogs.actions.${act}`);

  const columns = useMemo<ColumnDef<AuditLogEntry>[]>(
    () => [
      {
        header: t('auditLogs.date'),
        accessorKey: 'DateTime',
        cell: DateTimeCell,
      },
      {
        header: t('auditLogs.user'),
        id: 'User',
        accessorFn: (row) => row,
        cell: (row) => formatUser(row.getValue()),
        enableSorting: false,
      },
      {
        header: t('auditLogs.email'),
        id: 'Email',
        accessorKey: 'Email',
      },
      {
        header: t('auditLogs.action'),
        accessorKey: 'Action',
        cell: (cell) => formatAction(cell.getValue()),
        enableSorting: false,
      },
      {
        header: t('auditLogs.operator'),
        accessorKey: 'Operator',
        cell: OperatorCell,
        hide: !hasMultipleOperators(),
        enableSorting: false,
      },
      {
        header: t('auditLogs.executedAt'),
        id: 'ExecutedAt',
        accessorFn: (row) => row,
        cell: SiteRieCell,
        enableSorting: false,
      },
    ],
    [i18n.language],
  );

  if (!checkPermission(Permission.READ_AUDIT_LOGS)) {
    return <Unauthorized pageTitle={t('generic.auditLogs')} />;
  }

  const handleSearch = () => {
    const filterObj: FilterObjectType = {
      and: [],
    };

    if (filterObj && filterObj.and) {
      if (siteNumber) {
        filterObj.and.push({ ExecutedAt: { contains: siteNumber } });
      }

      if (email) {
        filterObj.and.push({ Email: { contains: email } });
      }

      if (firstName) {
        filterObj.and.push({ FirstName: { contains: firstName } });
      }

      if (lastName) {
        filterObj.and.push({ LastName: { contains: lastName } });
      }

      if (startDate) {
        // Parsing to correct format, and creating a new Date object. Jumping through hoops for odata DO NOT REMOVE
        const parsedStartDate = DateTime.fromISO(startDate.toISOString()).toFormat('yyyy-MM-dd');
        filterObj.and.push({ DateTime: { ge: new Date(parsedStartDate) } });
      }

      if (endDate) {
        // Parsing to correct format, and creating a new Date object. Jumping through hoops for odata DO NOT REMOVE
        const parsedEndDate = DateTime.fromISO(endDate.toISOString()).toFormat('yyyy-MM-dd');
        filterObj.and.push({ DateTime: { le: new Date(parsedEndDate) } });
      }

      if (action.value) {
        filterObj.and.push({ Action: action.value });
      }

      if (operator.value) {
        filterObj.and.push({ Operator: operator.value });
      }

      setFilter(filterObj);
      // @ts-ignore
      tableRef.current?.resetPagination();
    }
  };

  const handleDataCall = (queryOptions: TableQueryOptions): UseQueryResult<ODataResult, Error> =>
    useAuditLogs({
      pageIndex: queryOptions.pageIndex,
      pageSize: queryOptions.pageSize,
      sorting: queryOptions.sorting,
      filter,
    });

  return (
    <div className="audit-logs page">
      <div className="page-header">
        <div className="page-header__title">
          <h1 className="title">{t('generic.auditLogs')}</h1>
        </div>
      </div>
      <div className="search-bar search-bar--margin-bottom">
        <div className="search-bar__search-section">
          <div className="search-bar__filters">
            <InputWithIcon
              icon={Icon.calendar}
              size="28px"
            >
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date as Date)}
                locale={i18n.language}
                placeholderText={t('generic.dateStart')}
                dateFormat="PP"
              />
            </InputWithIcon>
            <InputWithIcon
              icon={Icon.calendar}
              size="28px"
            >
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date as Date)}
                locale={i18n.language}
                placeholderText={t('generic.dateEnd')}
                dateFormat="PP"
              />
            </InputWithIcon>
            <Input
              placeholder={t('auditLogs.email')}
              value={email}
              type="text"
              icon={Icon.user}
              onChange={(e: string | number) => setEmail(e as string)}
              onSubmit={() => handleSearch()}
            />
            <Input
              placeholder={t('auditLogs.firstName')}
              value={firstName}
              type="text"
              onChange={(e: string | number) => setFirstName(e as string)}
              onSubmit={() => handleSearch()}
            />
            <Input
              placeholder={t('auditLogs.lastName')}
              value={lastName}
              type="text"
              onChange={(e: string | number) => setLastName(e as string)}
              onSubmit={() => handleSearch()}
            />
            <Select
              classNamePrefix="react-select"
              placeholder={t('auditLogs.action')}
              onChange={(newValue): void => {
                setAction(newValue!);
              }}
              options={actionOptions}
            />
            {hasMultipleOperators() && (
              <InputWithIcon
                icon={Icon.operator}
                size="28px"
              >
                <Select
                  placeholder={t('locations.searchBar.chooseOperator')}
                  classNamePrefix="react-select"
                  defaultValue={operator}
                  onChange={(v) => {
                    if (v) {
                      setOperator(v);
                    }
                  }}
                  options={operatorOptions}
                />
              </InputWithIcon>
            )}
            <Input
              placeholder={t('rie.details.info.site')}
              value={siteNumber}
              type="text"
              icon={Icon.siteLocation}
              onChange={(e: string | number) => setSiteNumber(e as string)}
              onSubmit={() => handleSearch()}
            />
          </div>
          <div className="search-bar__submit">
            <Button
              label={t('generic.search')}
              icon={Icon.chevronRight}
              theme={Theme.primary}
              onClick={() => handleSearch()}
            />
          </div>
        </div>
      </div>

      <DataTable
        ref={tableRef}
        columns={columns}
        dataCall={handleDataCall}
        keyAttribute="Id"
        initialSortingState={[{ id: 'DateTime', desc: true }]}
      />
    </div>
  );
};

export default AuditLogs;
