import React from 'react';
import { SafetyStatus } from '../../Enums';

import './Markers.scss';

type MarkerPropType = {
  lat: number;
  lng: number;
  siteNumber: string;
  operator: string;
  status: SafetyStatus;
  rieId: string;
  onClick: () => void;
};

type ClusterMarkerPropType = {
  count: number;
  lat: number;
  lng: number;
  id: string | number | undefined;
  onClick: () => void;
};

const Marker = (props: MarkerPropType) => {
  const { lat, lng, siteNumber, operator, status, rieId, onClick } = props;
  return (
    <div
      className={`marker marker--location marker--color-${status.toLowerCase()}`}
      data-lat={lat}
      data-lng={lng}
      data-safety-status={status}
      data-rie-id={rieId}
    >
      <button
        type="button"
        className="marker-label"
        onClick={onClick}
      >
        {siteNumber} {operator}
      </button>
    </div>
  );
};

const ClusterMarker = (props: ClusterMarkerPropType) => {
  const { lat, lng, count, id, onClick } = props;
  return (
    <button
      type="button"
      key={id}
      className="marker marker--cluster"
      data-lat={lat}
      data-lng={lng}
      onClick={onClick}
    >
      {count}
    </button>
  );
};

export { Marker, type MarkerPropType, ClusterMarker, type ClusterMarkerPropType };
