import buildQuery from 'odata-query';

import http from '../../../Utils/Axios/http';
import getODataQueryOptions, {QueryOptions} from "../../../Shared/Types/QueryOptions";

const base = '/Audit/AuditLogEntries';

const getAuditLogs = async (queryOptions: QueryOptions) => {
  const query = buildQuery(getODataQueryOptions(queryOptions));

  const { data } = await http.get(`${base}${query}`);
  return {
    value: data.value,
    count: data['@odata.count'],
  };
};

export default getAuditLogs;
