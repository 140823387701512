import React, { type ReactNode } from 'react';

import './Title.scss';

type PropType = {
  children: ReactNode;
  className?: string;
  color?: string;
};

const Title = (props: PropType) => {
  const { className, color, children } = props;
  return (
    <h1
      className={`title ${className}`}
      style={{ color }}
    >
      {children}
    </h1>
  );
};

Title.defaultProps = {
  className: '',
  color: 'var(--orange)',
};

export default Title;
