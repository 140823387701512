import React, { useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Select from 'react-select';
import { Company } from '../../../../Shared/Models';
import Input from '../../../../Shared/Components/Input/Input';
import MonetModal from '../../../../Shared/Components/MonetModal/MonetModal';
import { validatePostalCode } from '../../../../Utils/Helpers/ValidationHelpers';
import { RoleSelectType } from '../../../../Shared/Types/RoleSelectType';
import { Role } from '../../../../Shared/Enums';

type PropType = {
  handleClose: () => void;
  save: (company: Company) => void;
  company: Company;
  roles: RoleSelectType[];
};

// @todo Create a modal component which we can extend here.
const CompanyModal = (props: PropType) => {
  const { company, handleClose, save, roles } = props;
  const { t } = useTranslation();

  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const [companyName, setCompanyName] = useState(company.Name);
  const [companyNameError, setCompanyNameError] = useState<string | null>(null);
  const [companyStreet, setCompanyStreet] = useState(company.Street);
  const [companyStreetError, setCompanyStreetError] = useState<string | null>(null);
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState(company.PhoneNumber);
  const [companyCity, setCompanyCity] = useState(company.City);
  const [companyCityError, setCompanyCityError] = useState<string | null>(null);
  const [companyPostalCode, setCompanyPostalCode] = useState(company.PostalCode);
  const [companyPostalCodeError, setCompanyPostalCodeError] = useState<string | null>(null);
  const [companyRoles, setCompanyRoles] = useState<Role[]>(company.Roles);

  const defaultCompanyRoles = [];
  for (let i = 0; i <= company.Roles.length; i += 1) {
    const selectIndex = roles.findIndex((item) => item.value === company.Roles[i]);

    if (selectIndex !== -1) {
      const role = roles[selectIndex];
      defaultCompanyRoles.push(role);
    }
  }

  const visibleRoles: RoleSelectType[] = [];
  visibleRoles.push(...defaultCompanyRoles);
  for (let i = 0; i < roles.length; i += 1) {
    const role = roles[i];

    if (!defaultCompanyRoles.some((r) => r.value === role.value) && role.enabled) {
      visibleRoles.push(role);
    }
  }

  const resetValidation = () => {
    setCompanyNameError(null);
    setCompanyStreetError(null);
    setCompanyCityError(null);
    setCompanyPostalCodeError(null);
  };

  const validateCompany = (comp: Company) => {
    resetValidation();

    let valid = true;

    if (!comp.Name) {
      valid = false;
      setCompanyNameError(t('companies.validation.name'));
    }
    if (!comp.Street) {
      valid = false;
      setCompanyStreetError(t('companies.validation.street'));
    }
    if (!comp.City) {
      valid = false;
      setCompanyCityError(t('companies.validation.city'));
    }
    if (!comp.PostalCode) {
      valid = false;
      setCompanyPostalCodeError(t('companies.validation.postalCode.required'));
    }
    if (!validatePostalCode(comp.PostalCode)) {
      valid = false;
      setCompanyPostalCodeError(t('companies.validation.postalCode.invalid'));
    }

    return valid;
  };

  const handleSave = () => {
    const newComp: Company = {
      Name: companyName,
      Roles: companyRoles,
      Street: companyStreet,
      PhoneNumber: companyPhoneNumber,
      City: companyCity,
      PostalCode: companyPostalCode,
    };

    if (company) {
      newComp.Id = company.Id;
    }

    if (validateCompany(newComp)) {
      save(newComp);
    } else {
      setShowValidationErrors(true);
    }
  };

  const selectStyle = {
    multiValueRemove: (style: any, state: any) => ({
      ...style,
      ...(!state.data?.enabled
        ? {
            visibility: 'hidden',
            width: '4px',
          }
        : {}),
    }),
  };

  return (
    <MonetModal
      close={handleClose}
      confirm={handleSave}
      onConfirmLabel={company ? t('generic.save') : t('generic.create')}
      title={company ? t('companies.updateCompany') : t('companies.newCompany')}
    >
      <label htmlFor="company_name">
        <span>{t('companies.name')}</span>
        <Input
          className={classNames({ 'input--has-error': companyNameError })}
          placeholder={t('companies.name')}
          value={companyName}
          id="company_name"
          onChange={(e) => setCompanyName(e as string)}
          type="text"
        />
      </label>
      <label htmlFor="company_street">
        <span>{t('companies.street')}</span>
        <Input
          className={classNames({ 'input--has-error': companyStreetError })}
          placeholder={t('companies.street')}
          value={companyStreet}
          id="company_street"
          onChange={(e) => setCompanyStreet(e as string)}
          type="text"
        />
      </label>
      <label htmlFor="company_city">
        <span>{t('companies.city')}</span>
        <Input
          className={classNames({ 'input--has-error': companyCityError })}
          placeholder={t('companies.city')}
          value={companyCity}
          id="company_city"
          onChange={(e) => setCompanyCity(e as string)}
          type="text"
        />
      </label>
      <label htmlFor="company_postal-code">
        <span>{t('companies.postalCode')}</span>
        <Input
          className={classNames({ 'input--has-error': companyPostalCodeError })}
          placeholder={t('companies.postalCode')}
          value={companyPostalCode}
          id="company_postal-code"
          onChange={(e) => setCompanyPostalCode(e as string)}
          type="text"
        />
      </label>
      <label htmlFor="company_phone-number">
        <span>{t('companies.phoneNumber')}</span>
        <Input
          placeholder={t('companies.phoneNumber')}
          value={companyPhoneNumber}
          id="company_phone-number"
          onChange={(e) => setCompanyPhoneNumber(e as string)}
          type="text"
        />
      </label>
      <label htmlFor="company_roles">
        <span>{t('companies.roles')}</span>
        <Select
          classNamePrefix="react-select"
          placeholder={t('generic.selectRoles')}
          defaultValue={defaultCompanyRoles}
          id="company_roles"
          isMulti
          onChange={(newValue): void => {
            const newRoles: Role[] = [];
            newValue.forEach((item: RoleSelectType) => newRoles.push(item.value as Role));
            setCompanyRoles(newRoles);
          }}
          menuPlacement="top"
          options={visibleRoles}
          isOptionDisabled={(option) => !option.enabled}
          styles={selectStyle}
          isClearable={false}
        />
      </label>

      {showValidationErrors && (
        <ul className="monet-modal__error-container">
          {companyNameError && <li>{companyNameError}</li>}
          {companyStreetError && <li>{companyStreetError}</li>}
          {companyCityError && <li>{companyCityError}</li>}
          {companyPostalCodeError && <li>{companyPostalCodeError}</li>}
        </ul>
      )}
    </MonetModal>
  );
};

export default CompanyModal;
