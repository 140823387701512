import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../Shared/Models';
import { IconButton } from '../../../Shared/Components';
import { Icon, Theme } from '../../../Shared/Enums';
import useMonetUser from '../../../Hooks/useMonetUser';

const UserCommandCell = ({ row, selectedUser, activateConfirmDeleteModal, activateUserModal }: any) => {
  const { permissions, getRoleOptions, loggedInUser } = useMonetUser();
  const { t } = useTranslation();
  const roles = useMemo(() => getRoleOptions(), [permissions]);

  const user = row.original as User;

  return (
    <div className="buttons">
      <IconButton
        icon={Icon.pencil}
        label={t('generic.edit')}
        size="15px"
        theme={Theme.primary}
        onClick={() => {
          document.querySelector('body')!.classList.add('prevent-scroll');
          selectedUser(user);
          activateUserModal(true);
        }}
      />
      {user.Auth0Id !== loggedInUser?.sub && user.Roles.every((r) => roles.some((mr) => mr.value === r && mr.enabled)) && (
        <IconButton
          icon={Icon.delete}
          label={t('generic.delete')}
          size="15px"
          theme={Theme.dark}
          onClick={() => {
            selectedUser(user);
            activateConfirmDeleteModal(true);
          }}
        />
      )}
    </div>
  );
};

export default UserCommandCell;
