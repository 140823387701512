import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import IconRender from '../Icon/IconRender';
import { Icon, Theme } from '../../Enums';

import './IconButton.scss';

// @todo Do this in styling instead based on a class.
const IconColors = {
  [Theme.default]: 'var(--black)',
  [Theme.transparent]: 'var(--black)',
  [Theme.transparentInverted]: 'var(--white)',
  [Theme.primary]: 'var(--white)',
  [Theme.primaryInverted]: 'var(--orange)',
  [Theme.dark]: 'var(--white)',
  [Theme.orange]: 'var(--orange)',
  [Theme.yellow]: 'var(--yellow)',
  [Theme.red]: 'var(--red)',
};

type PropType = {
  icon: Icon;
  label: string;
  className?: string;
  size?: string;
  theme?: Theme;
  isSubmit?: boolean;
  onClick?: () => void;
};

const IconButton = (props: PropType) => {
  const { icon, label, className, size, isSubmit, theme, onClick } = props;
  const { t } = useTranslation();

  return (
    <button
      type={isSubmit ? 'submit' : 'button'}
      className={classNames(className, 'icon-button', {
        [`icon-button--${theme}`]: theme,
      })}
      title={t(label)}
      onClick={(e) => {
        e.stopPropagation();
        if (onClick !== undefined) {
          onClick();
        }
      }}
    >
      <IconRender
        className="icon-button__icon"
        icon={icon}
        color={theme ? IconColors[theme] : undefined}
        width={size}
        height={size}
      />
    </button>
  );
};

IconButton.defaultProps = {
  className: '',
  size: '20px',
  theme: Theme.default,
  isSubmit: false,
  onClick: null,
};

export default IconButton;
