import classNames from 'classnames';
import React from 'react';
import { SafetyStatus, Size } from '../../Enums';

import './StatusSymbol.scss';

type PropType = {
  status?: SafetyStatus;
  number?: number | string;
  size?: Size;
};

const StatusSymbol = (props: PropType) => {
  const { status, number, size } = props;
  return (
    <span
      className={classNames('status-symbol', {
        [`status-symbol--${status?.toLowerCase()}`]: status,
        [`status-symbol--${size}`]: size,
      })}
    >
      {number && <span className="status-symbol__number">{number}</span>}
    </span>
  );
};

StatusSymbol.defaultProps = {
  number: null,
  status: SafetyStatus.Grey,
  size: Size.default,
};

export default StatusSymbol;
