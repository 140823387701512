import React, { ReactNode } from 'react';
import './Container.scss';

type PropType = {
  children: ReactNode;
};

const Container = ({ children }: PropType) => <div className="container">{children}</div>;

export default Container;
