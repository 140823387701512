enum Icon {
  archive = 'archive',
  arrowDown = 'arrow-down',
  arrowUp = 'arrow-up',
  auditLog = 'audit-log',
  calendar = 'calendar',
  chevronDown = 'chevron-down',
  chevronLeft = 'chevron-left',
  chevronLeftEnd = 'chevron-left-end',
  chevronRight = 'chevron-right',
  chevronRightEnd = 'chevron-right-end',
  close = 'close',
  cloud = 'cloud',
  companies = 'companies',
  delete = 'delete',
  exit = 'exit',
  export = 'export',
  flagNL = 'flagNL',
  flagUK = 'flagUK',
  hashtag = 'hashtag',
  house = 'house',
  image = 'image',
  importLog = 'import-log',
  lock = 'lock',
  logo = 'logo',
  mail = 'mail',
  marker = 'marker',
  menu = 'menu',
  myLocation = 'my-location',
  operator = 'operator',
  pencil = 'pencil',
  phone = 'phone',
  quarantine = 'quarantine',
  search = 'search',
  siteLocation = 'site-location',
  siteNumber = 'site-number',
  street = 'street',
  submittedRies = 'submitted-ries',
  user = 'user',
}

export default Icon;
