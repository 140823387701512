import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import RieDataTable from '../Components/RieDataTable';
import { useQuarantineRies } from '../../../Hooks';
import { Permission, Status } from '../../../Shared/Enums';
import { SearchBar, Unauthorized } from '../../../Shared/Components';
import { TableQueryOptions } from '../../../Shared/Types/QueryOptions';
import { ODataResult } from '../Types/ODataResult';
import useMonetUser from '../../../Hooks/useMonetUser';
import {useFilter} from "../../../Shared/Providers/FilterContextProvider";

const Quarantine = () => {
  const { t } = useTranslation();
  const { checkPermission, hasMultipleOperators } = useMonetUser();
  const tableRef = useRef();

  const {filterObjects} = useFilter();

  const showMultipleOperators = hasMultipleOperators();

  if (!(checkPermission(Permission.RIE_QUARANTINE))) {
    return <Unauthorized pageTitle={t('menu.quarantine')} />;
  }

  const handleDataCall = (queryOptions: TableQueryOptions): UseQueryResult<ODataResult, Error> =>
    useQuarantineRies({
      pageIndex: queryOptions.pageIndex,
      pageSize: queryOptions.pageSize,
      sorting: queryOptions.sorting,
      filter: filterObjects.quarantineFilter,
    });

  return (
    <div className="quarantine page">
      <div className="page-header">
        <div className="page-header__title">
          <h1 className="title">{t('menu.quarantine')}</h1>
        </div>
      </div>
      <SearchBar
        showMultipleOperators={showMultipleOperators}
        showSimpleHeader
        showStatusFilter
        excludedStatuses={[Status.Archived]}
        filterKey="quarantineFilter"
      />
      <RieDataTable
        ref={tableRef}
        dataCall={handleDataCall}
      />
    </div>
  );
};

export default Quarantine;
