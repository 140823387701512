import React, { ReactNode } from 'react';
import { Icon } from '../../Enums';
import IconRender from '../Icon/IconRender';

import './InputWithIcon.scss';

type PropType = {
  children: ReactNode;
  icon: Icon;
  size: string;
};

const InputWithIcon = ({ children, icon, size }: PropType) => (
  <div className="input-with-icon">
    <div className="input-with-icon__icon-container">
      <IconRender
        icon={icon}
        width={size}
        height={size}
        color="var(--dark-grey)"
      />
    </div>
    <div className="input-with-icon__field-container">{children}</div>
  </div>
);

export default InputWithIcon;
