import { useQuery, UseQueryResult } from 'react-query';
import buildQuery from 'odata-query';
import http from '../../Utils/Axios/http';
import { ODataResult } from '../../Features/Rie/Types/ODataResult';
import { FilterQueryOptions } from '../../Shared/Types/QueryOptions';

export const getMapRies = async (filter: FilterQueryOptions) => {
  // eslint-disable-next-line no-param-reassign
  filter.filter.and = filter.filter.and?.filter(i => !i.Status);
  
  let query = buildQuery<any>({ filter: filter.filter, count: true });
  query = query.replaceAll("'tolower(''", "tolower('").replaceAll("'')'", "')");

  const { data } = await http.get(`/Rie/Ries/MapLocations${query}`);

  return {
    value: data.value,
    count: data['@odata.count'],
  };
};

export default function useMapRies(filter: FilterQueryOptions): UseQueryResult<ODataResult, Error> {
  return useQuery(['ries', 'locations', 'map', filter], () => getMapRies(filter));
}
