enum Permission {
  ROLE_ASSIGN_ADMIN_INDICIA = 'assign:role:administratorindicia',
  ROLE_ASSIGN_ADMIN_MONET = 'assign:role:administratormonet',
  ROLE_ASSIGN_COMPANY_MANAGER_KPN = 'assign:role:companymanagerkpn',
  ROLE_ASSIGN_COMPANY_MANAGER_VF = 'assign:role:companymanagervf',
  ROLE_ASSIGN_COMPANY_MANAGER_OD = 'assign:role:companymanagerod',
  ROLE_ASSIGN_INSPECTOR_VF = 'assign:role:inspectorvf',
  ROLE_ASSIGN_INSPECTOR_OD = 'assign:role:inspectorod',
  ROLE_ASSIGN_OPERATOR_KPN = 'assign:role:operatorkpn',
  ROLE_ASSIGN_OPERATOR_VF = 'assign:role:operatorvf',
  ROLE_ASSIGN_OPERATOR_OD = 'assign:role:operatorod',
  ROLE_ASSIGN_OPERATOR_OWNER_KPN = 'assign:role:operatorownerkpn',
  ROLE_ASSIGN_OPERATOR_OWNER_VF = 'assign:role:operatorownervf',
  ROLE_ASSIGN_OPERATOR_OWNER_OD = 'assign:role:operatorownerod',
  ROLE_ASSIGN_VALIDATOR_VF = 'assign:role:validatorvf',
  ROLE_ASSIGN_VALIDATOR_OD = 'assign:role:validatorod',
  ROLE_ASSIGN_VIEWER = 'assign:role:viewer',

  RIE_SUBMIT = 'submit:ries',
  RIE_QUARANTINE = 'quarantine:ries',
  RIE_ARCHIVE = 'archive:ries',
  RIE_IMPORT = 'import:ries',
  RIE_EXPORT = 'export:ries',

  COMPANIES_MANAGE_OWN = 'manage:companies:own',
  COMPANIES_MANAGE_ALL = 'manage:companies:all',

  READ_AUDIT_LOGS = 'read:auditlogs',
  READ_IMPORT_LOGS = 'read:importlogs',

  READ_TOKENS = 'read:tokens',
}

export default Permission;
