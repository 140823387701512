import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import RieDataTable from '../Components/RieDataTable';
import { Permission, Status } from '../../../Shared/Enums';
import { SearchBar, Unauthorized } from '../../../Shared/Components';
import { TableQueryOptions } from '../../../Shared/Types/QueryOptions';
import { ODataResult } from '../Types/ODataResult';
import useMySubmittedRies from '../../../Hooks/Rie/useMySubmittedRies';
import useMonetUser from '../../../Hooks/useMonetUser';
import { useFilter } from '../../../Shared/Providers/FilterContextProvider';

const MySubmittedRies = () => {
  const { t } = useTranslation();
  const { checkPermission, hasMultipleOperators } = useMonetUser();
  const tableRef = useRef();

  const { filterObjects } = useFilter();

  const showMultipleOperators = hasMultipleOperators();

  if (!checkPermission(Permission.RIE_SUBMIT)) {
    return <Unauthorized pageTitle={t('menu.mySubmittedRies')} />;
  }

  const handleDataCall = (queryOptions: TableQueryOptions): UseQueryResult<ODataResult, Error> =>
    useMySubmittedRies({
      pageIndex: queryOptions.pageIndex,
      pageSize: queryOptions.pageSize,
      sorting: queryOptions.sorting,
      filter: filterObjects.myRiesFilter,
    });

  return (
    <div className="my-submitted-ries page">
      <div className="page-header">
        <div className="page-header__title">
          <h1 className="title">{t('menu.mySubmittedRies')}</h1>
        </div>
      </div>
      <SearchBar
        showMultipleOperators={showMultipleOperators}
        showSimpleHeader
        showStatusFilter
        excludedStatuses={[Status.Active, Status.Archived]}
        filterKey="myRiesFilter"
      />
      <RieDataTable
        ref={tableRef}
        dataCall={handleDataCall}
      />
    </div>
  );
};

export default MySubmittedRies;
