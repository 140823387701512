import React from 'react';

import './OperatorLogos.scss';
import useMonetUser from '../../../Hooks/useMonetUser';

const OperatorLogos = () => {
  const { ops } = useMonetUser();

  return (
    <div className="operator-logos">
      {ops.map((op) => {
        const img = `/images/${op.toLowerCase()}.png`;
        return (
          <img
            key={op}
            src={img}
            alt={op}
            className="operator-logos__logo"
          />
        );
      })}
    </div>
  );
};

export default OperatorLogos;
