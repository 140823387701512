import React, { forwardRef, useImperativeHandle } from 'react';
import Select from 'react-select';
import {
  flexRender,
  getCoreRowModel,
  Header,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import IconRender from '../Icon/IconRender';
import { Icon } from '../../Enums';
import PagerButton from './Components/PagerButton';

import './DataTable.scss';
import { ODataResult } from '../../../Features/Rie/Types/ODataResult';
import { TableQueryOptions } from '../../Types/QueryOptions';
import Loader from '../Loader/Loader';

type PropType = {
  columns: any;
  dataCall: (fetchDataOptions: TableQueryOptions) => UseQueryResult<ODataResult, Error>;
  onRowClick?: (id: string) => void;
  keyAttribute?: string;
  initialSortingState?: SortingState;
};

const DataTable = forwardRef((props: PropType, ref) => {
  const { columns, dataCall, keyAttribute, onRowClick, initialSortingState } = props;

  const { t } = useTranslation();

  const [sorting, setSorting] = React.useState<SortingState>(initialSortingState ?? []);
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useImperativeHandle(ref, () => ({
    resetPagination() {
      setPagination({ pageIndex: 0, pageSize });
    },
  }));

  const dataQuery = dataCall({
    pageIndex,
    pageSize,
    sorting,
  });

  const defaultData = React.useMemo(() => [], []);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const columnMap = (col: any): Record<string, boolean> => {
    const ret: Record<string, boolean> = {};
    ret[col.id || col.accessorKey] = false;
    return ret;
  };

  const columnVisibility = Object.assign({}, ...columns.filter((col: any) => col.hide === true).map(columnMap));

  const count: number = dataQuery.data?.count || 0;

  const table = useReactTable({
    columns,
    // @ts-ignore
    data: dataQuery.data?.value ?? defaultData,
    pageCount: dataQuery.data ? Math.ceil(count / pageSize) : -1,
    state: {
      pagination,
      sorting,
    },
    initialState: {
      columnVisibility,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    debugTable: false,
  });

  const options = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ];

  const defaultOption = (ps: number) => options.find((o) => o.value === ps.toString());

  const renderSortHeader = (header: Header<any, any>) => {
    if (!header.column.getCanSort()) {
      return null;
    }

    return (
      {
        asc: (
          <IconRender
            className="sorting-icon"
            icon={Icon.arrowUp}
            width="12"
            height="8"
          />
        ),
        desc: (
          <IconRender
            className="sorting-icon"
            icon={Icon.arrowDown}
            width="12"
            height="8"
          />
        ),
      }[header.column.getIsSorted() as string] ?? (
        <IconRender
          className="sorting-icon sorting-icon-potential"
          icon={Icon.arrowUp}
          width="12"
          height="8"
        />
      )
    );
  };

  if (dataQuery.isFetching) {
    return (
      <div className="data-table-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="data-table">
      <table className="data-table__table">
        <thead className="data-table__thead">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              className="data-table__table-row data-table__table-row--header"
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => {
                const classes = ['data-table__table-header'];

                return (
                  <th
                    className={classes.join(' ')}
                    colSpan={header.colSpan}
                    key={header.id}
                  >
                    {header.isPlaceholder ? null : (
                      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                      <div
                        {...{
                          className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {renderSortHeader(header)}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            const rowClasses = ['data-table__table-row'];

            let rowClickHandler = () => {};
            if (keyAttribute && onRowClick) {
              const rowId: string = row.getValue(keyAttribute) || '';
              rowClickHandler = () => onRowClick(rowId);
              rowClasses.push('data-table__table-row--clickable');
            }

            return (
              <tr
                key={row.id}
                className={rowClasses.join(' ')}
                onClick={rowClickHandler}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="data-table__table-cell"
                  >
                    <div
                      className="table-cell-value"
                      data-text={cell.getValue()}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="data-table__pager-container">
        <div className="data-table__pager">
          <div className="data-table__page-size">
            <span>{t('pager.numberOfRows')}</span>
            <Select
              defaultValue={defaultOption(table.getState().pagination.pageSize)}
              onChange={(v) => {
                table.setPageSize(Number(v?.value));
              }}
              options={options}
              menuPlacement="auto"
              isSearchable={false}
            />
          </div>
          <div className="data-table__results">
            {count === 0
              ? t('pager.resultCounter.noResults')
              : t('pager.resultCounter.results', {
                  min: table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1,
                  max: Math.min(
                    count,
                    table.getState().pagination.pageIndex * table.getState().pagination.pageSize +
                      table.getState().pagination.pageSize,
                  ),
                  total: count,
                })}
          </div>
          <div className="data-table__pager-actions">
            <PagerButton
              active={table.getCanPreviousPage()}
              label={t('pager.first')}
              onClick={() => table.setPageIndex(0)}
              icon={Icon.chevronLeftEnd}
            />
            <PagerButton
              active={table.getCanPreviousPage()}
              label={t('pager.previous')}
              onClick={() => table.previousPage()}
              icon={Icon.chevronLeft}
            />
            <PagerButton
              active={table.getCanNextPage()}
              onClick={() => table.nextPage()}
              label={t('pager.next')}
              icon={Icon.chevronRight}
            />
            <PagerButton
              active={table.getCanNextPage()}
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              label={t('pager.last')}
              icon={Icon.chevronRightEnd}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

DataTable.defaultProps = {
  onRowClick: undefined,
  keyAttribute: undefined,
  initialSortingState: [],
};

export default DataTable;
