import buildQuery from 'odata-query';
import http from '../../../Utils/Axios/http';
import { Company } from '../../../Shared/Models';
import getODataQueryOptions, { QueryOptions } from '../../../Shared/Types/QueryOptions';

const base = '/CoreAdministration/Companies/';

const deleteCompany = async (id: string) => http.delete(`${base}${id}`);

const getCompanies = async (queryOptions: QueryOptions) => {
  let query = buildQuery(getODataQueryOptions(queryOptions));
  query = query.replace('T00:00:00.000Z', ''); // Jumping through hoops for odata, DO NOT REMOVE

  const { data } = await http.get(`${base}${query}`);
  return {
    value: data.value,
    count: data['@odata.count'],
  };
};

const getCompanyById = async (id?: string) => {
  if (!id) {
    return undefined;
  }

  // @ts-ignore
  const query = buildQuery();

  const { data } = await http.get(`${base}${query}${id}`);

  return data;
};

const newCompany = async (company: Company) => {
  const data = { ...company };

  // These fields are removed from the Data object (copy of user), to ensure UpdateUser works the call can not handle extra fields being send
  delete data.Operators;

  return http.post(`${base}CreateCompany`, company);
};

const updateCompany = async (company: Company) => {
  const data = { ...company };

  // These fields are removed from the Data object (copy of user), to ensure UpdateUser works the call can not handle extra fields being send
  delete data.Id;
  delete data.Operators;

  return http.post(`${base}${company.Id}/UpdateCompany`, data);
};

export { deleteCompany, getCompanies, newCompany, updateCompany, getCompanyById };
