import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Slider from 'react-slick';
import { ColumnDef } from '@tanstack/react-table';
import { useQueryClient, UseQueryResult } from 'react-query';

import { GoogleMaps, SearchBar, Tabs, Title, Toggle } from '../../../Shared/Components';

import './Locations.scss';
import { Rie } from '../../../Shared/Models';
import { useRies } from '../../../Hooks';
import OperatorCell from '../../../Shared/Components/Table/Cells/OperatorCell';
import SafetyStatusCell from '../../Rie/Cells/SafetyStatusCell';
import DateCell from '../../../Shared/Components/Table/Cells/DateCell';
import DataTable from '../../../Shared/Components/Table/DataTable';
import { TableQueryOptions } from '../../../Shared/Types/QueryOptions';
import { ODataResult } from '../../Rie/Types/ODataResult';
import useMapRies from '../../../Hooks/Rie/useMapRies';
import { useFilter } from '../../../Shared/Providers/FilterContextProvider';

const Locations = () => {
  const sliderEl = useRef(null);
  const { filterObjects } = useFilter();
  const tableRef = useRef();

  const [sliderActive, setSliderActive] = useState(false);
  const [allRies, setAllRies] = useState<Array<Rie> | undefined>(undefined);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { i18n, t } = useTranslation();

  const mapsDataQuery = useMapRies({
    filter: filterObjects.mapFilter,
  });

  const allMapsDataQuery = useMapRies({
    filter: { and: [] },
  });

  useEffect(() => {
    if (allMapsDataQuery.data) {
      setAllRies(allMapsDataQuery.data.value as Rie[]);
    }
  }, [allMapsDataQuery.data]);

  const handleDataCall = (queryOptions: TableQueryOptions): UseQueryResult<ODataResult, Error> =>
    useRies({
      pageIndex: queryOptions.pageIndex,
      pageSize: queryOptions.pageSize,
      sorting: queryOptions.sorting,
      filter: filterObjects.mapFilter,
    });

  const columns = useMemo<ColumnDef<Rie>[]>(
    () => [
      {
        header: 'Id',
        accessorKey: 'Id',
        hide: true,
      },
      {
        header: t('rie.details.info.site'),
        accessorKey: 'SiteNumber',
        enableSorting: false,
      },
      {
        header: t('rie.details.info.operator'),
        accessorKey: 'Operator',
        cell: OperatorCell,
      },
      {
        header: t('rie.details.address.title'),
        accessorKey: 'Address',
      },
      {
        header: t('rie.details.address.city'),
        accessorKey: 'City',
      },
      {
        header: t('rie.details.info.inventory'),
        accessorKey: 'InspectionDate',
        cell: DateCell,
        enableSorting: false,
      },
      {
        header: t('rie.details.info.safetyStatus'),
        accessorKey: 'SafetyStatus',
        cell: SafetyStatusCell,
        enableSorting: false,
      },
    ],
    [i18n.language],
  );

  const renderList = () => (
    <div className="rie-data-table">
      <DataTable
        ref={tableRef}
        columns={columns}
        dataCall={handleDataCall}
        onRowClick={(id: string) => {
          queryClient.invalidateQueries(['ries', 'rie', id]).then(() => {
            navigate(`/locations/${id}`);
          });
        }}
        keyAttribute="Id"
      />
    </div>
  );

  const handleToggleOffSwitch = () => {
    if (sliderEl.current) {
      // @ts-ignore
      sliderEl.current.slickPrev();
      setSliderActive(!sliderActive);
    }
  };

  const handleToggleOnSwitch = () => {
    if (sliderEl.current) {
      // @ts-ignore
      sliderEl.current.slickNext();
      setSliderActive(!sliderActive);
    }
  };

  const renderTabs = () => (
    <Tabs
      tabs={[
        {
          title: t('locations.map.button'),
          onClick: () => handleToggleOffSwitch(),
        },
        {
          title: t('locations.list.button'),
          onClick: () => handleToggleOnSwitch(),
        },
      ]}
    />
  );

  return (
    <div className="locations">
      <div className="locations__tabs">{renderTabs()}</div>
      <div className="locations__search-bar">
        <SearchBar filterKey="mapFilter" />
      </div>
      <div className="locations__header">
        <Title className="locations__title">
          {sliderActive ? t('locations.list.title') : t('locations.map.title')}
        </Title>
        <Toggle
          offSwitchLabel={t('locations.map.button')}
          onSwitchLabel={t('locations.list.button')}
          handleOffSwitch={() => handleToggleOffSwitch()}
          handleOnSwitch={() => handleToggleOnSwitch()}
        />
      </div>
      <div className={classNames('locations__slider', { 'locations__slider--active': sliderActive })}>
        <Slider
          ref={sliderEl}
          arrows={false}
          dots={false}
          slidesToShow={1}
          slidesToScroll={1}
          infinite={false}
          touchMove={false}
          swipeToSlide={false}
        >
          <div className="locations__maps">
            {mapsDataQuery.data && allRies && (
              <GoogleMaps
                ries={mapsDataQuery.data.value as Rie[]}
                allRies={allRies}
              />
            )}
          </div>
          <div className="locations__list">{renderList()}</div>
        </Slider>
      </div>
    </div>
  );
};

export default Locations;
