import { ColumnSort } from '@tanstack/react-table';
import FilterObjectType from './FilterObjectType';

export type TableQueryOptions = {
  pageIndex: number;
  pageSize: number;
  sorting: ColumnSort[];
};

export type FilterQueryOptions = {
  filter: FilterObjectType;
};

export type QueryOptions = TableQueryOptions & FilterQueryOptions;

const mapSortBy = (sb: ColumnSort): string => {
  const prop = sb.id.charAt(0).toUpperCase() + sb.id.slice(1);
  return `${prop} ${sb.desc ? 'desc' : 'asc'}`;
};

const getODataQueryOptions = (queryOptions: QueryOptions) => {
  const { pageIndex, pageSize, sorting, filter } = queryOptions;
  const top = pageSize;
  const skip = pageSize * pageIndex;
  const count = true;

  const orderBy = sorting.map(mapSortBy);

  return { top, skip, count, orderBy, filter };
};

export default getODataQueryOptions;
