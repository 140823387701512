import React, { useEffect, useRef, useState } from 'react';
import GoogleMapReact, { Coords, fitBounds } from 'google-map-react';
import useSupercluster from 'use-supercluster';
import Supercluster, { PointFeature } from 'supercluster';
import { GeoJsonProperties } from 'geojson';
import Rie from '../../Models/Rie';
import PinInfoScreen from './PinInfoScreen';
import { ClusterMarker, Marker } from './Markers';
import ClusterInfoScreen from './ClusterInfoScreen';
import { Icon, SafetyStatus } from '../../Enums';
import IconRender from '../Icon/IconRender';
import { useFilter } from '../../Providers/FilterContextProvider';
import Loader from '../Loader/Loader';

import './GoogleMaps.scss';

type GoogleMapsPropType = {
  ries: Rie[];
  allRies: Rie[];
  isDetail?: boolean;
  showRoute?: boolean;
};

const GoogleMap = (props: GoogleMapsPropType) => {
  const { ries, allRies, isDetail, showRoute } = props;

  const [infoPanelRie, setInfoPanelRie] = useState<Rie | undefined>(undefined);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [clusterLocations, setClusterLocations] = useState<any>([]);
  const { filters } = useFilter();
  const selectedRie: Rie | undefined = isDetail ? ries[0] : undefined;

  // eslint-disable-next-line no-underscore-dangle
  const googleApiKey = window.__RUNTIME_CONFIG__.REACT_APP_GOOGLE_API;

  const mapDivRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState<any | null>(null);
  const [zoom, setZoom] = useState(8);
  const [mapRef, setMapRef] = useState<any | null>(null);
  const [mapsRef, setMapsRef] = useState<any | null>(null);

  const points: Array<PointFeature<GeoJsonProperties>> = allRies.map((rie) => ({
    type: 'Feature',
    properties: {
      cluster: false,
      rieId: rie.Id,
      category: 'rie-location',
      siteNumber: rie.SiteNumber,
      operator: rie.Operator,
      status: rie.SafetyStatus,
    },
    geometry: {
      type: 'Point',
      coordinates: [rie.X as number, rie.Y as number],
    },
  }));

  const options = {
    radius: 75,
    maxZoom: 20,
  };

  const getRiesBounds = () => {
    let neLat = 53.7278211;
    let neLng = 7.3886663;
    let swLat = 50.6310793;
    let swLng = 3.0293883;

    if (ries.length === 1) {
      neLat = (ries[0].Y as number) + 0.001;
      neLng = (ries[0].X as number) + 0.001;
      swLat = (ries[0].Y as number) - 0.001;
      swLng = (ries[0].X as number) - 0.001;
    } else if (ries.length > 1) {
      const lats = ries.map((rie) => rie.Y).filter((y) => y !== 0);
      const longs = ries.map((rie) => rie.X).filter((x) => x !== 0);

      if (lats.length > 0) {
        neLat = Math.max(...(lats as number[]));
        swLat = Math.min(...(lats as number[]));
      }
      if (longs.length > 0) {
        neLng = Math.max(...(longs as number[]));
        swLng = Math.min(...(longs as number[]));
      }
    }

    return {
      ne: {
        lat: neLat,
        lng: neLng,
      },
      sw: {
        lat: swLat,
        lng: swLng,
      },
    };
  };

  const size = {
    width: mapDivRef.current?.clientWidth ?? 640, // Map width in pixels
    height: mapDivRef.current?.clientHeight ?? 480, // Map height in pixels
  };

  const riesCenter = fitBounds(getRiesBounds(), size);

  const { clusters, supercluster } = useSupercluster({ points, bounds, zoom, options });
  const [currentLocation, setCurrentLocation] = useState<Coords | undefined>(undefined);

  const getAllClusterChildren = (
    cluster:
      | Supercluster.PointFeature<GeoJsonProperties>
      | Supercluster.PointFeature<Supercluster.ClusterProperties & Supercluster.AnyProps>,
  ) => {
    const children = supercluster?.getChildren(cluster.id as number);
    if (children) {
      let locations: any[] = [];

      for (let i = 0; i < children.length; i += 1) {
        if (children[i].properties?.cluster) {
          locations = [...locations, ...getAllClusterChildren(children[i])];
        } else {
          locations.push(children[i]);
        }
      }

      return locations;
    }

    return [];
  };

  const onClusterClick = (id: number, latitude: number, longitude: number /* , key: string, childProp: any */) => {
    const cluster = clusters.find((c) => c.id === id);
    if (cluster !== undefined) {
      const locations = getAllClusterChildren(cluster);
      setClusterLocations(locations.slice(0, 25));
      setInfoPanelRie(undefined);
    }
    setCurrentLocation({ lat: latitude, lng: longitude });
  };

  const onMarkerClick = (id: string): void => {
    const rie = allRies!.find((r) => r.Id === id);
    setInfoPanelRie(rie);
    setClusterLocations([]);
  };

  const handleApiLoaded = (map: any, maps: any) => {
    setMapRef(map);
    setMapsRef(maps);
  };

  const goToCenter = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
          setZoom(15);
          mapRef?.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
          mapRef?.setZoom(15);
        },
        () => {
          setCurrentLocation({ lat: 52.370216, lng: 4.895168 });
          mapRef?.setCenter({ lat: 52.370216, lng: 4.895168 });
          mapRef?.setZoom(8);
          setZoom(8);
        },
      );
    } else {
      setCurrentLocation({ lat: 52.370216, lng: 4.895168 });
      mapRef?.setCenter({ lat: 52.370216, lng: 4.895168 });
      mapRef?.setZoom(8);
      setZoom(8);
    }
  };

  const isCleanFilter = JSON.stringify(filters.cleanFilter) === JSON.stringify(filters.mapFilter);

  useEffect(() => {
    if (JSON.stringify(filters.cleanFilter) === JSON.stringify(filters.mapFilter) && !initialLoad) {
      goToCenter();
      setInitialLoad(true);
    }
  }, [isCleanFilter]);

  useEffect(() => {
    if (mapRef !== null && showRoute) {
      const directionsService = new mapsRef.DirectionsService();
      const directionsRenderer = new mapsRef.DirectionsRenderer();

      directionsRenderer.setMap(mapRef);

      const origin = { lat: currentLocation?.lat, lng: currentLocation?.lng };
      const destination = { lat: selectedRie?.Y as number, lng: selectedRie?.X as number };

      directionsService.route(
        {
          origin,
          destination,
          travelMode: mapsRef.TravelMode.DRIVING,
        },
        (result: any, status: any) => {
          if (status === mapsRef.DirectionsStatus.OK) {
            directionsRenderer.setDirections(result);
          } else {
            // eslint-disable-next-line no-console
            console.error(`error fetching directions ${result}`);
          }
        },
      );
    }
  }, [showRoute]);

  if (isCleanFilter && !currentLocation) {
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  const isDefaultLocation = currentLocation?.lat === 52.370216 && currentLocation?.lng === 4.895168;
  const curZoom = isCleanFilter && !isDefaultLocation ? 15 : riesCenter.zoom;
  const curCenter = isCleanFilter && currentLocation ? currentLocation : riesCenter.center;

  return (
    <div className="map">
      <div
        ref={mapDivRef}
        className="map__google"
      >
        <button
          type="button"
          onClick={() => {
            goToCenter();
          }}
          className="my-location-button"
        >
          <IconRender icon={Icon.myLocation} />
        </button>
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: googleApiKey }}
          center={
            isDetail && !!selectedRie ? { lat: selectedRie?.Y as number, lng: selectedRie?.X as number } : curCenter
          }
          zoom={curZoom}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          onChange={(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            { zoom, bounds },
          ) => {
            setZoom(zoom);
            setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
          }}
          options={{ fullscreenControl: false }}
        >
          {clusters.map((cluster) => {
            const { properties, geometry, id } = cluster;
            const { coordinates } = geometry;
            const [longitude, latitude] = coordinates;

            // @ts-ignore
            const usedProps: (GeoJsonProperties | (Supercluster.ClusterProperties & Supercluster.AnyProps)) & {
              siteNumber: string;
              operator: string;
              status: SafetyStatus;
              rieId: string;
            } = properties;

            const { cluster: isCluster, point_count: pointCount, siteNumber, operator, status, rieId } = usedProps;

            if (isCluster) {
              return (
                <ClusterMarker
                  key={id}
                  lat={latitude}
                  lng={longitude}
                  count={pointCount}
                  id={id}
                  onClick={() => onClusterClick(id as number, latitude, longitude)}
                />
              );
            }
            return (
              <Marker
                key={`${siteNumber}_${operator}`}
                lat={latitude}
                lng={longitude}
                siteNumber={siteNumber}
                operator={operator}
                status={status}
                rieId={rieId}
                onClick={() => onMarkerClick(rieId)}
              />
            );
          })}
        </GoogleMapReact>
        {!isDetail && infoPanelRie && (
          <PinInfoScreen
            lat={infoPanelRie.Y as number}
            lng={infoPanelRie.X as number}
            key={infoPanelRie.Id}
            deactivate={() => setInfoPanelRie(undefined)}
            rie={infoPanelRie}
          />
        )}
        {clusterLocations.length > 0 && (
          <ClusterInfoScreen
            locations={clusterLocations}
            deactivate={() => setClusterLocations([])}
          />
        )}
      </div>
    </div>
  );
};

GoogleMap.defaultProps = {
  isDetail: false,
  showRoute: true,
};

export default GoogleMap;
