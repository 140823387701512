enum Theme {
  default = 'default',
  primary = 'primary',
  primaryInverted = 'primary-inverted',
  transparent = 'transparent',
  transparentInverted = 'transparent-inverted',
  dark = 'dark',
  orange = 'orange',
  yellow = 'yellow',
  red = 'red',
}

export default Theme;
