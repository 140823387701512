import classNames from 'classnames';
import React from 'react';

import './Label.scss';

type PropType = {
  label?: string;
};

const Label = (props: PropType) => {
  const { label } = props;
  return (
    <span
      className={classNames('label', {
        [`label--${label?.toLowerCase()}`]: label,
      })}
    >
      {label && <span className="label__text">{label}</span>}
    </span>
  );
};

Label.defaultProps = {
  label: null,
};

export default Label;
