import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import React, { ComponentType, useEffect } from 'react';
import { Header, Loader } from '../../../Shared/Components';
import http from '../../../Utils/Axios/http';

interface ProtectedRouteProps {
  showHeader: boolean;
  component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, showHeader }) => {
  const loader = () => (
    <div className="centralize-loader">
      <Loader />
    </div>
  );
  const { getAccessTokenSilently } = useAuth0();

  // taken from: https://stackoverflow.com/a/68407340
  useEffect(() => {
    http.tokenGenerator = getAccessTokenSilently;
  }, [getAccessTokenSilently]);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: loader,
  });

  return (
    <>
      {showHeader && <Header />}
      <Component />
    </>
  );
};

export default ProtectedRoute;
