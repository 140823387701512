import React, { useContext, ReactNode, useState, useMemo } from 'react';
import { t } from 'i18next';
import RieFilterType from '../Types/RieFilterType';
import FilterContextType from '../Types/FilterContextType';
import FilterObjectType from '../Types/FilterObjectType';
import RieFiltersType from '../Types/RieFiltersType';

const getDefaultFilter = (defaultFilters?: Partial<RieFilterType>): RieFilterType => ({
  city: '',
  siteNumber: '',
  address: '',
  operator: {
    value: '',
    label: t('locations.searchBar.chooseOperator'),
  },
  status: {
    value: '',
    label: t('locations.searchBar.chooseStatus'),
  },
  ...defaultFilters,
});

const getDefaultFilters = (): RieFiltersType<RieFilterType> => ({
  archiveFilter: getDefaultFilter({
    status: {
      value: 'Archived',
      label: t(`rie.details.info.status.Archived`),
    },
  }),
  mapFilter: getDefaultFilter(),
  quarantineFilter: getDefaultFilter({
    status: {
      value: 'PendingApproval',
      label: t(`rie.details.info.status.PendingApproval`),
    },
  }),
  myRiesFilter: getDefaultFilter(),
  cleanFilter: getDefaultFilter(),
});

const getDefaultFilterObject = (): FilterObjectType => ({ and: [] });
const getDefaultArchivedFilterObject = (): FilterObjectType => ({ and: [{ Status: 'Archived' }] });
const getDefaultQuarantineFilterObject = (): FilterObjectType => ({ and: [{ Status: 'PendingApproval' }] });

const getDefaultFilterObjects = (): RieFiltersType<FilterObjectType> => ({
  archiveFilter: getDefaultArchivedFilterObject(),
  mapFilter: getDefaultFilterObject(),
  quarantineFilter: getDefaultQuarantineFilterObject(),
  myRiesFilter: getDefaultFilterObject(),
  cleanFilter: getDefaultFilterObject(),
});

export const FilterContext = React.createContext<FilterContextType>({
  filters: getDefaultFilters(),
  filterObjects: getDefaultFilterObjects(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFilterChanged: (filter: RieFilterType, key: keyof RieFiltersType<any>) => {},
});

export const useFilter = () => useContext(FilterContext);

type PropType = {
  children: ReactNode[] | ReactNode;
};

// @ts-ignore
export const FilterContextProvider = (props: PropType) => {
  const [filters, setFilters] = useState<RieFiltersType<RieFilterType>>(getDefaultFilters());
  const [filterObjects, setFilterObjects] = useState<RieFiltersType<FilterObjectType>>(getDefaultFilterObjects());

  const onFilterChanged = (newFilter: RieFilterType, key: keyof RieFiltersType<any>) => {
    const filterObj: FilterObjectType = {
      and: [],
    };

    if (filterObj && filterObj.and) {
      if (newFilter.siteNumber) {
        filterObj.and.push({ SiteNumber: { contains: newFilter.siteNumber.trim() } });
      }

      if (newFilter.city) {
        filterObj.and.push({ 'tolower(City)': { startswith: `tolower('${newFilter.city.trim()}')` } });
      }

      if (newFilter.address) {
        filterObj.and.push({ 'tolower(Address)': { contains: `tolower('${newFilter.address.trim()}')` } });
      }

      if (newFilter.operator.value) {
        filterObj.and.push({ Operator: newFilter.operator.value });
      }

      if (newFilter.status!.value) {
        filterObj.and.push({ Status: newFilter.status!.value });
      }
    }

    filters[key] = newFilter;
    filterObjects[key] = filterObj;

    setFilters({ ...filters });
    setFilterObjects({ ...filterObjects });
  };

  const { children } = props;
  const context = useMemo(() => ({ filters, filterObjects, onFilterChanged }), [filters, filterObjects]);

  return <FilterContext.Provider value={context}>{children}</FilterContext.Provider>;
};
