import React from "react";

import {SafetyStatus, Size} from "../../../Shared/Enums";
import {StatusSymbol} from "../../../Shared/Components";

const formatSafetyStatus = (ss: SafetyStatus) => (
    <StatusSymbol
        status={ss}
        size={Size.small}
    />
);

const SafetyStatusCell = ( value : any ) => formatSafetyStatus(value.getValue());

export default SafetyStatusCell;
