enum Operator {
  KPN = 'KPN',
  ODIDO = 'OD',
  VF = 'VF',
}

export default Operator;

export const getOperator = (operator: string): string => {
  switch (operator) {
    case Operator.KPN:
      return 'KPN';
    case Operator.ODIDO:
      return 'Odido';
    case Operator.VF:
      return 'Vodafone';
    default:
      throw new Error('Invalid Operator');
  }
};
