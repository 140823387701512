enum Role {
  AdministratorIndicia = 'AdministratorIndicia',
  AdministratorMonet = 'AdministratorMonet',
  CompanyManagerKPN = 'CompanyManagerKPN',
  CompanyManagerVF = 'CompanyManagerVF',
  CompanyManagerOD = 'CompanyManagerOD',
  InspectorVF = 'InspectorVF',
  InspectorOD = 'InspectorOD',
  OperatorKPN = 'OperatorKPN',
  OperatorVF = 'OperatorVF',
  OperatorOD = 'OperatorOD',
  OperatorOwnerKPN = 'OperatorOwnerKPN',
  OperatorOwnerVF = 'OperatorOwnerVF',
  OperatorOwnerOD = 'OperatorOwnerOD',
  ValidatorVF = 'ValidatorVF',
  ValidatorOD = 'ValidatorOD',
  Viewer = 'Viewer',
}

export default Role;
