import React, { type ReactNode } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import { Icon, Theme } from '../../Enums';
import Button from '../Button/Button';
import IconButton from '../IconButton/IconButton';

import './Flyout.scss';
import Username from '../Username/Username';
import OperatorLogos from '../OperatorLogos/OperatorLogos';

type PropType = {
  active: boolean;
  handleClose: () => void;
  className?: string;
  children?: ReactNode;
  footer?: ReactNode;
};

const Flyout = (props: PropType) => {
  const { className, children, footer, active, handleClose } = props;

  return (
    <div className={classNames(className, 'flyout', { 'flyout--active': active })}>
      <div className="flyout__wrapper">
        <div className="flyout__header">
          <div className="flyout__header-content">
            <div className="flyout__header-top">
              <ReactSVG
                src="/images/logo-short.svg"
                className="flyout__logo"
              />
              <OperatorLogos />
            </div>
            <div className="flyout__header-bottom">
              <div className="flyout__user">
                <Username />
              </div>
            </div>
          </div>
          <div className="flyout__header-close">
            <IconButton
              label="Close"
              icon={Icon.close}
              onClick={handleClose}
              theme={Theme.primaryInverted}
              size="20px"
            />
          </div>
        </div>
        <div className="flyout__content">{children}</div>
        <div className="flyout__footer">{footer}</div>
      </div>
      <Button
        className="flyout__overlay"
        onClick={handleClose}
      />
    </div>
  );
};

Flyout.defaultProps = {
  className: '',
  children: null,
  footer: null,
};

export default Flyout;
