import React from 'react';
import { useTranslation } from 'react-i18next';

import Container from '../Container/Container';

import './Unauthorized.scss';

type PropType = {
  pageTitle: string;
};

const Unauthorized = ({ pageTitle }: PropType) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="page-header">
        <div className="page-header__title">
          <h1 className="title">{pageTitle}</h1>
        </div>
      </div>
      <Container>
        <div className="unauthorized">{t('generic.unauthorized')}</div>
      </Container>
    </>
  );
};

export default Unauthorized;
