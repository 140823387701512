import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { enGB, nl } from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithHistory from './Features/Login/Auth0/Auth0ProviderWithHistory';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-lightbox/style.css';
import './index.scss';
import './i18n';
import {ErrorContextProvider} from "./Shared/Providers/ErrorContextProvider";
import {FilterContextProvider} from "./Shared/Providers/FilterContextProvider";

registerLocale('nl', nl);
registerLocale('en', enGB);
setDefaultLocale('nl');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <ErrorContextProvider>
          <FilterContextProvider>
            <App />
          </FilterContextProvider>
        </ErrorContextProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
