import React from 'react';
import {Link} from "react-router-dom";

import LogEntry from "../../../Models/LogEntry";

const formatSiteRie = ( entry : LogEntry) => (
    <>
        {!entry.RieId && (
            <span>{entry.ExecutedAt}</span>
        )}
        {entry.RieId && (
            <Link to={`/locations/${entry.RieId}`}>{entry.ExecutedAt}</Link>
        )}
    </>
)

const SiteRieCell = ( value : any ) => formatSiteRie(value.getValue());

export default SiteRieCell;
