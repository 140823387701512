import React, { type ReactNode } from 'react';

import './Subtitle.scss';

type PropType = {
  children: ReactNode;
  className?: string;
  color?: string;
};

const Subtitle = (props: PropType) => {
  const { className, color, children } = props;
  return (
    <h2
      className={`subtitle ${className}`}
      style={{ color }}
    >
      {children}
    </h2>
  );
};

Subtitle.defaultProps = {
  className: '',
  color: 'var(--black)',
};

export default Subtitle;
