import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import formatUsername from "../../../Features/Users/Util/UserNameFormatter";

const Username = () => {
  const { user } = useAuth0();

  const username = formatUsername(user);

  return <span>{username}</span>;
};

export default Username;
