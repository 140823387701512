import { useQuery, UseQueryResult } from 'react-query';

import getImportLogs from '../Data/importLogService';
import { ODataResult } from '../../Rie/Types/ODataResult';
import {QueryOptions} from "../../../Shared/Types/QueryOptions";

const useImportLogs = (queryOptions: QueryOptions): UseQueryResult<ODataResult, Error> =>
  useQuery(['ImportLogs', queryOptions], () => getImportLogs(queryOptions));

export default useImportLogs;
