import React from 'react';
import { ReactSVG } from 'react-svg';
import { Icon } from '../../Enums';

import './Icon.scss';

const IconColorMapping = {
  [Icon.archive]: ['fill'],
  [Icon.arrowDown]: ['fill'],
  [Icon.arrowUp]: ['fill'],
  [Icon.auditLog]: ['fill'],
  [Icon.calendar]: ['fill'],
  [Icon.chevronDown]: ['stroke'],
  [Icon.chevronLeft]: ['stroke'],
  [Icon.chevronLeftEnd]: ['stroke'],
  [Icon.chevronRight]: ['stroke'],
  [Icon.chevronRightEnd]: ['stroke'],
  [Icon.close]: ['fill'],
  [Icon.cloud]: ['fill'],
  [Icon.companies]: ['fill'],
  [Icon.delete]: ['fill'],
  [Icon.exit]: ['fill'],
  [Icon.export]: ['fill'],
  [Icon.flagNL]: [],
  [Icon.flagUK]: [],
  [Icon.hashtag]: ['fill'],
  [Icon.house]: ['stroke'],
  [Icon.image]: ['fill'],
  [Icon.importLog]: ['fill'],
  [Icon.lock]: ['fill'],
  [Icon.logo]: [],
  [Icon.mail]: ['fill'],
  [Icon.marker]: ['fill'],
  [Icon.menu]: ['fill'],
  [Icon.myLocation]: ['fill'],
  [Icon.operator]: ['fill'],
  [Icon.pencil]: ['fill'],
  [Icon.phone]: ['fill'],
  [Icon.quarantine]: ['fill'],
  [Icon.search]: ['fill'],
  [Icon.siteLocation]: ['fill'],
  [Icon.siteNumber]: ['fill'],
  [Icon.street]: ['fill'],
  [Icon.submittedRies]: ['fill'],
  [Icon.user]: ['fill'],
};

type PropType = {
  icon: Icon;
  color?: string;
  width?: string;
  height?: string;
  className?: string;
};

const IconRender = (props: PropType) => {
  const { icon, color, width, height, className } = props;

  // Set the width, height and color of the SVG.
  const handleSvgBeforeInjection = (svg: SVGElement) => {
    svg.setAttribute('width', width || '0');
    svg.setAttribute('height', height || '0');

    if (color && IconColorMapping[icon]) {
      const paths = svg.querySelectorAll('path');
      if (paths) {
        Array.from(paths).forEach((path) => {
          IconColorMapping[icon].forEach((attribute) => {
            path.setAttribute(attribute, color);
          });
        });
      }
    }
  };

  return (
    <ReactSVG
      src={`/images/icons/${icon}.svg`}
      wrapper="span"
      className={`${className} icon`}
      beforeInjection={handleSvgBeforeInjection}
      style={{
        width,
        height,
      }}
    />
  );
};

IconRender.defaultProps = {
  color: 'var(--black)',
  width: '20px',
  height: '20px',
  className: '',
};

export default IconRender;
