import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Link, useLocation } from 'react-router-dom';

import Title from '../Title/Title';
import IconButton from '../IconButton/IconButton';
import { Icon, Theme } from '../../Enums';
import Menu from '../Menu/Menu';

import './Header.scss';
import Username from '../Username/Username';
import OperatorLogos from '../OperatorLogos/OperatorLogos';
import MonetErrorModal from "../MonetModal/MonetErrorModal";
import {useErrorModal} from "../../Providers/ErrorContextProvider";

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);
  const { isAuthenticated } = useAuth0();
  const { t } = useTranslation();
  const location = useLocation();
  const {error, setError} = useErrorModal();

  const toggleSearch = () => {
    const searchBar = document.querySelector('.locations__search-bar');
    if (searchBar) {
      searchBar.classList.toggle('locations__active');
    }
  };

  return (
    <div className="header">
      <Link
        className="header__branding"
        to="/"
      >
        <span>
          <ReactSVG
            src="/images/logo.svg"
            className="header__logo"
          />
          <Title className="header__short-title">{t('generic.ssd')}</Title>
          <Title className="header__title">{t('generic.safetySiteDatabase')}</Title>
        </span>
        <span className="show-mobile">
          <OperatorLogos />
        </span>
      </Link>
      {isAuthenticated && (
        <>
          <div className="header__user-info">
            <OperatorLogos />
            <div className="header__user">
              <Username />
            </div>
          </div>
          <div className="header__actions">
            {(location.pathname === '/' || location.pathname === '/locations') && (
              <IconButton
                icon={Icon.search}
                label="Search"
                theme={Theme.primaryInverted}
                className="search-button"
                onClick={() => toggleSearch()}
              />
            )}
            <IconButton
              icon={Icon.menu}
              label="Menu"
              theme={Theme.primaryInverted}
              onClick={() => {
                document.querySelector('body')!.classList.add('prevent-scroll');
                setMenuActive(true);
              }}
            />
          </div>
          <Menu
            active={menuActive}
            handleClose={() => {
              document.querySelector('body')!.classList.remove('prevent-scroll');
              setMenuActive(false);
            }}
          />
          {error && true && <MonetErrorModal close={() => setError('')}>{error}</MonetErrorModal>}
        </>
      )}
    </div>
  );
};

export default Header;
