import classNames from 'classnames';
import React, { useState } from 'react';

import Tab, { PropType as TabPropType } from './Tab';

import './Tabs.scss';

type PropType = {
  tabs: TabPropType[];
};

const Tabs = (props: PropType) => {
  const { tabs } = props;
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="tabs">
      <div className="tabs__items">
        {tabs &&
          tabs.map((tab, tabIndex) => (
            <Tab
              key={`tab-${tab.title}`}
              className={classNames('tabs__tab', tab.className)}
              title={tab.title}
              icon={tab.icon}
              url={tab.url}
              active={tabIndex === activeTab}
              onClick={() => {
                setActiveTab(tabIndex);
                if (tab.onClick) {
                  tab.onClick();
                }
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default Tabs;
