import React from 'react-dom';
import Slider from 'react-slick';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';

import './ImageSlider.scss';
import IconButton from '../IconButton/IconButton';
import Icon from '../../Enums/Icon';
import { Theme } from '../../Enums';

type PropType = {
  callback?: (image: string) => void;
  centerMode?: boolean;
  centerPadding?: string;
  dots?: boolean;
  images: string[];
  infinite?: boolean;
  slidesToScroll?: number;
  slidesToShow?: number;
};

const ImageSlider = (props: PropType) => {
  const { callback, centerMode, centerPadding, dots, images, infinite, slidesToScroll, slidesToShow } = props;
  const { t } = useTranslation();

  const [isLightboxOpen, setIsLightboxOpen] = useState<boolean>(false);
  const [photoIndex, setPhotoIndex] = useState<number>(0);

  const getImgUrl = (image: string): string =>
    // eslint-disable-next-line no-underscore-dangle
    `${window.__RUNTIME_CONFIG__.REACT_APP_ODATA_URL}File/Files/${image}/$value`;

  const renderImage = (image: string, index: number) => (
    <div
      className="image-slider__image-container"
      key={image}
    >
      <button
        type="button"
        key={image}
        onClick={() => {
          setPhotoIndex(index);
          setIsLightboxOpen(true);
        }}
        className="image-slider__button"
      >
        <img
          src={getImgUrl(image)}
          alt="test"
          height="200px"
        />
      </button>
      {callback !== null && (
        <IconButton
          icon={Icon.delete}
          theme={Theme.primary}
          onClick={() => (callback ? callback(image) : false)}
          label={t('generic.delete')}
        />
      )}
    </div>
  );

  return (
    <div>
      {images && images.length > 1 && (
        <div className="image-slider">
          <Slider
            dots={dots}
            centerMode={centerMode}
            centerPadding={centerPadding}
            infinite={infinite}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            className="image-slider__slick-slider"
          >
            {images.map(renderImage)}
          </Slider>
        </div>
      )}
      {images && images.length === 1 && renderImage(images[0], 0)}

      {isLightboxOpen && images && (
        <Lightbox
          mainSrc={getImgUrl(images[photoIndex])}
          nextSrc={getImgUrl(images[(photoIndex + 1) % images.length])}
          prevSrc={getImgUrl(images[(photoIndex + images.length - 1) % images.length])}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

ImageSlider.defaultProps = {
  dots: true,
  centerMode: true,
  centerPadding: '60',
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  callback: null,
};

export default ImageSlider;
