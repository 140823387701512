import {t} from "i18next";
import http from '../../../Utils/Axios/http';
import Operator from '../../../Shared/Enums/Operator';

const exportRies = (operator: Operator, toggleEvent: () => void, setError: (error: string) => void) => {
  http
    .get(`/Rie/Ries/Export?operator=${operator}`, { responseType: 'blob' })
    .then((result) => {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `rie-export-${operator.toLowerCase()}.csv`);
      document.body.appendChild(link);
      link.click();
    })
    .catch(() => {
      setError(t('error'));
    })
    .finally(() => {
      toggleEvent();
    });
};

export default exportRies;
