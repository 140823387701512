import React, {ReactNode} from 'react';
import { useTranslation } from 'react-i18next';

import MonetModal from './MonetModal';

type PropType = {
  close: () => void;
  children: ReactNode;
};

const MonetErrorModal = (props: PropType) => {
  const { close, children } = props;
  const { t } = useTranslation();

  return (
      <MonetModal
          confirm={() => {}}
          title={t('generic.errorTitle')}
          close={close}
          onCloseLabel={t('generic.close')}
          showConfirm={false}
          isErrorModal
      >
        {children}
      </MonetModal>
  );
};

export default MonetErrorModal;
